import { filter, findIndex, get } from "lodash";
import { useParams } from "react-router";
import { useCurrentProgram } from "../../../../shared/graphql/programs";
import {
    InteractionVersion,
    NoteVersion,
    ProgramVersion,
    useInteractionAtVersionLazyQuery,
    useUpdateInteractionMutation,
} from "../../../../shared/models/types";
import { IInteractionArrow, useUpdateInteractionInProgram } from "../../models/program";
import { IInteractionLocationParams } from "../../routes/locations";

export type Version = ProgramVersion | InteractionVersion | (NoteVersion & { payload: { patches?: any } });

export const getPayloadPatchSummary = (version: Partial<Version>) => {
    const summary = { added: 0, removed: 0 };
    if (version.payload.patches) {
        for (const key of Object.keys(version.payload.patches)) {
            const patch = version.payload.patches[key];
            if (patch.eq) {
                continue;
            }
            if (patch.patchObj) {
                // TODO: Handle md
                const hunks = get(patch.patchObj, "[0].hunks", []);
                const mdSummary = getHunksSummary(hunks);
                summary.added += mdSummary.added;
                summary.removed += mdSummary.removed;
            } else if (patch.changes) {
                for (const change of patch.changes as Change[]) {
                    if (change.added) {
                        // summary.added += change.value.length;
                        summary.added += 1;
                    } else if (change.removed) {
                        // summary.removed += change.value.length;
                        summary.removed += 1;
                    }
                }
            }
        }
    } else {
        return null;
    }
    return summary;
};

const getHunksSummary = (hunks: { newLines: number; oldLines: number }[]) => {
    let added = 0,
        removed = 0;
    hunks.map((h) => {
        added += h.newLines;
        removed += h.oldLines;
    });
    return { added, removed };
};

export interface Change {
    count?: number | undefined;
    /**
     * Text content.
     */
    value: string;
    /**
     * `true` if the value was inserted into the new string.
     */
    added?: boolean | undefined;
    /**
     * `true` if the value was removed from the old string.
     */
    removed?: boolean | undefined;
}

export const filterUnchanged = (patches: any) => {
    const out = { ...patches };
    for (const k of Object.keys(patches)) {
        const patch = patches[k];
        if (patch && patch.eq == true) {
            delete out[k];
        }
        if (patch.changes) {
            out[k] = {
                changes: filter(patch.changes, (c: any) => !!!c.length),
            };
        }
    }
    return out;
};

const getPreviousVersion = (versions: Partial<Version>[], targetVersion: Partial<Version>) => {
    const targetIndex = findIndex(versions, { id: targetVersion.id });
    return versions[targetIndex + 1];
};

type VersionKey = keyof Version | string;

export const getUnpatchedChanges = (versions: Partial<Version>[], targetVersion: Partial<Version>, full = false) => {
    const previousVersion = getPreviousVersion(versions, targetVersion);
    const { payload, ...unPatchedFields } = targetVersion;
    delete unPatchedFields.__typename;
    delete unPatchedFields.editorId;
    delete (unPatchedFields as any).interactionId;
    delete unPatchedFields.id;
    delete unPatchedFields.userId;
    delete unPatchedFields.meta;
    delete unPatchedFields.branch;
    delete unPatchedFields.rootId;
    delete unPatchedFields.createdAt;
    delete unPatchedFields.updatedAt;
    delete unPatchedFields.deletedAt;

    const changedFields: { [k: VersionKey]: { added?: string; removed?: string; value?: string } } = {};
    for (const [k, newValue] of Object.entries(unPatchedFields)) {
        if (!!!previousVersion || previousVersion[k as keyof Version] !== newValue) {
            changedFields[k as keyof Version] = {
                added: newValue,
                removed: previousVersion ? previousVersion[k as keyof Version] : "null",
            };
        } else if (full) {
            changedFields[k as keyof Version] = {
                value: newValue,
            };
        }
    }
    return changedFields;
};

export const useRestoreInteraction = (interactionId: number) => {
    const params = useParams<IInteractionLocationParams>();
    const { section } = params;
    const [updateInteraction] = useUpdateInteractionInProgram();
    const [editInteractionMutation] = useUpdateInteractionMutation();

    const [getVersionAt] = useInteractionAtVersionLazyQuery();
    const { organization, program, refetchPrograms, loc } = useCurrentProgram();

    const targetContents: IInteractionArrow[] = program?.payload[section] || [];

    const restoreInteraction = async (targetVersionId: number) => {
        const interactionWithVersion = await getVersionAt({
            variables: {
                id: interactionId,
                versionId: targetVersionId,
            },
        });
        const interactionAtVersion = interactionWithVersion.data?.interaction?.atVersion;

        await editInteractionMutation({ variables: interactionAtVersion });
        updateInteraction(targetContents, loc, section, {
            id: interactionAtVersion.id,
            name: interactionAtVersion.name,
            slug: interactionAtVersion.slug,
        });
        refetchPrograms();
        window.location.reload();
    };
    return [restoreInteraction];
};
