import * as React from "react";
const { useEffect, useState, useRef, useMemo } = React;

import * as Modal from "react-modal";
import * as moment from "moment";

import { Note } from "../../../../shared/graphql/query";
import { GenericInteraction } from "../../models/program";
import { VersionModal } from "../Version/VersionModal";

const style = {
    button: "b ph3 pv2 input-reset ba b--light-gray black-40 bg-transparent grow pointer f6 dib mr1 right",
};

enum ModalStates {
    CLOSED,
    SHOW_NOTE_VERSIONS,
    DELETE,
}

export const EditorFooter = ({
    containerRef,
    note,
    isUnsaved,
    failure,
}: {
    containerRef?: React.MutableRefObject<any>;
    note: Partial<Note> | GenericInteraction;
    isUnsaved: () => boolean;
    failure: { count: number; message: string };
}) => {
    const [advancedOpen, setAdvancedOpen] = useState(false);
    const [modalState, setModalState] = useState(ModalStates.CLOSED);
    const lastSavedText = useLastSavedText(note);

    return (
        <div ref={containerRef} className="pb5">
            <input
                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                type="submit"
                value="Save"
            />
            {note.id && (
                <Dropdown label="Advanced" isOpen={advancedOpen} onClick={() => setAdvancedOpen(!!!advancedOpen)}>
                    <input
                        className={style.button}
                        type="button"
                        value="Show Note Versions"
                        onClick={() => setModalState(ModalStates.SHOW_NOTE_VERSIONS)}
                    />
                    <input
                        className={style.button}
                        type="button"
                        value="Delete"
                        onClick={() => setModalState(ModalStates.DELETE)}
                    />
                </Dropdown>
            )}
            {isUnsaved() && <small className="f6 black-60 di ma1">Unsaved.</small>}
            {note && note.updatedAt && <small className="f6 black-60 di ma1">{lastSavedText}</small>}
            {failure.count === 1 && (
                <small className="f6 black-60 di ma1">
                    Something went wrong and we couldn&rsquo;t save. Try again.
                </small>
            )}
            {failure.count > 1 && (
                <small className="f6 black-60 di ma1">
                    Trying again didn&rsquo;t help. Please shoot us an email at hello@ctoscano.com. Failure Message:{" "}
                    <pre>{failure.message}</pre>
                </small>
            )}

            <Modal isOpen={modalState === ModalStates.SHOW_NOTE_VERSIONS} ariaHideApp={false}>
                <VersionModal id={note.id} close={() => setModalState(ModalStates.CLOSED)} />
            </Modal>
        </div>
    );
};

const useLastSavedText = (note?: { updatedAt?: Date }) => {
    const [ago, setAgo] = useState("");
    const [lastTimer, setLastTimer] = useState(null);
    const updateAgo = () => {
        const updatedAt = moment.utc(note.updatedAt);
        setAgo(updatedAt.fromNow());
        return (updatedAt.diff as () => number)() * -1;
    };

    useEffect(() => {
        const diff = updateAgo();
        console.log("set timer for ", diff / 1000, " seconds from now", note.updatedAt);
        const timer = setTimeout(() => {
            console.log("ran timer", timer);
            updateAgo();
            setLastTimer(timer);
        }, Math.max(diff / 5, 10 * 1000));

        return () => clearTimeout(timer);
    }, [note, note?.updatedAt, lastTimer]);

    // const ago = moment.utc(note.updatedAt).fromNow();
    return `Last saved ${ago}`;
};

const Dropdown: React.FC<{ label: string; isOpen: boolean; onClick: () => void }> = (props) => {
    return (
        <div className="di relative">
            <a href={null} className="f6 black-60 di ma1 pointer" onClick={props.onClick}>
                {props.label}
            </a>
            {props.isOpen && (
                <div className="absolute left-0 bg-white" style={{ top: "-400%" }} onClick={props.onClick}>
                    {props.children}
                </div>
            )}
        </div>
    );
};
