import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};

export type Alert = {
  __typename?: 'Alert';
  createdAt: Scalars['Datetime'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ApplyTokenInput = {
  details?: InputMaybe<Scalars['JSON']>;
  key: Scalars['String'];
};

export type ApplyTokenPayload = {
  __typename?: 'ApplyTokenPayload';
  organization?: Maybe<Organization>;
};

export type AtVersionInput = {
  versionId: Scalars['Int'];
};

export type AuthenticateUserInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type AuthenticateUserPayload = {
  __typename?: 'AuthenticateUserPayload';
  jwt?: Maybe<Scalars['String']>;
  query?: Maybe<Query>;
  user?: Maybe<User>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** All input for the create `Interaction` mutation. */
export type CreateInteractionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Interaction` to be created by this mutation. */
  interaction: InteractionInput;
};

/** The output of our create `Interaction` mutation. */
export type CreateInteractionPayload = {
  __typename?: 'CreateInteractionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Interaction` that was created by this mutation. */
  interaction?: Maybe<Interaction>;
  /** An edge for our `Interaction`. May be used by Relay 1. */
  interactionEdge?: Maybe<InteractionsEdge>;
  /** Reads a single `Organization` that is related to this `Interaction`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Interaction`. */
  user?: Maybe<User>;
};


/** The output of our create `Interaction` mutation. */
export type CreateInteractionPayloadInteractionEdgeArgs = {
  orderBy?: InputMaybe<Array<InteractionsOrderBy>>;
};

/** All input for the create `InteractionVersion` mutation. */
export type CreateInteractionVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `InteractionVersion` to be created by this mutation. */
  interactionVersion: InteractionVersionInput;
};

/** The output of our create `InteractionVersion` mutation. */
export type CreateInteractionVersionPayload = {
  __typename?: 'CreateInteractionVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Interaction` that is related to this `InteractionVersion`. */
  interaction?: Maybe<Interaction>;
  /** The `InteractionVersion` that was created by this mutation. */
  interactionVersion?: Maybe<InteractionVersion>;
  /** An edge for our `InteractionVersion`. May be used by Relay 1. */
  interactionVersionEdge?: Maybe<InteractionVersionsEdge>;
  /** Reads a single `Organization` that is related to this `InteractionVersion`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  user?: Maybe<User>;
};


/** The output of our create `InteractionVersion` mutation. */
export type CreateInteractionVersionPayloadInteractionVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};

/** All input for the create `Membership` mutation. */
export type CreateMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Membership` to be created by this mutation. */
  membership: MembershipInput;
};

/** The output of our create `Membership` mutation. */
export type CreateMembershipPayload = {
  __typename?: 'CreateMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Membership` that was created by this mutation. */
  membership?: Maybe<Membership>;
  /** An edge for our `Membership`. May be used by Relay 1. */
  membershipEdge?: Maybe<MembershipsEdge>;
  /** Reads a single `Organization` that is related to this `Membership`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
};


/** The output of our create `Membership` mutation. */
export type CreateMembershipPayloadMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};

/** All input for the create `Note` mutation. */
export type CreateNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Note` to be created by this mutation. */
  note: NoteInput;
};

/** The output of our create `Note` mutation. */
export type CreateNotePayload = {
  __typename?: 'CreateNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Note` that was created by this mutation. */
  note?: Maybe<Note>;
  /** An edge for our `Note`. May be used by Relay 1. */
  noteEdge?: Maybe<NotesEdge>;
  /** Reads a single `Organization` that is related to this `Note`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Note`. */
  user?: Maybe<User>;
};


/** The output of our create `Note` mutation. */
export type CreateNotePayloadNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

/** All input for the create `NoteVersion` mutation. */
export type CreateNoteVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `NoteVersion` to be created by this mutation. */
  noteVersion: NoteVersionInput;
};

/** The output of our create `NoteVersion` mutation. */
export type CreateNoteVersionPayload = {
  __typename?: 'CreateNoteVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Note` that is related to this `NoteVersion`. */
  note?: Maybe<Note>;
  /** The `NoteVersion` that was created by this mutation. */
  noteVersion?: Maybe<NoteVersion>;
  /** An edge for our `NoteVersion`. May be used by Relay 1. */
  noteVersionEdge?: Maybe<NoteVersionsEdge>;
  /** Reads a single `Organization` that is related to this `NoteVersion`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  user?: Maybe<User>;
};


/** The output of our create `NoteVersion` mutation. */
export type CreateNoteVersionPayloadNoteVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};

/** All input for the create `Organization` mutation. */
export type CreateOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Organization` to be created by this mutation. */
  organization: OrganizationInput;
};

/** The output of our create `Organization` mutation. */
export type CreateOrganizationPayload = {
  __typename?: 'CreateOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Organization` that was created by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Reads a single `User` that is related to this `Organization`. */
  owner?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Organization` mutation. */
export type CreateOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the create `Program` mutation. */
export type CreateProgramInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Program` to be created by this mutation. */
  program: ProgramInput;
};

/** The output of our create `Program` mutation. */
export type CreateProgramPayload = {
  __typename?: 'CreateProgramPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Organization` that is related to this `Program`. */
  org?: Maybe<Organization>;
  /** The `Program` that was created by this mutation. */
  program?: Maybe<Program>;
  /** An edge for our `Program`. May be used by Relay 1. */
  programEdge?: Maybe<ProgramsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Program`. */
  user?: Maybe<User>;
};


/** The output of our create `Program` mutation. */
export type CreateProgramPayloadProgramEdgeArgs = {
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};

/** All input for the create `ProgramVersion` mutation. */
export type CreateProgramVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProgramVersion` to be created by this mutation. */
  programVersion: ProgramVersionInput;
};

/** The output of our create `ProgramVersion` mutation. */
export type CreateProgramVersionPayload = {
  __typename?: 'CreateProgramVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Organization` that is related to this `ProgramVersion`. */
  org?: Maybe<Organization>;
  /** Reads a single `Program` that is related to this `ProgramVersion`. */
  program?: Maybe<Program>;
  /** The `ProgramVersion` that was created by this mutation. */
  programVersion?: Maybe<ProgramVersion>;
  /** An edge for our `ProgramVersion`. May be used by Relay 1. */
  programVersionEdge?: Maybe<ProgramVersionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  user?: Maybe<User>;
};


/** The output of our create `ProgramVersion` mutation. */
export type CreateProgramVersionPayloadProgramVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};

/** All input for the create `State` mutation. */
export type CreateStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `State` to be created by this mutation. */
  state: StateInput;
};

/** The output of our create `State` mutation. */
export type CreateStatePayload = {
  __typename?: 'CreateStatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Interaction` that is related to this `State`. */
  interaction?: Maybe<Interaction>;
  /** Reads a single `Program` that is related to this `State`. */
  program?: Maybe<Program>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `State` that was created by this mutation. */
  state?: Maybe<State>;
  /** An edge for our `State`. May be used by Relay 1. */
  stateEdge?: Maybe<StatesEdge>;
  /** Reads a single `User` that is related to this `State`. */
  user?: Maybe<User>;
};


/** The output of our create `State` mutation. */
export type CreateStatePayloadStateEdgeArgs = {
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** All input for the create `Token` mutation. */
export type CreateTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Token` to be created by this mutation. */
  token: TokenInput;
};

/** The output of our create `Token` mutation. */
export type CreateTokenPayload = {
  __typename?: 'CreateTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Token`. */
  grantedBy?: Maybe<User>;
  /** Reads a single `Note` that is related to this `Token`. */
  note?: Maybe<Note>;
  /** Reads a single `Organization` that is related to this `Token`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Token` that was created by this mutation. */
  token?: Maybe<Token>;
  /** An edge for our `Token`. May be used by Relay 1. */
  tokenEdge?: Maybe<TokensEdge>;
};


/** The output of our create `Token` mutation. */
export type CreateTokenPayloadTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** All input for the create `TypeormMetadatum` mutation. */
export type CreateTypeormMetadatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TypeormMetadatum` to be created by this mutation. */
  typeormMetadatum: TypeormMetadatumInput;
};

/** The output of our create `TypeormMetadatum` mutation. */
export type CreateTypeormMetadatumPayload = {
  __typename?: 'CreateTypeormMetadatumPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `TypeormMetadatum` that was created by this mutation. */
  typeormMetadatum?: Maybe<TypeormMetadatum>;
  /** An edge for our `TypeormMetadatum`. May be used by Relay 1. */
  typeormMetadatumEdge?: Maybe<TypeormMetadataEdge>;
};


/** The output of our create `TypeormMetadatum` mutation. */
export type CreateTypeormMetadatumPayloadTypeormMetadatumEdgeArgs = {
  orderBy?: InputMaybe<Array<TypeormMetadataOrderBy>>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was created by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our create `User` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  id: Scalars['Int'];
  username: Scalars['String'];
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']>>;
};

/** All input for the `deleteInteractionByNodeId` mutation. */
export type DeleteInteractionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Interaction` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteInteraction` mutation. */
export type DeleteInteractionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Interaction` mutation. */
export type DeleteInteractionPayload = {
  __typename?: 'DeleteInteractionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedInteractionNodeId?: Maybe<Scalars['ID']>;
  /** The `Interaction` that was deleted by this mutation. */
  interaction?: Maybe<Interaction>;
  /** An edge for our `Interaction`. May be used by Relay 1. */
  interactionEdge?: Maybe<InteractionsEdge>;
  /** Reads a single `Organization` that is related to this `Interaction`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Interaction`. */
  user?: Maybe<User>;
};


/** The output of our delete `Interaction` mutation. */
export type DeleteInteractionPayloadInteractionEdgeArgs = {
  orderBy?: InputMaybe<Array<InteractionsOrderBy>>;
};

/** All input for the `deleteInteractionVersionByNodeId` mutation. */
export type DeleteInteractionVersionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InteractionVersion` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteInteractionVersion` mutation. */
export type DeleteInteractionVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `InteractionVersion` mutation. */
export type DeleteInteractionVersionPayload = {
  __typename?: 'DeleteInteractionVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedInteractionVersionNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Interaction` that is related to this `InteractionVersion`. */
  interaction?: Maybe<Interaction>;
  /** The `InteractionVersion` that was deleted by this mutation. */
  interactionVersion?: Maybe<InteractionVersion>;
  /** An edge for our `InteractionVersion`. May be used by Relay 1. */
  interactionVersionEdge?: Maybe<InteractionVersionsEdge>;
  /** Reads a single `Organization` that is related to this `InteractionVersion`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  user?: Maybe<User>;
};


/** The output of our delete `InteractionVersion` mutation. */
export type DeleteInteractionVersionPayloadInteractionVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};

/** All input for the `deleteMembershipByNodeId` mutation. */
export type DeleteMembershipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Membership` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMembership` mutation. */
export type DeleteMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Membership` mutation. */
export type DeleteMembershipPayload = {
  __typename?: 'DeleteMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedMembershipNodeId?: Maybe<Scalars['ID']>;
  /** The `Membership` that was deleted by this mutation. */
  membership?: Maybe<Membership>;
  /** An edge for our `Membership`. May be used by Relay 1. */
  membershipEdge?: Maybe<MembershipsEdge>;
  /** Reads a single `Organization` that is related to this `Membership`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
};


/** The output of our delete `Membership` mutation. */
export type DeleteMembershipPayloadMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};

/** All input for the `deleteNoteByNodeId` mutation. */
export type DeleteNoteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Note` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteNote` mutation. */
export type DeleteNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Note` mutation. */
export type DeleteNotePayload = {
  __typename?: 'DeleteNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedNoteNodeId?: Maybe<Scalars['ID']>;
  /** The `Note` that was deleted by this mutation. */
  note?: Maybe<Note>;
  /** An edge for our `Note`. May be used by Relay 1. */
  noteEdge?: Maybe<NotesEdge>;
  /** Reads a single `Organization` that is related to this `Note`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Note`. */
  user?: Maybe<User>;
};


/** The output of our delete `Note` mutation. */
export type DeleteNotePayloadNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

/** All input for the `deleteNoteVersionByNodeId` mutation. */
export type DeleteNoteVersionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NoteVersion` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteNoteVersion` mutation. */
export type DeleteNoteVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `NoteVersion` mutation. */
export type DeleteNoteVersionPayload = {
  __typename?: 'DeleteNoteVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedNoteVersionNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Note` that is related to this `NoteVersion`. */
  note?: Maybe<Note>;
  /** The `NoteVersion` that was deleted by this mutation. */
  noteVersion?: Maybe<NoteVersion>;
  /** An edge for our `NoteVersion`. May be used by Relay 1. */
  noteVersionEdge?: Maybe<NoteVersionsEdge>;
  /** Reads a single `Organization` that is related to this `NoteVersion`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  user?: Maybe<User>;
};


/** The output of our delete `NoteVersion` mutation. */
export type DeleteNoteVersionPayloadNoteVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};

/** All input for the `deleteOrganizationByNodeId` mutation. */
export type DeleteOrganizationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Organization` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOrganizationByUrl` mutation. */
export type DeleteOrganizationByUrlInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

/** All input for the `deleteOrganization` mutation. */
export type DeleteOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Organization` mutation. */
export type DeleteOrganizationPayload = {
  __typename?: 'DeleteOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedOrganizationNodeId?: Maybe<Scalars['ID']>;
  /** The `Organization` that was deleted by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Reads a single `User` that is related to this `Organization`. */
  owner?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Organization` mutation. */
export type DeleteOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the `deleteProgramByNodeId` mutation. */
export type DeleteProgramByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Program` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProgramBySlugAndOrgId` mutation. */
export type DeleteProgramBySlugAndOrgIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orgId: Scalars['Int'];
  slug: Scalars['String'];
};

/** All input for the `deleteProgram` mutation. */
export type DeleteProgramInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Program` mutation. */
export type DeleteProgramPayload = {
  __typename?: 'DeleteProgramPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedProgramNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Organization` that is related to this `Program`. */
  org?: Maybe<Organization>;
  /** The `Program` that was deleted by this mutation. */
  program?: Maybe<Program>;
  /** An edge for our `Program`. May be used by Relay 1. */
  programEdge?: Maybe<ProgramsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Program`. */
  user?: Maybe<User>;
};


/** The output of our delete `Program` mutation. */
export type DeleteProgramPayloadProgramEdgeArgs = {
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};

/** All input for the `deleteProgramVersionByNodeId` mutation. */
export type DeleteProgramVersionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProgramVersion` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProgramVersion` mutation. */
export type DeleteProgramVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ProgramVersion` mutation. */
export type DeleteProgramVersionPayload = {
  __typename?: 'DeleteProgramVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedProgramVersionNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Organization` that is related to this `ProgramVersion`. */
  org?: Maybe<Organization>;
  /** Reads a single `Program` that is related to this `ProgramVersion`. */
  program?: Maybe<Program>;
  /** The `ProgramVersion` that was deleted by this mutation. */
  programVersion?: Maybe<ProgramVersion>;
  /** An edge for our `ProgramVersion`. May be used by Relay 1. */
  programVersionEdge?: Maybe<ProgramVersionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  user?: Maybe<User>;
};


/** The output of our delete `ProgramVersion` mutation. */
export type DeleteProgramVersionPayloadProgramVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};

/** All input for the `deleteStateByNodeId` mutation. */
export type DeleteStateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `State` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteState` mutation. */
export type DeleteStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `State` mutation. */
export type DeleteStatePayload = {
  __typename?: 'DeleteStatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedStateNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Interaction` that is related to this `State`. */
  interaction?: Maybe<Interaction>;
  /** Reads a single `Program` that is related to this `State`. */
  program?: Maybe<Program>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `State` that was deleted by this mutation. */
  state?: Maybe<State>;
  /** An edge for our `State`. May be used by Relay 1. */
  stateEdge?: Maybe<StatesEdge>;
  /** Reads a single `User` that is related to this `State`. */
  user?: Maybe<User>;
};


/** The output of our delete `State` mutation. */
export type DeleteStatePayloadStateEdgeArgs = {
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** All input for the `deleteTokenByNodeId` mutation. */
export type DeleteTokenByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Token` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteToken` mutation. */
export type DeleteTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Token` mutation. */
export type DeleteTokenPayload = {
  __typename?: 'DeleteTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTokenNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `User` that is related to this `Token`. */
  grantedBy?: Maybe<User>;
  /** Reads a single `Note` that is related to this `Token`. */
  note?: Maybe<Note>;
  /** Reads a single `Organization` that is related to this `Token`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Token` that was deleted by this mutation. */
  token?: Maybe<Token>;
  /** An edge for our `Token`. May be used by Relay 1. */
  tokenEdge?: Maybe<TokensEdge>;
};


/** The output of our delete `Token` mutation. */
export type DeleteTokenPayloadTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** All input for the `deleteUserByNodeId` mutation. */
export type DeleteUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was deleted by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our delete `User` mutation. */
export type DeleteUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type DisableTokenInput = {
  key: Scalars['String'];
};

export type DisableTokenPayload = {
  __typename?: 'DisableTokenPayload';
  token?: Maybe<Token>;
};

export type GenerateTokenInput = {
  details?: InputMaybe<Scalars['JSON']>;
  orgId?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type GenerateTokenPayload = {
  __typename?: 'GenerateTokenPayload';
  token?: Maybe<Token>;
};

/** Indicates whether archived items should be included in the results or not. */
export enum IncludeArchivedOption {
  /** Only include archived items (i.e. exclude non-archived items). */
  Exclusively = 'EXCLUSIVELY',
  /** If there is a parent GraphQL record and it is archived then this is equivalent to YES, in all other cases this is equivalent to NO. */
  Inherit = 'INHERIT',
  /** Exclude archived items. */
  No = 'NO',
  /** Include archived items. */
  Yes = 'YES'
}

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Interaction = Node & {
  __typename?: 'Interaction';
  access: InteractionAccessEnum;
  atVersion: Interaction;
  branch?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `InteractionVersion`. */
  interactionVersions: InteractionVersionsConnection;
  isPublic: Scalars['Boolean'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `Interaction`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  rootId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: InteractionStateEnum;
  /** Reads and enables pagination through a set of `State`. */
  states: StatesConnection;
  subtype?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Interaction`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  version: Scalars['String'];
};


export type InteractionAtVersionArgs = {
  input: AtVersionInput;
};


export type InteractionInteractionVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionVersionCondition>;
  filter?: InputMaybe<InteractionVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};


export type InteractionStatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StateCondition>;
  filter?: InputMaybe<StateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

export enum InteractionAccessEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Semipublic = 'SEMIPUBLIC',
  Shared = 'SHARED'
}

/** A filter to be used against InteractionAccessEnum fields. All fields are combined with a logical ‘and.’ */
export type InteractionAccessEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<InteractionAccessEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<InteractionAccessEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<InteractionAccessEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<InteractionAccessEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<InteractionAccessEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<InteractionAccessEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<InteractionAccessEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<InteractionAccessEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<InteractionAccessEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<InteractionAccessEnum>>;
};

/**
 * A condition to be used against `Interaction` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type InteractionCondition = {
  /** Checks for equality with the object’s `access` field. */
  access?: InputMaybe<InteractionAccessEnum>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `rootId` field. */
  rootId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<InteractionStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Interaction` object types. All fields are combined with a logical ‘and.’ */
export type InteractionFilter = {
  /** Filter by the object’s `access` field. */
  access?: InputMaybe<InteractionAccessEnumFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InteractionFilter>>;
  /** Filter by the object’s `branch` field. */
  branch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InteractionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InteractionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rootId` field. */
  rootId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<InteractionStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Interaction` */
export type InteractionInput = {
  access?: InputMaybe<InteractionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InteractionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version: Scalars['String'];
};

/** Represents an update to a `Interaction`. Fields that are set will be updated. */
export type InteractionPatch = {
  access?: InputMaybe<InteractionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InteractionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

export enum InteractionStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** A filter to be used against InteractionStateEnum fields. All fields are combined with a logical ‘and.’ */
export type InteractionStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<InteractionStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<InteractionStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<InteractionStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<InteractionStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<InteractionStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<InteractionStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<InteractionStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<InteractionStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<InteractionStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<InteractionStateEnum>>;
};

export type InteractionVersion = Node & {
  __typename?: 'InteractionVersion';
  access: InteractionVersionAccessEnum;
  branch?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  editor?: Maybe<User>;
  editorId: Scalars['Int'];
  id: Scalars['Int'];
  /** Reads a single `Interaction` that is related to this `InteractionVersion`. */
  interaction?: Maybe<Interaction>;
  interactionId: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `InteractionVersion`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  rootId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: InteractionVersionStateEnum;
  subtype?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

export enum InteractionVersionAccessEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Semipublic = 'SEMIPUBLIC',
  Shared = 'SHARED'
}

/** A filter to be used against InteractionVersionAccessEnum fields. All fields are combined with a logical ‘and.’ */
export type InteractionVersionAccessEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<InteractionVersionAccessEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<InteractionVersionAccessEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<InteractionVersionAccessEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<InteractionVersionAccessEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<InteractionVersionAccessEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<InteractionVersionAccessEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<InteractionVersionAccessEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<InteractionVersionAccessEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<InteractionVersionAccessEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<InteractionVersionAccessEnum>>;
};

/**
 * A condition to be used against `InteractionVersion` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type InteractionVersionCondition = {
  /** Checks for equality with the object’s `access` field. */
  access?: InputMaybe<InteractionVersionAccessEnum>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `editorId` field. */
  editorId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `interactionId` field. */
  interactionId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `rootId` field. */
  rootId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<InteractionVersionStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `InteractionVersion` object types. All fields are combined with a logical ‘and.’ */
export type InteractionVersionFilter = {
  /** Filter by the object’s `access` field. */
  access?: InputMaybe<InteractionVersionAccessEnumFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InteractionVersionFilter>>;
  /** Filter by the object’s `branch` field. */
  branch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `editorId` field. */
  editorId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `interactionId` field. */
  interactionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InteractionVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InteractionVersionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rootId` field. */
  rootId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<InteractionVersionStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `InteractionVersion` */
export type InteractionVersionInput = {
  access?: InputMaybe<InteractionVersionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  editorId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  interactionId: Scalars['Int'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InteractionVersionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `InteractionVersion`. Fields that are set will be updated. */
export type InteractionVersionPatch = {
  access?: InputMaybe<InteractionVersionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  editorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interactionId?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<InteractionVersionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

export enum InteractionVersionStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** A filter to be used against InteractionVersionStateEnum fields. All fields are combined with a logical ‘and.’ */
export type InteractionVersionStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<InteractionVersionStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<InteractionVersionStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<InteractionVersionStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<InteractionVersionStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<InteractionVersionStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<InteractionVersionStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<InteractionVersionStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<InteractionVersionStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<InteractionVersionStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<InteractionVersionStateEnum>>;
};

/** A connection to a list of `InteractionVersion` values. */
export type InteractionVersionsConnection = {
  __typename?: 'InteractionVersionsConnection';
  /** A list of edges which contains the `InteractionVersion` and cursor to aid in pagination. */
  edges: Array<InteractionVersionsEdge>;
  /** A list of `InteractionVersion` objects. */
  nodes: Array<Maybe<InteractionVersion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InteractionVersion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `InteractionVersion` edge in the connection. */
export type InteractionVersionsEdge = {
  __typename?: 'InteractionVersionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InteractionVersion` at the end of the edge. */
  node?: Maybe<InteractionVersion>;
};

/** Methods to use when ordering `InteractionVersion`. */
export enum InteractionVersionsOrderBy {
  AccessAsc = 'ACCESS_ASC',
  AccessDesc = 'ACCESS_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  EditorIdAsc = 'EDITOR_ID_ASC',
  EditorIdDesc = 'EDITOR_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InteractionIdAsc = 'INTERACTION_ID_ASC',
  InteractionIdDesc = 'INTERACTION_ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RootIdAsc = 'ROOT_ID_ASC',
  RootIdDesc = 'ROOT_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC'
}

/** A connection to a list of `Interaction` values. */
export type InteractionsConnection = {
  __typename?: 'InteractionsConnection';
  /** A list of edges which contains the `Interaction` and cursor to aid in pagination. */
  edges: Array<InteractionsEdge>;
  /** A list of `Interaction` objects. */
  nodes: Array<Maybe<Interaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Interaction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Interaction` edge in the connection. */
export type InteractionsEdge = {
  __typename?: 'InteractionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Interaction` at the end of the edge. */
  node?: Maybe<Interaction>;
};

/** Methods to use when ordering `Interaction`. */
export enum InteractionsOrderBy {
  AccessAsc = 'ACCESS_ASC',
  AccessDesc = 'ACCESS_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RootIdAsc = 'ROOT_ID_ASC',
  RootIdDesc = 'ROOT_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC'
}

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars['JSON']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['JSON']>>;
};

export type Membership = Node & {
  __typename?: 'Membership';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `Membership`. */
  org?: Maybe<Organization>;
  orgId: Scalars['Int'];
  payload: Scalars['JSON'];
  state: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `Membership` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MembershipCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Membership` object types. All fields are combined with a logical ‘and.’ */
export type MembershipFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MembershipFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MembershipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MembershipFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Membership` */
export type MembershipInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  orgId: Scalars['Int'];
  payload?: InputMaybe<Scalars['JSON']>;
  state: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Membership`. Fields that are set will be updated. */
export type MembershipPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  state?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Membership` values. */
export type MembershipsConnection = {
  __typename?: 'MembershipsConnection';
  /** A list of edges which contains the `Membership` and cursor to aid in pagination. */
  edges: Array<MembershipsEdge>;
  /** A list of `Membership` objects. */
  nodes: Array<Maybe<Membership>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Membership` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Membership` edge in the connection. */
export type MembershipsEdge = {
  __typename?: 'MembershipsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Membership` at the end of the edge. */
  node?: Maybe<Membership>;
};

/** Methods to use when ordering `Membership`. */
export enum MembershipsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  applyToken?: Maybe<ApplyTokenPayload>;
  authenticateUser?: Maybe<AuthenticateUserPayload>;
  /** Creates a single `Interaction`. */
  createInteraction?: Maybe<CreateInteractionPayload>;
  /** Creates a single `InteractionVersion`. */
  createInteractionVersion?: Maybe<CreateInteractionVersionPayload>;
  /** Creates a single `Membership`. */
  createMembership?: Maybe<CreateMembershipPayload>;
  /** Creates a single `Note`. */
  createNote?: Maybe<CreateNotePayload>;
  /** Creates a single `NoteVersion`. */
  createNoteVersion?: Maybe<CreateNoteVersionPayload>;
  /** Creates a single `Organization`. */
  createOrganization?: Maybe<CreateOrganizationPayload>;
  /** Creates a single `Program`. */
  createProgram?: Maybe<CreateProgramPayload>;
  /** Creates a single `ProgramVersion`. */
  createProgramVersion?: Maybe<CreateProgramVersionPayload>;
  /** Creates a single `State`. */
  createState?: Maybe<CreateStatePayload>;
  /** Creates a single `Token`. */
  createToken?: Maybe<CreateTokenPayload>;
  /** Creates a single `TypeormMetadatum`. */
  createTypeormMetadatum?: Maybe<CreateTypeormMetadatumPayload>;
  /** Creates a single `User`. */
  createUser?: Maybe<CreateUserPayload>;
  /** Deletes a single `Interaction` using a unique key. */
  deleteInteraction?: Maybe<DeleteInteractionPayload>;
  /** Deletes a single `Interaction` using its globally unique id. */
  deleteInteractionByNodeId?: Maybe<DeleteInteractionPayload>;
  /** Deletes a single `InteractionVersion` using a unique key. */
  deleteInteractionVersion?: Maybe<DeleteInteractionVersionPayload>;
  /** Deletes a single `InteractionVersion` using its globally unique id. */
  deleteInteractionVersionByNodeId?: Maybe<DeleteInteractionVersionPayload>;
  /** Deletes a single `Membership` using a unique key. */
  deleteMembership?: Maybe<DeleteMembershipPayload>;
  /** Deletes a single `Membership` using its globally unique id. */
  deleteMembershipByNodeId?: Maybe<DeleteMembershipPayload>;
  /** Deletes a single `Note` using a unique key. */
  deleteNote?: Maybe<DeleteNotePayload>;
  /** Deletes a single `Note` using its globally unique id. */
  deleteNoteByNodeId?: Maybe<DeleteNotePayload>;
  /** Deletes a single `NoteVersion` using a unique key. */
  deleteNoteVersion?: Maybe<DeleteNoteVersionPayload>;
  /** Deletes a single `NoteVersion` using its globally unique id. */
  deleteNoteVersionByNodeId?: Maybe<DeleteNoteVersionPayload>;
  /** Deletes a single `Organization` using a unique key. */
  deleteOrganization?: Maybe<DeleteOrganizationPayload>;
  /** Deletes a single `Organization` using its globally unique id. */
  deleteOrganizationByNodeId?: Maybe<DeleteOrganizationPayload>;
  /** Deletes a single `Organization` using a unique key. */
  deleteOrganizationByUrl?: Maybe<DeleteOrganizationPayload>;
  /** Deletes a single `Program` using a unique key. */
  deleteProgram?: Maybe<DeleteProgramPayload>;
  /** Deletes a single `Program` using its globally unique id. */
  deleteProgramByNodeId?: Maybe<DeleteProgramPayload>;
  /** Deletes a single `Program` using a unique key. */
  deleteProgramBySlugAndOrgId?: Maybe<DeleteProgramPayload>;
  /** Deletes a single `ProgramVersion` using a unique key. */
  deleteProgramVersion?: Maybe<DeleteProgramVersionPayload>;
  /** Deletes a single `ProgramVersion` using its globally unique id. */
  deleteProgramVersionByNodeId?: Maybe<DeleteProgramVersionPayload>;
  /** Deletes a single `State` using a unique key. */
  deleteState?: Maybe<DeleteStatePayload>;
  /** Deletes a single `State` using its globally unique id. */
  deleteStateByNodeId?: Maybe<DeleteStatePayload>;
  /** Deletes a single `Token` using a unique key. */
  deleteToken?: Maybe<DeleteTokenPayload>;
  /** Deletes a single `Token` using its globally unique id. */
  deleteTokenByNodeId?: Maybe<DeleteTokenPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using its globally unique id. */
  deleteUserByNodeId?: Maybe<DeleteUserPayload>;
  disableToken?: Maybe<DisableTokenPayload>;
  generateToken?: Maybe<GenerateTokenPayload>;
  refreshToken?: Maybe<RefreshTokenPayload>;
  registerUser?: Maybe<RegisterUserPayload>;
  setUser: CurrentUser;
  /** Updates a single `Interaction` using a unique key and a patch. */
  updateInteraction?: Maybe<UpdateInteractionPayload>;
  /** Updates a single `Interaction` using its globally unique id and a patch. */
  updateInteractionByNodeId?: Maybe<UpdateInteractionPayload>;
  /** Updates a single `InteractionVersion` using a unique key and a patch. */
  updateInteractionVersion?: Maybe<UpdateInteractionVersionPayload>;
  /** Updates a single `InteractionVersion` using its globally unique id and a patch. */
  updateInteractionVersionByNodeId?: Maybe<UpdateInteractionVersionPayload>;
  /** Updates a single `Membership` using a unique key and a patch. */
  updateMembership?: Maybe<UpdateMembershipPayload>;
  /** Updates a single `Membership` using its globally unique id and a patch. */
  updateMembershipByNodeId?: Maybe<UpdateMembershipPayload>;
  /** Updates a single `Note` using a unique key and a patch. */
  updateNote?: Maybe<UpdateNotePayload>;
  /** Updates a single `Note` using its globally unique id and a patch. */
  updateNoteByNodeId?: Maybe<UpdateNotePayload>;
  /** Updates a single `NoteVersion` using a unique key and a patch. */
  updateNoteVersion?: Maybe<UpdateNoteVersionPayload>;
  /** Updates a single `NoteVersion` using its globally unique id and a patch. */
  updateNoteVersionByNodeId?: Maybe<UpdateNoteVersionPayload>;
  /** Updates a single `Organization` using a unique key and a patch. */
  updateOrganization?: Maybe<UpdateOrganizationPayload>;
  /** Updates a single `Organization` using its globally unique id and a patch. */
  updateOrganizationByNodeId?: Maybe<UpdateOrganizationPayload>;
  /** Updates a single `Organization` using a unique key and a patch. */
  updateOrganizationByUrl?: Maybe<UpdateOrganizationPayload>;
  /** Updates a single `Program` using a unique key and a patch. */
  updateProgram?: Maybe<UpdateProgramPayload>;
  /** Updates a single `Program` using its globally unique id and a patch. */
  updateProgramByNodeId?: Maybe<UpdateProgramPayload>;
  /** Updates a single `Program` using a unique key and a patch. */
  updateProgramBySlugAndOrgId?: Maybe<UpdateProgramPayload>;
  /** Updates a single `ProgramVersion` using a unique key and a patch. */
  updateProgramVersion?: Maybe<UpdateProgramVersionPayload>;
  /** Updates a single `ProgramVersion` using its globally unique id and a patch. */
  updateProgramVersionByNodeId?: Maybe<UpdateProgramVersionPayload>;
  /** Updates a single `State` using a unique key and a patch. */
  updateState?: Maybe<UpdateStatePayload>;
  /** Updates a single `State` using its globally unique id and a patch. */
  updateStateByNodeId?: Maybe<UpdateStatePayload>;
  /** Updates a single `Token` using a unique key and a patch. */
  updateToken?: Maybe<UpdateTokenPayload>;
  /** Updates a single `Token` using its globally unique id and a patch. */
  updateTokenByNodeId?: Maybe<UpdateTokenPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUserByNodeId?: Maybe<UpdateUserPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationApplyTokenArgs = {
  input: ApplyTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAuthenticateUserArgs = {
  input: AuthenticateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateInteractionArgs = {
  input: CreateInteractionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateInteractionVersionArgs = {
  input: CreateInteractionVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMembershipArgs = {
  input: CreateMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNoteVersionArgs = {
  input: CreateNoteVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProgramArgs = {
  input: CreateProgramInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProgramVersionArgs = {
  input: CreateProgramVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateStateArgs = {
  input: CreateStateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTokenArgs = {
  input: CreateTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTypeormMetadatumArgs = {
  input: CreateTypeormMetadatumInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInteractionArgs = {
  input: DeleteInteractionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInteractionByNodeIdArgs = {
  input: DeleteInteractionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInteractionVersionArgs = {
  input: DeleteInteractionVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInteractionVersionByNodeIdArgs = {
  input: DeleteInteractionVersionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMembershipArgs = {
  input: DeleteMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMembershipByNodeIdArgs = {
  input: DeleteMembershipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNoteArgs = {
  input: DeleteNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNoteByNodeIdArgs = {
  input: DeleteNoteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNoteVersionArgs = {
  input: DeleteNoteVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNoteVersionByNodeIdArgs = {
  input: DeleteNoteVersionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationByNodeIdArgs = {
  input: DeleteOrganizationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationByUrlArgs = {
  input: DeleteOrganizationByUrlInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProgramArgs = {
  input: DeleteProgramInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProgramByNodeIdArgs = {
  input: DeleteProgramByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProgramBySlugAndOrgIdArgs = {
  input: DeleteProgramBySlugAndOrgIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProgramVersionArgs = {
  input: DeleteProgramVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProgramVersionByNodeIdArgs = {
  input: DeleteProgramVersionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStateArgs = {
  input: DeleteStateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStateByNodeIdArgs = {
  input: DeleteStateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTokenArgs = {
  input: DeleteTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTokenByNodeIdArgs = {
  input: DeleteTokenByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByNodeIdArgs = {
  input: DeleteUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDisableTokenArgs = {
  input: DisableTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateTokenArgs = {
  input: GenerateTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetUserArgs = {
  user?: InputMaybe<SetUserInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInteractionArgs = {
  input: UpdateInteractionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInteractionByNodeIdArgs = {
  input: UpdateInteractionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInteractionVersionArgs = {
  input: UpdateInteractionVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInteractionVersionByNodeIdArgs = {
  input: UpdateInteractionVersionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipArgs = {
  input: UpdateMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipByNodeIdArgs = {
  input: UpdateMembershipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNoteByNodeIdArgs = {
  input: UpdateNoteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNoteVersionArgs = {
  input: UpdateNoteVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNoteVersionByNodeIdArgs = {
  input: UpdateNoteVersionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationByNodeIdArgs = {
  input: UpdateOrganizationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationByUrlArgs = {
  input: UpdateOrganizationByUrlInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProgramArgs = {
  input: UpdateProgramInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProgramByNodeIdArgs = {
  input: UpdateProgramByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProgramBySlugAndOrgIdArgs = {
  input: UpdateProgramBySlugAndOrgIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProgramVersionArgs = {
  input: UpdateProgramVersionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProgramVersionByNodeIdArgs = {
  input: UpdateProgramVersionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStateArgs = {
  input: UpdateStateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStateByNodeIdArgs = {
  input: UpdateStateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTokenArgs = {
  input: UpdateTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTokenByNodeIdArgs = {
  input: UpdateTokenByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByNodeIdArgs = {
  input: UpdateUserByNodeIdInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type Note = Node & {
  __typename?: 'Note';
  access: NoteAccessEnum;
  atVersion: Note;
  branch?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `NoteVersion`. */
  noteVersions: NoteVersionsConnection;
  /** Reads a single `Organization` that is related to this `Note`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  rootId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: NoteStateEnum;
  subtype?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Token`. */
  tokens: TokensConnection;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Note`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};


export type NoteAtVersionArgs = {
  input: AtVersionInput;
};


export type NoteNoteVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteVersionCondition>;
  filter?: InputMaybe<NoteVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};


export type NoteTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TokenCondition>;
  filter?: InputMaybe<TokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

export enum NoteAccessEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Semipublic = 'SEMIPUBLIC',
  Shared = 'SHARED'
}

/** A filter to be used against NoteAccessEnum fields. All fields are combined with a logical ‘and.’ */
export type NoteAccessEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<NoteAccessEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<NoteAccessEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<NoteAccessEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<NoteAccessEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<NoteAccessEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<NoteAccessEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<NoteAccessEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<NoteAccessEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<NoteAccessEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<NoteAccessEnum>>;
};

/** A condition to be used against `Note` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type NoteCondition = {
  /** Checks for equality with the object’s `access` field. */
  access?: InputMaybe<NoteAccessEnum>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `rootId` field. */
  rootId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<NoteStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Note` object types. All fields are combined with a logical ‘and.’ */
export type NoteFilter = {
  /** Filter by the object’s `access` field. */
  access?: InputMaybe<NoteAccessEnumFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NoteFilter>>;
  /** Filter by the object’s `branch` field. */
  branch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NoteFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NoteFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rootId` field. */
  rootId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<NoteStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Note` */
export type NoteInput = {
  access?: InputMaybe<NoteAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<NoteStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `Note`. Fields that are set will be updated. */
export type NotePatch = {
  access?: InputMaybe<NoteAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<NoteStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export enum NoteStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** A filter to be used against NoteStateEnum fields. All fields are combined with a logical ‘and.’ */
export type NoteStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<NoteStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<NoteStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<NoteStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<NoteStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<NoteStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<NoteStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<NoteStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<NoteStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<NoteStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<NoteStateEnum>>;
};

export type NoteVersion = Node & {
  __typename?: 'NoteVersion';
  access: NoteVersionAccessEnum;
  branch?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  editor?: Maybe<User>;
  editorId: Scalars['Int'];
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Note` that is related to this `NoteVersion`. */
  note?: Maybe<Note>;
  noteId: Scalars['Int'];
  /** Reads a single `Organization` that is related to this `NoteVersion`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  rootId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: NoteVersionStateEnum;
  subtype?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `NoteVersion`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

export enum NoteVersionAccessEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Semipublic = 'SEMIPUBLIC',
  Shared = 'SHARED'
}

/** A filter to be used against NoteVersionAccessEnum fields. All fields are combined with a logical ‘and.’ */
export type NoteVersionAccessEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<NoteVersionAccessEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<NoteVersionAccessEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<NoteVersionAccessEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<NoteVersionAccessEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<NoteVersionAccessEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<NoteVersionAccessEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<NoteVersionAccessEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<NoteVersionAccessEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<NoteVersionAccessEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<NoteVersionAccessEnum>>;
};

/**
 * A condition to be used against `NoteVersion` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type NoteVersionCondition = {
  /** Checks for equality with the object’s `access` field. */
  access?: InputMaybe<NoteVersionAccessEnum>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `editorId` field. */
  editorId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `noteId` field. */
  noteId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `rootId` field. */
  rootId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<NoteVersionStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `NoteVersion` object types. All fields are combined with a logical ‘and.’ */
export type NoteVersionFilter = {
  /** Filter by the object’s `access` field. */
  access?: InputMaybe<NoteVersionAccessEnumFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NoteVersionFilter>>;
  /** Filter by the object’s `branch` field. */
  branch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `editorId` field. */
  editorId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NoteVersionFilter>;
  /** Filter by the object’s `noteId` field. */
  noteId?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NoteVersionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rootId` field. */
  rootId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<NoteVersionStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `NoteVersion` */
export type NoteVersionInput = {
  access?: InputMaybe<NoteVersionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  editorId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  noteId: Scalars['Int'];
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<NoteVersionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `NoteVersion`. Fields that are set will be updated. */
export type NoteVersionPatch = {
  access?: InputMaybe<NoteVersionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  editorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  noteId?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<NoteVersionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

export enum NoteVersionStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** A filter to be used against NoteVersionStateEnum fields. All fields are combined with a logical ‘and.’ */
export type NoteVersionStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<NoteVersionStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<NoteVersionStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<NoteVersionStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<NoteVersionStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<NoteVersionStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<NoteVersionStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<NoteVersionStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<NoteVersionStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<NoteVersionStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<NoteVersionStateEnum>>;
};

/** A connection to a list of `NoteVersion` values. */
export type NoteVersionsConnection = {
  __typename?: 'NoteVersionsConnection';
  /** A list of edges which contains the `NoteVersion` and cursor to aid in pagination. */
  edges: Array<NoteVersionsEdge>;
  /** A list of `NoteVersion` objects. */
  nodes: Array<Maybe<NoteVersion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NoteVersion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `NoteVersion` edge in the connection. */
export type NoteVersionsEdge = {
  __typename?: 'NoteVersionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `NoteVersion` at the end of the edge. */
  node?: Maybe<NoteVersion>;
};

/** Methods to use when ordering `NoteVersion`. */
export enum NoteVersionsOrderBy {
  AccessAsc = 'ACCESS_ASC',
  AccessDesc = 'ACCESS_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  EditorIdAsc = 'EDITOR_ID_ASC',
  EditorIdDesc = 'EDITOR_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NoteIdAsc = 'NOTE_ID_ASC',
  NoteIdDesc = 'NOTE_ID_DESC',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RootIdAsc = 'ROOT_ID_ASC',
  RootIdDesc = 'ROOT_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC'
}

/** A connection to a list of `Note` values. */
export type NotesConnection = {
  __typename?: 'NotesConnection';
  /** A list of edges which contains the `Note` and cursor to aid in pagination. */
  edges: Array<NotesEdge>;
  /** A list of `Note` objects. */
  nodes: Array<Maybe<Note>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Note` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Note` edge in the connection. */
export type NotesEdge = {
  __typename?: 'NotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Note` at the end of the edge. */
  node?: Maybe<Note>;
};

/** Methods to use when ordering `Note`. */
export enum NotesOrderBy {
  AccessAsc = 'ACCESS_ASC',
  AccessDesc = 'ACCESS_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RootIdAsc = 'ROOT_ID_ASC',
  RootIdDesc = 'ROOT_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Organization = Node & {
  __typename?: 'Organization';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `InteractionVersion`. */
  interactionVersionsByOrgId: InteractionVersionsConnection;
  /** Reads and enables pagination through a set of `Interaction`. */
  interactionsByOrgId: InteractionsConnection;
  /** Reads and enables pagination through a set of `Membership`. */
  membershipsByOrgId: MembershipsConnection;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `NoteVersion`. */
  noteVersionsByOrgId: NoteVersionsConnection;
  /** Reads and enables pagination through a set of `Note`. */
  notesByOrgId: NotesConnection;
  /** Reads a single `User` that is related to this `Organization`. */
  owner?: Maybe<User>;
  ownerId: Scalars['Int'];
  /** Reads and enables pagination through a set of `ProgramVersion`. */
  programVersionsByOrgId: ProgramVersionsConnection;
  /** Reads and enables pagination through a set of `Program`. */
  programsByOrgId: ProgramsConnection;
  /** Reads and enables pagination through a set of `Token`. */
  tokensByOrgId: TokensConnection;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  url: Scalars['String'];
};


export type OrganizationInteractionVersionsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionVersionCondition>;
  filter?: InputMaybe<InteractionVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};


export type OrganizationInteractionsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionCondition>;
  filter?: InputMaybe<InteractionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionsOrderBy>>;
};


export type OrganizationMembershipsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipCondition>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};


export type OrganizationNoteVersionsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteVersionCondition>;
  filter?: InputMaybe<NoteVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};


export type OrganizationNotesByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteCondition>;
  filter?: InputMaybe<NoteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};


export type OrganizationProgramVersionsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramVersionCondition>;
  filter?: InputMaybe<ProgramVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};


export type OrganizationProgramsByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramCondition>;
  filter?: InputMaybe<ProgramFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};


export type OrganizationTokensByOrgIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TokenCondition>;
  filter?: InputMaybe<TokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/**
 * A condition to be used against `Organization` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OrganizationCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Organization` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrganizationFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrganizationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrganizationFilter>>;
  /** Filter by the object’s `ownerId` field. */
  ownerId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `Organization` */
export type OrganizationInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  ownerId: Scalars['Int'];
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url: Scalars['String'];
};

/** Represents an update to a `Organization`. Fields that are set will be updated. */
export type OrganizationPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `Organization` values. */
export type OrganizationsConnection = {
  __typename?: 'OrganizationsConnection';
  /** A list of edges which contains the `Organization` and cursor to aid in pagination. */
  edges: Array<OrganizationsEdge>;
  /** A list of `Organization` objects. */
  nodes: Array<Maybe<Organization>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Organization` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Organization` edge in the connection. */
export type OrganizationsEdge = {
  __typename?: 'OrganizationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Organization` at the end of the edge. */
  node?: Maybe<Organization>;
};

/** Methods to use when ordering `Organization`. */
export enum OrganizationsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OwnerIdAsc = 'OWNER_ID_ASC',
  OwnerIdDesc = 'OWNER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type Program = Node & {
  __typename?: 'Program';
  access: ProgramAccessEnum;
  atVersion: Program;
  branch?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `Program`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  /** Reads and enables pagination through a set of `ProgramVersion`. */
  programVersions: ProgramVersionsConnection;
  rootId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: ProgramStateEnum;
  /** Reads and enables pagination through a set of `State`. */
  states: StatesConnection;
  subtype?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Program`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};


export type ProgramAtVersionArgs = {
  input: AtVersionInput;
};


export type ProgramProgramVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramVersionCondition>;
  filter?: InputMaybe<ProgramVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};


export type ProgramStatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StateCondition>;
  filter?: InputMaybe<StateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

export enum ProgramAccessEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Semipublic = 'SEMIPUBLIC',
  Shared = 'SHARED'
}

/** A filter to be used against ProgramAccessEnum fields. All fields are combined with a logical ‘and.’ */
export type ProgramAccessEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ProgramAccessEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ProgramAccessEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ProgramAccessEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ProgramAccessEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ProgramAccessEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ProgramAccessEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ProgramAccessEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ProgramAccessEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ProgramAccessEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ProgramAccessEnum>>;
};

/** A condition to be used against `Program` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProgramCondition = {
  /** Checks for equality with the object’s `access` field. */
  access?: InputMaybe<ProgramAccessEnum>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `rootId` field. */
  rootId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<ProgramStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Program` object types. All fields are combined with a logical ‘and.’ */
export type ProgramFilter = {
  /** Filter by the object’s `access` field. */
  access?: InputMaybe<ProgramAccessEnumFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProgramFilter>>;
  /** Filter by the object’s `branch` field. */
  branch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProgramFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProgramFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `rootId` field. */
  rootId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<ProgramStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Program` */
export type ProgramInput = {
  access?: InputMaybe<ProgramAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ProgramStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `Program`. Fields that are set will be updated. */
export type ProgramPatch = {
  access?: InputMaybe<ProgramAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ProgramStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export enum ProgramStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** A filter to be used against ProgramStateEnum fields. All fields are combined with a logical ‘and.’ */
export type ProgramStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ProgramStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ProgramStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ProgramStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ProgramStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ProgramStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ProgramStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ProgramStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ProgramStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ProgramStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ProgramStateEnum>>;
};

export type ProgramVersion = Node & {
  __typename?: 'ProgramVersion';
  access: ProgramVersionAccessEnum;
  branch?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  editor?: Maybe<User>;
  editorId: Scalars['Int'];
  id: Scalars['Int'];
  isPublic: Scalars['Boolean'];
  meta: Scalars['JSON'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Organization` that is related to this `ProgramVersion`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  payload: Scalars['JSON'];
  /** Reads a single `Program` that is related to this `ProgramVersion`. */
  program?: Maybe<Program>;
  programId: Scalars['Int'];
  rootId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  state: ProgramVersionStateEnum;
  subtype?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['String']>;
};

export enum ProgramVersionAccessEnum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Semipublic = 'SEMIPUBLIC',
  Shared = 'SHARED'
}

/** A filter to be used against ProgramVersionAccessEnum fields. All fields are combined with a logical ‘and.’ */
export type ProgramVersionAccessEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ProgramVersionAccessEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ProgramVersionAccessEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ProgramVersionAccessEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ProgramVersionAccessEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ProgramVersionAccessEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ProgramVersionAccessEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ProgramVersionAccessEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ProgramVersionAccessEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ProgramVersionAccessEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ProgramVersionAccessEnum>>;
};

/**
 * A condition to be used against `ProgramVersion` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProgramVersionCondition = {
  /** Checks for equality with the object’s `access` field. */
  access?: InputMaybe<ProgramVersionAccessEnum>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `editorId` field. */
  editorId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `programId` field. */
  programId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rootId` field. */
  rootId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<ProgramVersionStateEnum>;
  /** Checks for equality with the object’s `subtype` field. */
  subtype?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `ProgramVersion` object types. All fields are combined with a logical ‘and.’ */
export type ProgramVersionFilter = {
  /** Filter by the object’s `access` field. */
  access?: InputMaybe<ProgramVersionAccessEnumFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProgramVersionFilter>>;
  /** Filter by the object’s `branch` field. */
  branch?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `editorId` field. */
  editorId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProgramVersionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProgramVersionFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `programId` field. */
  programId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `rootId` field. */
  rootId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<ProgramVersionStateEnumFilter>;
  /** Filter by the object’s `subtype` field. */
  subtype?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `ProgramVersion` */
export type ProgramVersionInput = {
  access?: InputMaybe<ProgramVersionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  editorId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  programId: Scalars['Int'];
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ProgramVersionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `ProgramVersion`. Fields that are set will be updated. */
export type ProgramVersionPatch = {
  access?: InputMaybe<ProgramVersionAccessEnum>;
  branch?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  editorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  orgId?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  programId?: InputMaybe<Scalars['Int']>;
  rootId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ProgramVersionStateEnum>;
  subtype?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

export enum ProgramVersionStateEnum {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED'
}

/** A filter to be used against ProgramVersionStateEnum fields. All fields are combined with a logical ‘and.’ */
export type ProgramVersionStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ProgramVersionStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ProgramVersionStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ProgramVersionStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ProgramVersionStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ProgramVersionStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ProgramVersionStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ProgramVersionStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ProgramVersionStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ProgramVersionStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ProgramVersionStateEnum>>;
};

/** A connection to a list of `ProgramVersion` values. */
export type ProgramVersionsConnection = {
  __typename?: 'ProgramVersionsConnection';
  /** A list of edges which contains the `ProgramVersion` and cursor to aid in pagination. */
  edges: Array<ProgramVersionsEdge>;
  /** A list of `ProgramVersion` objects. */
  nodes: Array<Maybe<ProgramVersion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProgramVersion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProgramVersion` edge in the connection. */
export type ProgramVersionsEdge = {
  __typename?: 'ProgramVersionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProgramVersion` at the end of the edge. */
  node?: Maybe<ProgramVersion>;
};

/** Methods to use when ordering `ProgramVersion`. */
export enum ProgramVersionsOrderBy {
  AccessAsc = 'ACCESS_ASC',
  AccessDesc = 'ACCESS_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  EditorIdAsc = 'EDITOR_ID_ASC',
  EditorIdDesc = 'EDITOR_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProgramIdAsc = 'PROGRAM_ID_ASC',
  ProgramIdDesc = 'PROGRAM_ID_DESC',
  RootIdAsc = 'ROOT_ID_ASC',
  RootIdDesc = 'ROOT_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC'
}

/** A connection to a list of `Program` values. */
export type ProgramsConnection = {
  __typename?: 'ProgramsConnection';
  /** A list of edges which contains the `Program` and cursor to aid in pagination. */
  edges: Array<ProgramsEdge>;
  /** A list of `Program` objects. */
  nodes: Array<Maybe<Program>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Program` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Program` edge in the connection. */
export type ProgramsEdge = {
  __typename?: 'ProgramsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Program` at the end of the edge. */
  node?: Maybe<Program>;
};

/** Methods to use when ordering `Program`. */
export enum ProgramsOrderBy {
  AccessAsc = 'ACCESS_ASC',
  AccessDesc = 'ACCESS_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RootIdAsc = 'ROOT_ID_ASC',
  RootIdDesc = 'ROOT_ID_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  SubtypeAsc = 'SUBTYPE_ASC',
  SubtypeDesc = 'SUBTYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  alerts?: Maybe<Array<Maybe<Alert>>>;
  canUpdateInteraction?: Maybe<Scalars['Boolean']>;
  canUpdateNote?: Maybe<Scalars['Boolean']>;
  canUpdateProgram?: Maybe<Scalars['Boolean']>;
  currentUser: CurrentUser;
  currentUserId?: Maybe<Scalars['Int']>;
  interaction?: Maybe<Interaction>;
  /** Reads a single `Interaction` using its globally unique `ID`. */
  interactionByNodeId?: Maybe<Interaction>;
  interactionVersion?: Maybe<InteractionVersion>;
  /** Reads a single `InteractionVersion` using its globally unique `ID`. */
  interactionVersionByNodeId?: Maybe<InteractionVersion>;
  /** Reads and enables pagination through a set of `InteractionVersion`. */
  interactionVersions?: Maybe<InteractionVersionsConnection>;
  /** Reads and enables pagination through a set of `Interaction`. */
  interactions?: Maybe<InteractionsConnection>;
  isMember?: Maybe<Scalars['Boolean']>;
  membership?: Maybe<Membership>;
  /** Reads a single `Membership` using its globally unique `ID`. */
  membershipByNodeId?: Maybe<Membership>;
  /** Reads and enables pagination through a set of `Membership`. */
  memberships?: Maybe<MembershipsConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  note?: Maybe<Note>;
  /** Reads a single `Note` using its globally unique `ID`. */
  noteByNodeId?: Maybe<Note>;
  noteVersion?: Maybe<NoteVersion>;
  /** Reads a single `NoteVersion` using its globally unique `ID`. */
  noteVersionByNodeId?: Maybe<NoteVersion>;
  /** Reads and enables pagination through a set of `NoteVersion`. */
  noteVersions?: Maybe<NoteVersionsConnection>;
  /** Reads and enables pagination through a set of `Note`. */
  notes?: Maybe<NotesConnection>;
  organization?: Maybe<Organization>;
  /** Reads a single `Organization` using its globally unique `ID`. */
  organizationByNodeId?: Maybe<Organization>;
  organizationByUrl?: Maybe<Organization>;
  /** Reads and enables pagination through a set of `Organization`. */
  organizations?: Maybe<OrganizationsConnection>;
  program?: Maybe<Program>;
  /** Reads a single `Program` using its globally unique `ID`. */
  programByNodeId?: Maybe<Program>;
  programBySlugAndOrgId?: Maybe<Program>;
  programVersion?: Maybe<ProgramVersion>;
  /** Reads a single `ProgramVersion` using its globally unique `ID`. */
  programVersionByNodeId?: Maybe<ProgramVersion>;
  /** Reads and enables pagination through a set of `ProgramVersion`. */
  programVersions?: Maybe<ProgramVersionsConnection>;
  /** Reads and enables pagination through a set of `Program`. */
  programs?: Maybe<ProgramsConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  state?: Maybe<State>;
  /** Reads a single `State` using its globally unique `ID`. */
  stateByNodeId?: Maybe<State>;
  /** Reads and enables pagination through a set of `State`. */
  states?: Maybe<StatesConnection>;
  token?: Maybe<Token>;
  /** Reads a single `Token` using its globally unique `ID`. */
  tokenByNodeId?: Maybe<Token>;
  /** Reads and enables pagination through a set of `Token`. */
  tokens?: Maybe<TokensConnection>;
  /** Reads and enables pagination through a set of `TypeormMetadatum`. */
  typeormMetadata?: Maybe<TypeormMetadataConnection>;
  user?: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId?: Maybe<User>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
  validateToken?: Maybe<Token>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCanUpdateInteractionArgs = {
  sourceId?: InputMaybe<Scalars['BigInt']>;
  subjectUserId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCanUpdateNoteArgs = {
  sourceNoteId?: InputMaybe<Scalars['BigInt']>;
  subjectUserId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCanUpdateProgramArgs = {
  sourceId?: InputMaybe<Scalars['BigInt']>;
  subjectUserId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionVersionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionVersionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionVersionCondition>;
  filter?: InputMaybe<InteractionVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInteractionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionCondition>;
  filter?: InputMaybe<InteractionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIsMemberArgs = {
  orgId?: InputMaybe<Scalars['BigInt']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipCondition>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoteArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoteByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoteVersionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoteVersionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoteVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteVersionCondition>;
  filter?: InputMaybe<NoteVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNotesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteCondition>;
  filter?: InputMaybe<NoteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationByUrlArgs = {
  url: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCondition>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramBySlugAndOrgIdArgs = {
  orgId: Scalars['Int'];
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramVersionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramVersionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramVersionCondition>;
  filter?: InputMaybe<ProgramVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramCondition>;
  filter?: InputMaybe<ProgramFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryStateArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryStateByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryStatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StateCondition>;
  filter?: InputMaybe<StateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTokenArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTokenByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TokenCondition>;
  filter?: InputMaybe<TokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTypeormMetadataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TypeormMetadatumCondition>;
  filter?: InputMaybe<TypeormMetadatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TypeormMetadataOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryValidateTokenArgs = {
  key: Scalars['String'];
};

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  jwt?: Maybe<Scalars['String']>;
};

export type RegisterUserInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  jwt?: Maybe<Scalars['String']>;
  query?: Maybe<Query>;
  user?: Maybe<User>;
};

export type SetUserInput = {
  id: Scalars['Int'];
  username: Scalars['String'];
};

export type State = Node & {
  __typename?: 'State';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** Reads a single `Interaction` that is related to this `State`. */
  interaction?: Maybe<Interaction>;
  interactionId?: Maybe<Scalars['Int']>;
  meta: Scalars['JSON'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  payload: Scalars['JSON'];
  /** Reads a single `Program` that is related to this `State`. */
  program?: Maybe<Program>;
  programId?: Maybe<Scalars['Int']>;
  state: StateStateEnum;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `State`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/** A condition to be used against `State` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type StateCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `interactionId` field. */
  interactionId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `programId` field. */
  programId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<StateStateEnum>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `State` object types. All fields are combined with a logical ‘and.’ */
export type StateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StateFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `interactionId` field. */
  interactionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StateFilter>>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `programId` field. */
  programId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StateStateEnumFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `State` */
export type StateInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  interactionId?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['JSON']>;
  payload?: InputMaybe<Scalars['JSON']>;
  programId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<StateStateEnum>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `State`. Fields that are set will be updated. */
export type StatePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  interactionId?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['JSON']>;
  payload?: InputMaybe<Scalars['JSON']>;
  programId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<StateStateEnum>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export enum StateStateEnum {
  Completed = 'COMPLETED',
  New = 'NEW',
  Skipped = 'SKIPPED',
  Started = 'STARTED'
}

/** A filter to be used against StateStateEnum fields. All fields are combined with a logical ‘and.’ */
export type StateStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<StateStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<StateStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<StateStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<StateStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<StateStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<StateStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<StateStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<StateStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<StateStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<StateStateEnum>>;
};

/** A connection to a list of `State` values. */
export type StatesConnection = {
  __typename?: 'StatesConnection';
  /** A list of edges which contains the `State` and cursor to aid in pagination. */
  edges: Array<StatesEdge>;
  /** A list of `State` objects. */
  nodes: Array<Maybe<State>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `State` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `State` edge in the connection. */
export type StatesEdge = {
  __typename?: 'StatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `State` at the end of the edge. */
  node?: Maybe<State>;
};

/** Methods to use when ordering `State`. */
export enum StatesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InteractionIdAsc = 'INTERACTION_ID_ASC',
  InteractionIdDesc = 'INTERACTION_ID_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  Natural = 'NATURAL',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProgramIdAsc = 'PROGRAM_ID_ASC',
  ProgramIdDesc = 'PROGRAM_ID_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']>;
};

export type Token = Node & {
  __typename?: 'Token';
  createdAt: Scalars['Datetime'];
  details: Scalars['JSON'];
  /** Reads a single `User` that is related to this `Token`. */
  grantedBy?: Maybe<User>;
  grantedById: Scalars['Int'];
  id: Scalars['Int'];
  key: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Note` that is related to this `Token`. */
  note?: Maybe<Note>;
  noteId?: Maybe<Scalars['Int']>;
  /** Reads a single `Organization` that is related to this `Token`. */
  org?: Maybe<Organization>;
  orgId?: Maybe<Scalars['Int']>;
  state: TokenStateEnum;
  type: TokenTypeEnum;
  updatedAt: Scalars['Datetime'];
};

/** A condition to be used against `Token` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TokenCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `details` field. */
  details?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `grantedById` field. */
  grantedById?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `noteId` field. */
  noteId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `orgId` field. */
  orgId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<TokenStateEnum>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<TokenTypeEnum>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Token` object types. All fields are combined with a logical ‘and.’ */
export type TokenFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TokenFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `details` field. */
  details?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `grantedById` field. */
  grantedById?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `key` field. */
  key?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TokenFilter>;
  /** Filter by the object’s `noteId` field. */
  noteId?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TokenFilter>>;
  /** Filter by the object’s `orgId` field. */
  orgId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<TokenStateEnumFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<TokenTypeEnumFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Token` */
export type TokenInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  details?: InputMaybe<Scalars['JSON']>;
  grantedById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  key: Scalars['UUID'];
  noteId?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<TokenStateEnum>;
  type: TokenTypeEnum;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `Token`. Fields that are set will be updated. */
export type TokenPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  details?: InputMaybe<Scalars['JSON']>;
  grantedById?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  key?: InputMaybe<Scalars['UUID']>;
  noteId?: InputMaybe<Scalars['Int']>;
  orgId?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<TokenStateEnum>;
  type?: InputMaybe<TokenTypeEnum>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export enum TokenStateEnum {
  Accepted = 'ACCEPTED',
  Disabled = 'DISABLED',
  New = 'NEW'
}

/** A filter to be used against TokenStateEnum fields. All fields are combined with a logical ‘and.’ */
export type TokenStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<TokenStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<TokenStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<TokenStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<TokenStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<TokenStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<TokenStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<TokenStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<TokenStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<TokenStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<TokenStateEnum>>;
};

export enum TokenTypeEnum {
  Enrollment = 'ENROLLMENT',
  Membership = 'MEMBERSHIP',
  Note = 'NOTE',
  Organization = 'ORGANIZATION'
}

/** A filter to be used against TokenTypeEnum fields. All fields are combined with a logical ‘and.’ */
export type TokenTypeEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<TokenTypeEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<TokenTypeEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<TokenTypeEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<TokenTypeEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<TokenTypeEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<TokenTypeEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<TokenTypeEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<TokenTypeEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<TokenTypeEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<TokenTypeEnum>>;
};

/** A connection to a list of `Token` values. */
export type TokensConnection = {
  __typename?: 'TokensConnection';
  /** A list of edges which contains the `Token` and cursor to aid in pagination. */
  edges: Array<TokensEdge>;
  /** A list of `Token` objects. */
  nodes: Array<Maybe<Token>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Token` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Token` edge in the connection. */
export type TokensEdge = {
  __typename?: 'TokensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Token` at the end of the edge. */
  node?: Maybe<Token>;
};

/** Methods to use when ordering `Token`. */
export enum TokensOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DetailsAsc = 'DETAILS_ASC',
  DetailsDesc = 'DETAILS_DESC',
  GrantedByIdAsc = 'GRANTED_BY_ID_ASC',
  GrantedByIdDesc = 'GRANTED_BY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  NoteIdAsc = 'NOTE_ID_ASC',
  NoteIdDesc = 'NOTE_ID_DESC',
  OrgIdAsc = 'ORG_ID_ASC',
  OrgIdDesc = 'ORG_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A connection to a list of `TypeormMetadatum` values. */
export type TypeormMetadataConnection = {
  __typename?: 'TypeormMetadataConnection';
  /** A list of edges which contains the `TypeormMetadatum` and cursor to aid in pagination. */
  edges: Array<TypeormMetadataEdge>;
  /** A list of `TypeormMetadatum` objects. */
  nodes: Array<Maybe<TypeormMetadatum>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TypeormMetadatum` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TypeormMetadatum` edge in the connection. */
export type TypeormMetadataEdge = {
  __typename?: 'TypeormMetadataEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TypeormMetadatum` at the end of the edge. */
  node?: Maybe<TypeormMetadatum>;
};

/** Methods to use when ordering `TypeormMetadatum`. */
export enum TypeormMetadataOrderBy {
  DatabaseAsc = 'DATABASE_ASC',
  DatabaseDesc = 'DATABASE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  SchemaAsc = 'SCHEMA_ASC',
  SchemaDesc = 'SCHEMA_DESC',
  TableAsc = 'TABLE_ASC',
  TableDesc = 'TABLE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

export type TypeormMetadatum = {
  __typename?: 'TypeormMetadatum';
  database?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  schema?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `TypeormMetadatum` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TypeormMetadatumCondition = {
  /** Checks for equality with the object’s `database` field. */
  database?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `schema` field. */
  schema?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `table` field. */
  table?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `TypeormMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type TypeormMetadatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TypeormMetadatumFilter>>;
  /** Filter by the object’s `database` field. */
  database?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TypeormMetadatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TypeormMetadatumFilter>>;
  /** Filter by the object’s `schema` field. */
  schema?: InputMaybe<StringFilter>;
  /** Filter by the object’s `table` field. */
  table?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `TypeormMetadatum` */
export type TypeormMetadatumInput = {
  database?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<Scalars['String']>;
  table?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']>>;
};

/** All input for the `updateInteractionByNodeId` mutation. */
export type UpdateInteractionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Interaction` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Interaction` being updated. */
  patch: InteractionPatch;
};

/** All input for the `updateInteraction` mutation. */
export type UpdateInteractionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Interaction` being updated. */
  patch: InteractionPatch;
};

/** The output of our update `Interaction` mutation. */
export type UpdateInteractionPayload = {
  __typename?: 'UpdateInteractionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Interaction` that was updated by this mutation. */
  interaction?: Maybe<Interaction>;
  /** An edge for our `Interaction`. May be used by Relay 1. */
  interactionEdge?: Maybe<InteractionsEdge>;
  /** Reads a single `Organization` that is related to this `Interaction`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Interaction`. */
  user?: Maybe<User>;
};


/** The output of our update `Interaction` mutation. */
export type UpdateInteractionPayloadInteractionEdgeArgs = {
  orderBy?: InputMaybe<Array<InteractionsOrderBy>>;
};

/** All input for the `updateInteractionVersionByNodeId` mutation. */
export type UpdateInteractionVersionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InteractionVersion` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `InteractionVersion` being updated. */
  patch: InteractionVersionPatch;
};

/** All input for the `updateInteractionVersion` mutation. */
export type UpdateInteractionVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `InteractionVersion` being updated. */
  patch: InteractionVersionPatch;
};

/** The output of our update `InteractionVersion` mutation. */
export type UpdateInteractionVersionPayload = {
  __typename?: 'UpdateInteractionVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Interaction` that is related to this `InteractionVersion`. */
  interaction?: Maybe<Interaction>;
  /** The `InteractionVersion` that was updated by this mutation. */
  interactionVersion?: Maybe<InteractionVersion>;
  /** An edge for our `InteractionVersion`. May be used by Relay 1. */
  interactionVersionEdge?: Maybe<InteractionVersionsEdge>;
  /** Reads a single `Organization` that is related to this `InteractionVersion`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `InteractionVersion`. */
  user?: Maybe<User>;
};


/** The output of our update `InteractionVersion` mutation. */
export type UpdateInteractionVersionPayloadInteractionVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};

/** All input for the `updateMembershipByNodeId` mutation. */
export type UpdateMembershipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Membership` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Membership` being updated. */
  patch: MembershipPatch;
};

/** All input for the `updateMembership` mutation. */
export type UpdateMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Membership` being updated. */
  patch: MembershipPatch;
};

/** The output of our update `Membership` mutation. */
export type UpdateMembershipPayload = {
  __typename?: 'UpdateMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Membership` that was updated by this mutation. */
  membership?: Maybe<Membership>;
  /** An edge for our `Membership`. May be used by Relay 1. */
  membershipEdge?: Maybe<MembershipsEdge>;
  /** Reads a single `Organization` that is related to this `Membership`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
};


/** The output of our update `Membership` mutation. */
export type UpdateMembershipPayloadMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};

/** All input for the `updateNoteByNodeId` mutation. */
export type UpdateNoteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Note` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Note` being updated. */
  patch: NotePatch;
};

/** All input for the `updateNote` mutation. */
export type UpdateNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Note` being updated. */
  patch: NotePatch;
};

/** The output of our update `Note` mutation. */
export type UpdateNotePayload = {
  __typename?: 'UpdateNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Note` that was updated by this mutation. */
  note?: Maybe<Note>;
  /** An edge for our `Note`. May be used by Relay 1. */
  noteEdge?: Maybe<NotesEdge>;
  /** Reads a single `Organization` that is related to this `Note`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Note`. */
  user?: Maybe<User>;
};


/** The output of our update `Note` mutation. */
export type UpdateNotePayloadNoteEdgeArgs = {
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};

/** All input for the `updateNoteVersionByNodeId` mutation. */
export type UpdateNoteVersionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NoteVersion` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `NoteVersion` being updated. */
  patch: NoteVersionPatch;
};

/** All input for the `updateNoteVersion` mutation. */
export type UpdateNoteVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `NoteVersion` being updated. */
  patch: NoteVersionPatch;
};

/** The output of our update `NoteVersion` mutation. */
export type UpdateNoteVersionPayload = {
  __typename?: 'UpdateNoteVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Note` that is related to this `NoteVersion`. */
  note?: Maybe<Note>;
  /** The `NoteVersion` that was updated by this mutation. */
  noteVersion?: Maybe<NoteVersion>;
  /** An edge for our `NoteVersion`. May be used by Relay 1. */
  noteVersionEdge?: Maybe<NoteVersionsEdge>;
  /** Reads a single `Organization` that is related to this `NoteVersion`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `NoteVersion`. */
  user?: Maybe<User>;
};


/** The output of our update `NoteVersion` mutation. */
export type UpdateNoteVersionPayloadNoteVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};

/** All input for the `updateOrganizationByNodeId` mutation. */
export type UpdateOrganizationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Organization` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
};

/** All input for the `updateOrganizationByUrl` mutation. */
export type UpdateOrganizationByUrlInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
  url: Scalars['String'];
};

/** All input for the `updateOrganization` mutation. */
export type UpdateOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
};

/** The output of our update `Organization` mutation. */
export type UpdateOrganizationPayload = {
  __typename?: 'UpdateOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Organization` that was updated by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Reads a single `User` that is related to this `Organization`. */
  owner?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Organization` mutation. */
export type UpdateOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the `updateProgramByNodeId` mutation. */
export type UpdateProgramByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Program` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Program` being updated. */
  patch: ProgramPatch;
};

/** All input for the `updateProgramBySlugAndOrgId` mutation. */
export type UpdateProgramBySlugAndOrgIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  orgId: Scalars['Int'];
  /** An object where the defined keys will be set on the `Program` being updated. */
  patch: ProgramPatch;
  slug: Scalars['String'];
};

/** All input for the `updateProgram` mutation. */
export type UpdateProgramInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Program` being updated. */
  patch: ProgramPatch;
};

/** The output of our update `Program` mutation. */
export type UpdateProgramPayload = {
  __typename?: 'UpdateProgramPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Organization` that is related to this `Program`. */
  org?: Maybe<Organization>;
  /** The `Program` that was updated by this mutation. */
  program?: Maybe<Program>;
  /** An edge for our `Program`. May be used by Relay 1. */
  programEdge?: Maybe<ProgramsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Program`. */
  user?: Maybe<User>;
};


/** The output of our update `Program` mutation. */
export type UpdateProgramPayloadProgramEdgeArgs = {
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};

/** All input for the `updateProgramVersionByNodeId` mutation. */
export type UpdateProgramVersionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProgramVersion` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProgramVersion` being updated. */
  patch: ProgramVersionPatch;
};

/** All input for the `updateProgramVersion` mutation. */
export type UpdateProgramVersionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ProgramVersion` being updated. */
  patch: ProgramVersionPatch;
};

/** The output of our update `ProgramVersion` mutation. */
export type UpdateProgramVersionPayload = {
  __typename?: 'UpdateProgramVersionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  editor?: Maybe<User>;
  /** Reads a single `Organization` that is related to this `ProgramVersion`. */
  org?: Maybe<Organization>;
  /** Reads a single `Program` that is related to this `ProgramVersion`. */
  program?: Maybe<Program>;
  /** The `ProgramVersion` that was updated by this mutation. */
  programVersion?: Maybe<ProgramVersion>;
  /** An edge for our `ProgramVersion`. May be used by Relay 1. */
  programVersionEdge?: Maybe<ProgramVersionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProgramVersion`. */
  user?: Maybe<User>;
};


/** The output of our update `ProgramVersion` mutation. */
export type UpdateProgramVersionPayloadProgramVersionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};

/** All input for the `updateStateByNodeId` mutation. */
export type UpdateStateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `State` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `State` being updated. */
  patch: StatePatch;
};

/** All input for the `updateState` mutation. */
export type UpdateStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `State` being updated. */
  patch: StatePatch;
};

/** The output of our update `State` mutation. */
export type UpdateStatePayload = {
  __typename?: 'UpdateStatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Interaction` that is related to this `State`. */
  interaction?: Maybe<Interaction>;
  /** Reads a single `Program` that is related to this `State`. */
  program?: Maybe<Program>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `State` that was updated by this mutation. */
  state?: Maybe<State>;
  /** An edge for our `State`. May be used by Relay 1. */
  stateEdge?: Maybe<StatesEdge>;
  /** Reads a single `User` that is related to this `State`. */
  user?: Maybe<User>;
};


/** The output of our update `State` mutation. */
export type UpdateStatePayloadStateEdgeArgs = {
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** All input for the `updateTokenByNodeId` mutation. */
export type UpdateTokenByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Token` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Token` being updated. */
  patch: TokenPatch;
};

/** All input for the `updateToken` mutation. */
export type UpdateTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Token` being updated. */
  patch: TokenPatch;
};

/** The output of our update `Token` mutation. */
export type UpdateTokenPayload = {
  __typename?: 'UpdateTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Token`. */
  grantedBy?: Maybe<User>;
  /** Reads a single `Note` that is related to this `Token`. */
  note?: Maybe<Note>;
  /** Reads a single `Organization` that is related to this `Token`. */
  org?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Token` that was updated by this mutation. */
  token?: Maybe<Token>;
  /** An edge for our `Token`. May be used by Relay 1. */
  tokenEdge?: Maybe<TokensEdge>;
};


/** The output of our update `Token` mutation. */
export type UpdateTokenPayloadTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** All input for the `updateUserByNodeId` mutation. */
export type UpdateUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type User = Node & {
  __typename?: 'User';
  createdAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `InteractionVersion`. */
  editedInteractionVersions: InteractionVersionsConnection;
  /** Reads and enables pagination through a set of `NoteVersion`. */
  editedNoteVersions: NoteVersionsConnection;
  /** Reads and enables pagination through a set of `ProgramVersion`. */
  editedProgramVersions: ProgramVersionsConnection;
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `InteractionVersion`. */
  interactionVersions: InteractionVersionsConnection;
  /** Reads and enables pagination through a set of `Interaction`. */
  interactions: InteractionsConnection;
  /** Reads and enables pagination through a set of `Membership`. */
  memberships: MembershipsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `NoteVersion`. */
  noteVersions: NoteVersionsConnection;
  /** Reads and enables pagination through a set of `Note`. */
  notes: NotesConnection;
  /** Reads and enables pagination through a set of `Organization`. */
  organizationsByOwnerId: OrganizationsConnection;
  password: Scalars['String'];
  /** Reads and enables pagination through a set of `ProgramVersion`. */
  programVersions: ProgramVersionsConnection;
  /** Reads and enables pagination through a set of `Program`. */
  programs: ProgramsConnection;
  /** Reads and enables pagination through a set of `State`. */
  states: StatesConnection;
  /** Reads and enables pagination through a set of `Token`. */
  tokensByGrantedById: TokensConnection;
  updatedAt: Scalars['Datetime'];
  username: Scalars['String'];
};


export type UserEditedInteractionVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionVersionCondition>;
  filter?: InputMaybe<InteractionVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};


export type UserEditedNoteVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteVersionCondition>;
  filter?: InputMaybe<NoteVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};


export type UserEditedProgramVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramVersionCondition>;
  filter?: InputMaybe<ProgramVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};


export type UserInteractionVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionVersionCondition>;
  filter?: InputMaybe<InteractionVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionVersionsOrderBy>>;
};


export type UserInteractionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InteractionCondition>;
  filter?: InputMaybe<InteractionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InteractionsOrderBy>>;
};


export type UserMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipCondition>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};


export type UserNoteVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteVersionCondition>;
  filter?: InputMaybe<NoteVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoteVersionsOrderBy>>;
};


export type UserNotesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoteCondition>;
  filter?: InputMaybe<NoteFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotesOrderBy>>;
};


export type UserOrganizationsByOwnerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCondition>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};


export type UserProgramVersionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramVersionCondition>;
  filter?: InputMaybe<ProgramVersionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramVersionsOrderBy>>;
};


export type UserProgramsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramCondition>;
  filter?: InputMaybe<ProgramFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};


export type UserStatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StateCondition>;
  filter?: InputMaybe<StateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};


export type UserTokensByGrantedByIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TokenCondition>;
  filter?: InputMaybe<TokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TokensOrderBy>>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `password` field. */
  password?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `password` field. */
  password?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  password: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  username: Scalars['String'];
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  username?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PasswordAsc = 'PASSWORD_ASC',
  PasswordDesc = 'PASSWORD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

export type OrganizationProgramsQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type OrganizationProgramsQuery = { __typename?: 'Query', organizationByUrl?: { __typename?: 'Organization', id: number, name: string, url: string, ownerId: number, programsByOrgId: { __typename?: 'ProgramsConnection', nodes: Array<{ __typename: 'Program', id: number, userId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null | undefined> } } | null | undefined };

export type CreateProgramMutationVariables = Exact<{
  orgId?: InputMaybe<Scalars['Int']>;
  access?: InputMaybe<ProgramAccessEnum>;
  name: Scalars['String'];
  type: Scalars['String'];
  payload: Scalars['JSON'];
  meta: Scalars['JSON'];
  isPublic: Scalars['Boolean'];
  userId: Scalars['Int'];
  slug: Scalars['String'];
}>;


export type CreateProgramMutation = { __typename?: 'Mutation', createProgram?: { __typename?: 'CreateProgramPayload', program?: { __typename: 'Program', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null | undefined } | null | undefined };

export type UpdateProgramMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['JSON']>;
  meta?: InputMaybe<Scalars['JSON']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
}>;


export type UpdateProgramMutation = { __typename?: 'Mutation', updateProgram?: { __typename?: 'UpdateProgramPayload', program?: { __typename: 'Program', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null | undefined } | null | undefined };

export type CreateInteractionMutationVariables = Exact<{
  orgId?: InputMaybe<Scalars['Int']>;
  access?: InputMaybe<InteractionAccessEnum>;
  name: Scalars['String'];
  type: Scalars['String'];
  payload: Scalars['JSON'];
  meta: Scalars['JSON'];
  isPublic: Scalars['Boolean'];
  userId: Scalars['Int'];
  slug: Scalars['String'];
  version: Scalars['String'];
}>;


export type CreateInteractionMutation = { __typename?: 'Mutation', createInteraction?: { __typename?: 'CreateInteractionPayload', interaction?: { __typename: 'Interaction', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, version: string, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null | undefined } | null | undefined };

export type UpdateInteractionMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['JSON']>;
  meta?: InputMaybe<Scalars['JSON']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
}>;


export type UpdateInteractionMutation = { __typename?: 'Mutation', updateInteraction?: { __typename?: 'UpdateInteractionPayload', interaction?: { __typename: 'Interaction', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, access: InteractionAccessEnum, isPublic: boolean, createdAt: any, updatedAt: any } | null | undefined } | null | undefined };

export type QueryInteractionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type QueryInteractionQuery = { __typename?: 'Query', interaction?: { __typename: 'Interaction', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, access: InteractionAccessEnum, isPublic: boolean, createdAt: any, updatedAt: any } | null | undefined };

export type InteractionArrowsQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Int']>;
}>;


export type InteractionArrowsQuery = { __typename?: 'Query', interactions?: { __typename?: 'InteractionsConnection', nodes: Array<{ __typename: 'Interaction', id: number, userId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, access: InteractionAccessEnum, isPublic: boolean, meta: any, createdAt: any, updatedAt: any } | null | undefined> } | null | undefined };

export type ProgramVersionsQueryVariables = Exact<{
  programId: Scalars['Int'];
}>;


export type ProgramVersionsQuery = { __typename?: 'Query', programVersions?: { __typename?: 'ProgramVersionsConnection', nodes: Array<{ __typename: 'ProgramVersion', id: number, userId?: number | null | undefined, editorId: number, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, access: ProgramVersionAccessEnum, state: ProgramVersionStateEnum, programId: number, branch?: string | null | undefined, rootId?: number | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined } | null | undefined> } | null | undefined };

export type InteractionVersionsQueryVariables = Exact<{
  interactionId: Scalars['Int'];
}>;


export type InteractionVersionsQuery = { __typename?: 'Query', interactionVersions?: { __typename?: 'InteractionVersionsConnection', nodes: Array<{ __typename: 'InteractionVersion', id: number, userId?: number | null | undefined, editorId: number, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, access: InteractionVersionAccessEnum, state: InteractionVersionStateEnum, interactionId: number, branch?: string | null | undefined, rootId?: number | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined } | null | undefined> } | null | undefined };

export type AuthenticateUserMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type AuthenticateUserMutation = { __typename?: 'Mutation', authenticateUser?: { __typename?: 'AuthenticateUserPayload', jwt?: string | null | undefined, user?: { __typename?: 'User', id: number, username: string } | null | undefined } | null | undefined };

export type RegisterUserMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', registerUser?: { __typename?: 'RegisterUserPayload', jwt?: string | null | undefined, user?: { __typename?: 'User', id: number, username: string } | null | undefined } | null | undefined };

export type CreateNoteMutationVariables = Exact<{
  orgId?: InputMaybe<Scalars['Int']>;
  access?: InputMaybe<NoteAccessEnum>;
  name: Scalars['String'];
  type: Scalars['String'];
  payload: Scalars['JSON'];
  meta: Scalars['JSON'];
  isPublic: Scalars['Boolean'];
  userId: Scalars['Int'];
  slug: Scalars['String'];
}>;


export type CreateNoteMutation = { __typename?: 'Mutation', payload?: { __typename?: 'CreateNotePayload', note?: { __typename: 'Note', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null | undefined } | null | undefined };

export type UpdateNoteMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['JSON']>;
  meta?: InputMaybe<Scalars['JSON']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
}>;


export type UpdateNoteMutation = { __typename?: 'Mutation', payload?: { __typename?: 'UpdateNotePayload', note?: { __typename: 'Note', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined } | null | undefined } | null | undefined };

export type AllNotesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllNotesQuery = { __typename?: 'Query', notes?: { __typename?: 'NotesConnection', nodes: Array<{ __typename: 'Note', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null | undefined> } | null | undefined };

export type PersonalNotesQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['Int']>;
}>;


export type PersonalNotesQuery = { __typename?: 'Query', notes?: { __typename?: 'NotesConnection', nodes: Array<{ __typename: 'Note', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null | undefined> } | null | undefined };

export type QueryNoteQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type QueryNoteQuery = { __typename?: 'Query', note?: { __typename: 'Note', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null | undefined };

export type NoteVersionsQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  noteId?: InputMaybe<Scalars['Int']>;
}>;


export type NoteVersionsQuery = { __typename?: 'Query', noteVersions?: { __typename?: 'NoteVersionsConnection', nodes: Array<{ __typename: 'NoteVersion', id: number, noteId: number, editorId: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null | undefined> } | null | undefined };

export type NoteAtVersionQueryVariables = Exact<{
  id: Scalars['Int'];
  versionId: Scalars['Int'];
}>;


export type NoteAtVersionQuery = { __typename?: 'Query', note?: { __typename: 'Note', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any, atVersion: { __typename?: 'Note', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } } | null | undefined };

export type GenerateTokenMutationVariables = Exact<{
  type: Scalars['String'];
  details: Scalars['JSON'];
  orgId?: InputMaybe<Scalars['Int']>;
}>;


export type GenerateTokenMutation = { __typename?: 'Mutation', generateToken?: { __typename?: 'GenerateTokenPayload', token?: { __typename?: 'Token', nodeId: string, id: number, type: TokenTypeEnum, key: any, state: TokenStateEnum, details: any, createdAt: any, updatedAt: any, org?: { __typename?: 'Organization', nodeId: string, name: string, url: string } | null | undefined } | null | undefined } | null | undefined };

export type ValidateTokenQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type ValidateTokenQuery = { __typename?: 'Query', validateToken?: { __typename?: 'Token', nodeId: string, id: number, type: TokenTypeEnum, key: any, state: TokenStateEnum, details: any, createdAt: any, updatedAt: any, org?: { __typename?: 'Organization', nodeId: string, name: string, url: string } | null | undefined } | null | undefined };

export type DisableTokenMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type DisableTokenMutation = { __typename?: 'Mutation', disableToken?: { __typename?: 'DisableTokenPayload', token?: { __typename?: 'Token', nodeId: string, id: number, type: TokenTypeEnum, key: any, state: TokenStateEnum, details: any, createdAt: any, updatedAt: any, org?: { __typename?: 'Organization', nodeId: string, name: string, url: string } | null | undefined } | null | undefined } | null | undefined };

export type ApplyTokenMutationVariables = Exact<{
  key: Scalars['String'];
  details: Scalars['JSON'];
}>;


export type ApplyTokenMutation = { __typename?: 'Mutation', applyToken?: { __typename?: 'ApplyTokenPayload', organization?: { __typename?: 'Organization', nodeId: string, id: number, type: string, name: string, url: string, createdAt: any, updatedAt: any } | null | undefined } | null | undefined };

export type OrganizationsNotesQueryVariables = Exact<{
  url?: InputMaybe<Scalars['String']>;
}>;


export type OrganizationsNotesQuery = { __typename?: 'Query', organizations?: { __typename?: 'OrganizationsConnection', nodes: Array<{ __typename?: 'Organization', id: number, name: string, url: string, notes: { __typename?: 'NotesConnection', nodes: Array<{ __typename: 'Note', id: number, userId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } | null | undefined> } } | null | undefined> } | null | undefined };

export type OrgSettingsQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type OrgSettingsQuery = { __typename?: 'Query', organizationByUrl?: { __typename?: 'Organization', id: number, name: string, url: string, ownerId: number, tokensByOrgId: { __typename?: 'TokensConnection', nodes: Array<{ __typename?: 'Token', id: number, key: any, type: TokenTypeEnum, state: TokenStateEnum, details: any, createdAt: any, updatedAt: any, grantedById: number } | null | undefined> }, membershipsByOrgId: { __typename?: 'MembershipsConnection', nodes: Array<{ __typename: 'Membership', id: number, userId: number, type: string, state: string, payload: any, createdAt: any, updatedAt: any } | null | undefined> } } | null | undefined };

export type UpdateMembershipMutationVariables = Exact<{
  id: Scalars['Int'];
  type?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['JSON']>;
}>;


export type UpdateMembershipMutation = { __typename?: 'Mutation', updateMembership?: { __typename?: 'UpdateMembershipPayload', membership?: { __typename: 'Membership', id: number, type: string, state: string, payload: any, createdAt: any, updatedAt: any } | null | undefined } | null | undefined };

export type ProgramAtVersionQueryVariables = Exact<{
  id: Scalars['Int'];
  versionId: Scalars['Int'];
}>;


export type ProgramAtVersionQuery = { __typename?: 'Query', program?: { __typename: 'Program', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any, atVersion: { __typename?: 'Program', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } } | null | undefined };

export type InteractionAtVersionQueryVariables = Exact<{
  id: Scalars['Int'];
  versionId: Scalars['Int'];
}>;


export type InteractionAtVersionQuery = { __typename?: 'Query', interaction?: { __typename: 'Interaction', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any, atVersion: { __typename?: 'Interaction', id: number, userId?: number | null | undefined, orgId?: number | null | undefined, type: string, name: string, slug?: string | null | undefined, payload: any, meta: any, isPublic: boolean, createdAt: any, updatedAt: any } } | null | undefined };

export type UserRegistrationMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type UserRegistrationMutation = { __typename?: 'Mutation', registerUser?: { __typename?: 'RegisterUserPayload', user?: { __typename?: 'User', id: number, username: string } | null | undefined } | null | undefined };

export type CreateStateMutationVariables = Exact<{
  userId: Scalars['Int'];
  programId: Scalars['Int'];
  interactionId: Scalars['Int'];
  state?: InputMaybe<StateStateEnum>;
  payload?: InputMaybe<Scalars['JSON']>;
  meta?: InputMaybe<Scalars['JSON']>;
}>;


export type CreateStateMutation = { __typename?: 'Mutation', createState?: { __typename?: 'CreateStatePayload', state?: { __typename: 'State', id: number, userId: number, programId?: number | null | undefined, interactionId?: number | null | undefined, state: StateStateEnum, payload: any, meta: any, createdAt: any, updatedAt: any } | null | undefined } | null | undefined };

export type StatesQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['Int']>;
  programId?: InputMaybe<Scalars['Int']>;
  interactionId?: InputMaybe<Scalars['Int']>;
}>;


export type StatesQuery = { __typename?: 'Query', states?: { __typename?: 'StatesConnection', nodes: Array<{ __typename: 'State', id: number, userId: number, programId?: number | null | undefined, interactionId?: number | null | undefined, state: StateStateEnum, payload: any, meta: any, createdAt: any, updatedAt: any } | null | undefined> } | null | undefined };

export type UpdateStateMutationVariables = Exact<{
  id: Scalars['Int'];
  state?: InputMaybe<StateStateEnum>;
  payload?: InputMaybe<Scalars['JSON']>;
  meta?: InputMaybe<Scalars['JSON']>;
}>;


export type UpdateStateMutation = { __typename?: 'Mutation', updateState?: { __typename?: 'UpdateStatePayload', state?: { __typename: 'State', id: number, userId: number, programId?: number | null | undefined, interactionId?: number | null | undefined, state: StateStateEnum, payload: any, meta: any, createdAt: any, updatedAt: any } | null | undefined } | null | undefined };


export const OrganizationProgramsDocument = gql`
    query OrganizationPrograms($url: String!) {
  organizationByUrl(url: $url) {
    id
    name
    url
    ownerId
    programsByOrgId(orderBy: UPDATED_AT_DESC) {
      nodes {
        __typename
        id
        userId
        type
        name
        slug
        payload
        meta
        isPublic
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useOrganizationProgramsQuery__
 *
 * To run a query within a React component, call `useOrganizationProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationProgramsQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useOrganizationProgramsQuery(baseOptions: Apollo.QueryHookOptions<OrganizationProgramsQuery, OrganizationProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationProgramsQuery, OrganizationProgramsQueryVariables>(OrganizationProgramsDocument, options);
      }
export function useOrganizationProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationProgramsQuery, OrganizationProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationProgramsQuery, OrganizationProgramsQueryVariables>(OrganizationProgramsDocument, options);
        }
export type OrganizationProgramsQueryHookResult = ReturnType<typeof useOrganizationProgramsQuery>;
export type OrganizationProgramsLazyQueryHookResult = ReturnType<typeof useOrganizationProgramsLazyQuery>;
export type OrganizationProgramsQueryResult = Apollo.QueryResult<OrganizationProgramsQuery, OrganizationProgramsQueryVariables>;
export function refetchOrganizationProgramsQuery(variables: OrganizationProgramsQueryVariables) {
      return { query: OrganizationProgramsDocument, variables: variables }
    }
export const CreateProgramDocument = gql`
    mutation CreateProgram($orgId: Int, $access: ProgramAccessEnum, $name: String!, $type: String!, $payload: JSON!, $meta: JSON!, $isPublic: Boolean!, $userId: Int!, $slug: String!) {
  createProgram(
    input: {program: {orgId: $orgId, access: $access, name: $name, payload: $payload, meta: $meta, isPublic: $isPublic, userId: $userId, type: $type, slug: $slug}}
  ) {
    program {
      __typename
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateProgramMutationFn = Apollo.MutationFunction<CreateProgramMutation, CreateProgramMutationVariables>;

/**
 * __useCreateProgramMutation__
 *
 * To run a mutation, you first call `useCreateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramMutation, { data, loading, error }] = useCreateProgramMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      access: // value for 'access'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      payload: // value for 'payload'
 *      meta: // value for 'meta'
 *      isPublic: // value for 'isPublic'
 *      userId: // value for 'userId'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCreateProgramMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgramMutation, CreateProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgramMutation, CreateProgramMutationVariables>(CreateProgramDocument, options);
      }
export type CreateProgramMutationHookResult = ReturnType<typeof useCreateProgramMutation>;
export type CreateProgramMutationResult = Apollo.MutationResult<CreateProgramMutation>;
export type CreateProgramMutationOptions = Apollo.BaseMutationOptions<CreateProgramMutation, CreateProgramMutationVariables>;
export const UpdateProgramDocument = gql`
    mutation UpdateProgram($name: String, $type: String, $slug: String, $payload: JSON, $meta: JSON, $isPublic: Boolean, $userId: Int, $id: Int!) {
  updateProgram(
    input: {id: $id, patch: {name: $name, type: $type, slug: $slug, payload: $payload, meta: $meta, isPublic: $isPublic, userId: $userId}}
  ) {
    program {
      __typename
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
}
    `;
export type UpdateProgramMutationFn = Apollo.MutationFunction<UpdateProgramMutation, UpdateProgramMutationVariables>;

/**
 * __useUpdateProgramMutation__
 *
 * To run a mutation, you first call `useUpdateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramMutation, { data, loading, error }] = useUpdateProgramMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      slug: // value for 'slug'
 *      payload: // value for 'payload'
 *      meta: // value for 'meta'
 *      isPublic: // value for 'isPublic'
 *      userId: // value for 'userId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateProgramMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProgramMutation, UpdateProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProgramMutation, UpdateProgramMutationVariables>(UpdateProgramDocument, options);
      }
export type UpdateProgramMutationHookResult = ReturnType<typeof useUpdateProgramMutation>;
export type UpdateProgramMutationResult = Apollo.MutationResult<UpdateProgramMutation>;
export type UpdateProgramMutationOptions = Apollo.BaseMutationOptions<UpdateProgramMutation, UpdateProgramMutationVariables>;
export const CreateInteractionDocument = gql`
    mutation CreateInteraction($orgId: Int, $access: InteractionAccessEnum, $name: String!, $type: String!, $payload: JSON!, $meta: JSON!, $isPublic: Boolean!, $userId: Int!, $slug: String!, $version: String!) {
  createInteraction(
    input: {interaction: {orgId: $orgId, access: $access, name: $name, payload: $payload, meta: $meta, isPublic: $isPublic, userId: $userId, type: $type, slug: $slug, version: $version}}
  ) {
    interaction {
      __typename
      id
      userId
      orgId
      type
      name
      slug
      version
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateInteractionMutationFn = Apollo.MutationFunction<CreateInteractionMutation, CreateInteractionMutationVariables>;

/**
 * __useCreateInteractionMutation__
 *
 * To run a mutation, you first call `useCreateInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInteractionMutation, { data, loading, error }] = useCreateInteractionMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      access: // value for 'access'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      payload: // value for 'payload'
 *      meta: // value for 'meta'
 *      isPublic: // value for 'isPublic'
 *      userId: // value for 'userId'
 *      slug: // value for 'slug'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useCreateInteractionMutation(baseOptions?: Apollo.MutationHookOptions<CreateInteractionMutation, CreateInteractionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInteractionMutation, CreateInteractionMutationVariables>(CreateInteractionDocument, options);
      }
export type CreateInteractionMutationHookResult = ReturnType<typeof useCreateInteractionMutation>;
export type CreateInteractionMutationResult = Apollo.MutationResult<CreateInteractionMutation>;
export type CreateInteractionMutationOptions = Apollo.BaseMutationOptions<CreateInteractionMutation, CreateInteractionMutationVariables>;
export const UpdateInteractionDocument = gql`
    mutation UpdateInteraction($name: String, $type: String, $slug: String, $payload: JSON, $meta: JSON, $isPublic: Boolean, $userId: Int, $id: Int!) {
  updateInteraction(
    input: {id: $id, patch: {name: $name, type: $type, slug: $slug, payload: $payload, meta: $meta, isPublic: $isPublic, userId: $userId}}
  ) {
    interaction {
      __typename
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      access
      isPublic
      createdAt
      updatedAt
    }
  }
}
    `;
export type UpdateInteractionMutationFn = Apollo.MutationFunction<UpdateInteractionMutation, UpdateInteractionMutationVariables>;

/**
 * __useUpdateInteractionMutation__
 *
 * To run a mutation, you first call `useUpdateInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInteractionMutation, { data, loading, error }] = useUpdateInteractionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      slug: // value for 'slug'
 *      payload: // value for 'payload'
 *      meta: // value for 'meta'
 *      isPublic: // value for 'isPublic'
 *      userId: // value for 'userId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateInteractionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInteractionMutation, UpdateInteractionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInteractionMutation, UpdateInteractionMutationVariables>(UpdateInteractionDocument, options);
      }
export type UpdateInteractionMutationHookResult = ReturnType<typeof useUpdateInteractionMutation>;
export type UpdateInteractionMutationResult = Apollo.MutationResult<UpdateInteractionMutation>;
export type UpdateInteractionMutationOptions = Apollo.BaseMutationOptions<UpdateInteractionMutation, UpdateInteractionMutationVariables>;
export const QueryInteractionDocument = gql`
    query QueryInteraction($id: Int!) {
  interaction(id: $id) {
    __typename
    id
    userId
    orgId
    type
    name
    slug
    payload
    meta
    access
    isPublic
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useQueryInteractionQuery__
 *
 * To run a query within a React component, call `useQueryInteractionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryInteractionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryInteractionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryInteractionQuery(baseOptions: Apollo.QueryHookOptions<QueryInteractionQuery, QueryInteractionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryInteractionQuery, QueryInteractionQueryVariables>(QueryInteractionDocument, options);
      }
export function useQueryInteractionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryInteractionQuery, QueryInteractionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryInteractionQuery, QueryInteractionQueryVariables>(QueryInteractionDocument, options);
        }
export type QueryInteractionQueryHookResult = ReturnType<typeof useQueryInteractionQuery>;
export type QueryInteractionLazyQueryHookResult = ReturnType<typeof useQueryInteractionLazyQuery>;
export type QueryInteractionQueryResult = Apollo.QueryResult<QueryInteractionQuery, QueryInteractionQueryVariables>;
export function refetchQueryInteractionQuery(variables: QueryInteractionQueryVariables) {
      return { query: QueryInteractionDocument, variables: variables }
    }
export const InteractionArrowsDocument = gql`
    query InteractionArrows($orgId: Int, $type: String, $slug: String, $isPublic: Boolean, $userId: Int) {
  interactions(
    orderBy: UPDATED_AT_DESC
    condition: {orgId: $orgId, type: $type, slug: $slug, isPublic: $isPublic, userId: $userId}
  ) {
    nodes {
      __typename
      id
      userId
      type
      name
      slug
      access
      isPublic
      meta
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useInteractionArrowsQuery__
 *
 * To run a query within a React component, call `useInteractionArrowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionArrowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionArrowsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      type: // value for 'type'
 *      slug: // value for 'slug'
 *      isPublic: // value for 'isPublic'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInteractionArrowsQuery(baseOptions?: Apollo.QueryHookOptions<InteractionArrowsQuery, InteractionArrowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractionArrowsQuery, InteractionArrowsQueryVariables>(InteractionArrowsDocument, options);
      }
export function useInteractionArrowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractionArrowsQuery, InteractionArrowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractionArrowsQuery, InteractionArrowsQueryVariables>(InteractionArrowsDocument, options);
        }
export type InteractionArrowsQueryHookResult = ReturnType<typeof useInteractionArrowsQuery>;
export type InteractionArrowsLazyQueryHookResult = ReturnType<typeof useInteractionArrowsLazyQuery>;
export type InteractionArrowsQueryResult = Apollo.QueryResult<InteractionArrowsQuery, InteractionArrowsQueryVariables>;
export function refetchInteractionArrowsQuery(variables?: InteractionArrowsQueryVariables) {
      return { query: InteractionArrowsDocument, variables: variables }
    }
export const ProgramVersionsDocument = gql`
    query ProgramVersions($programId: Int!) {
  programVersions(condition: {programId: $programId}, orderBy: [ID_DESC]) {
    nodes {
      __typename
      id
      userId
      editorId
      type
      name
      slug
      payload
      meta
      isPublic
      access
      state
      programId
      branch
      rootId
      createdAt
      updatedAt
      deletedAt
    }
  }
}
    `;

/**
 * __useProgramVersionsQuery__
 *
 * To run a query within a React component, call `useProgramVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramVersionsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useProgramVersionsQuery(baseOptions: Apollo.QueryHookOptions<ProgramVersionsQuery, ProgramVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProgramVersionsQuery, ProgramVersionsQueryVariables>(ProgramVersionsDocument, options);
      }
export function useProgramVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramVersionsQuery, ProgramVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProgramVersionsQuery, ProgramVersionsQueryVariables>(ProgramVersionsDocument, options);
        }
export type ProgramVersionsQueryHookResult = ReturnType<typeof useProgramVersionsQuery>;
export type ProgramVersionsLazyQueryHookResult = ReturnType<typeof useProgramVersionsLazyQuery>;
export type ProgramVersionsQueryResult = Apollo.QueryResult<ProgramVersionsQuery, ProgramVersionsQueryVariables>;
export function refetchProgramVersionsQuery(variables: ProgramVersionsQueryVariables) {
      return { query: ProgramVersionsDocument, variables: variables }
    }
export const InteractionVersionsDocument = gql`
    query InteractionVersions($interactionId: Int!) {
  interactionVersions(
    condition: {interactionId: $interactionId}
    orderBy: [ID_DESC]
  ) {
    nodes {
      __typename
      id
      userId
      editorId
      type
      name
      slug
      payload
      meta
      isPublic
      access
      state
      interactionId
      branch
      rootId
      createdAt
      updatedAt
      deletedAt
    }
  }
}
    `;

/**
 * __useInteractionVersionsQuery__
 *
 * To run a query within a React component, call `useInteractionVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionVersionsQuery({
 *   variables: {
 *      interactionId: // value for 'interactionId'
 *   },
 * });
 */
export function useInteractionVersionsQuery(baseOptions: Apollo.QueryHookOptions<InteractionVersionsQuery, InteractionVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractionVersionsQuery, InteractionVersionsQueryVariables>(InteractionVersionsDocument, options);
      }
export function useInteractionVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractionVersionsQuery, InteractionVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractionVersionsQuery, InteractionVersionsQueryVariables>(InteractionVersionsDocument, options);
        }
export type InteractionVersionsQueryHookResult = ReturnType<typeof useInteractionVersionsQuery>;
export type InteractionVersionsLazyQueryHookResult = ReturnType<typeof useInteractionVersionsLazyQuery>;
export type InteractionVersionsQueryResult = Apollo.QueryResult<InteractionVersionsQuery, InteractionVersionsQueryVariables>;
export function refetchInteractionVersionsQuery(variables: InteractionVersionsQueryVariables) {
      return { query: InteractionVersionsDocument, variables: variables }
    }
export const AuthenticateUserDocument = gql`
    mutation AuthenticateUser($username: String!, $password: String!) {
  authenticateUser(input: {username: $username, password: $password}) {
    jwt
    user {
      id
      username
    }
  }
}
    `;
export type AuthenticateUserMutationFn = Apollo.MutationFunction<AuthenticateUserMutation, AuthenticateUserMutationVariables>;

/**
 * __useAuthenticateUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserMutation, { data, loading, error }] = useAuthenticateUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticateUserMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateUserMutation, AuthenticateUserMutationVariables>(AuthenticateUserDocument, options);
      }
export type AuthenticateUserMutationHookResult = ReturnType<typeof useAuthenticateUserMutation>;
export type AuthenticateUserMutationResult = Apollo.MutationResult<AuthenticateUserMutation>;
export type AuthenticateUserMutationOptions = Apollo.BaseMutationOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($username: String!, $password: String!) {
  registerUser(input: {username: $username, password: $password}) {
    jwt
    user {
      id
      username
    }
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const CreateNoteDocument = gql`
    mutation CreateNote($orgId: Int, $access: NoteAccessEnum, $name: String!, $type: String!, $payload: JSON!, $meta: JSON!, $isPublic: Boolean!, $userId: Int!, $slug: String!) {
  payload: createNote(
    input: {note: {orgId: $orgId, access: $access, name: $name, payload: $payload, meta: $meta, isPublic: $isPublic, userId: $userId, type: $type, slug: $slug}}
  ) {
    note {
      __typename
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      access: // value for 'access'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      payload: // value for 'payload'
 *      meta: // value for 'meta'
 *      isPublic: // value for 'isPublic'
 *      userId: // value for 'userId'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const UpdateNoteDocument = gql`
    mutation UpdateNote($id: Int!, $name: String, $type: String, $slug: String, $payload: JSON, $meta: JSON, $isPublic: Boolean, $userId: Int, $deletedAt: Datetime) {
  payload: updateNote(
    input: {id: $id, patch: {name: $name, type: $type, slug: $slug, payload: $payload, meta: $meta, isPublic: $isPublic, userId: $userId, deletedAt: $deletedAt}}
  ) {
    note {
      __typename
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
      deletedAt
    }
  }
}
    `;
export type UpdateNoteMutationFn = Apollo.MutationFunction<UpdateNoteMutation, UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      slug: // value for 'slug'
 *      payload: // value for 'payload'
 *      meta: // value for 'meta'
 *      isPublic: // value for 'isPublic'
 *      userId: // value for 'userId'
 *      deletedAt: // value for 'deletedAt'
 *   },
 * });
 */
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<UpdateNoteMutation, UpdateNoteMutationVariables>;
export const AllNotesDocument = gql`
    query AllNotes {
  notes(orderBy: UPDATED_AT_DESC) {
    nodes {
      __typename
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useAllNotesQuery__
 *
 * To run a query within a React component, call `useAllNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNotesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllNotesQuery(baseOptions?: Apollo.QueryHookOptions<AllNotesQuery, AllNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllNotesQuery, AllNotesQueryVariables>(AllNotesDocument, options);
      }
export function useAllNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllNotesQuery, AllNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllNotesQuery, AllNotesQueryVariables>(AllNotesDocument, options);
        }
export type AllNotesQueryHookResult = ReturnType<typeof useAllNotesQuery>;
export type AllNotesLazyQueryHookResult = ReturnType<typeof useAllNotesLazyQuery>;
export type AllNotesQueryResult = Apollo.QueryResult<AllNotesQuery, AllNotesQueryVariables>;
export function refetchAllNotesQuery(variables?: AllNotesQueryVariables) {
      return { query: AllNotesDocument, variables: variables }
    }
export const PersonalNotesDocument = gql`
    query PersonalNotes($type: String, $slug: String, $isPublic: Boolean, $userId: Int) {
  notes(
    orderBy: UPDATED_AT_DESC
    condition: {orgId: null, type: $type, slug: $slug, isPublic: $isPublic, userId: $userId}
  ) {
    nodes {
      __typename
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __usePersonalNotesQuery__
 *
 * To run a query within a React component, call `usePersonalNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalNotesQuery({
 *   variables: {
 *      type: // value for 'type'
 *      slug: // value for 'slug'
 *      isPublic: // value for 'isPublic'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePersonalNotesQuery(baseOptions?: Apollo.QueryHookOptions<PersonalNotesQuery, PersonalNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonalNotesQuery, PersonalNotesQueryVariables>(PersonalNotesDocument, options);
      }
export function usePersonalNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonalNotesQuery, PersonalNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonalNotesQuery, PersonalNotesQueryVariables>(PersonalNotesDocument, options);
        }
export type PersonalNotesQueryHookResult = ReturnType<typeof usePersonalNotesQuery>;
export type PersonalNotesLazyQueryHookResult = ReturnType<typeof usePersonalNotesLazyQuery>;
export type PersonalNotesQueryResult = Apollo.QueryResult<PersonalNotesQuery, PersonalNotesQueryVariables>;
export function refetchPersonalNotesQuery(variables?: PersonalNotesQueryVariables) {
      return { query: PersonalNotesDocument, variables: variables }
    }
export const QueryNoteDocument = gql`
    query QueryNote($id: Int!) {
  note: note(id: $id) {
    __typename
    id
    userId
    orgId
    type
    name
    slug
    payload
    meta
    isPublic
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useQueryNoteQuery__
 *
 * To run a query within a React component, call `useQueryNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryNoteQuery(baseOptions: Apollo.QueryHookOptions<QueryNoteQuery, QueryNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryNoteQuery, QueryNoteQueryVariables>(QueryNoteDocument, options);
      }
export function useQueryNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryNoteQuery, QueryNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryNoteQuery, QueryNoteQueryVariables>(QueryNoteDocument, options);
        }
export type QueryNoteQueryHookResult = ReturnType<typeof useQueryNoteQuery>;
export type QueryNoteLazyQueryHookResult = ReturnType<typeof useQueryNoteLazyQuery>;
export type QueryNoteQueryResult = Apollo.QueryResult<QueryNoteQuery, QueryNoteQueryVariables>;
export function refetchQueryNoteQuery(variables: QueryNoteQueryVariables) {
      return { query: QueryNoteDocument, variables: variables }
    }
export const NoteVersionsDocument = gql`
    query NoteVersions($type: String, $slug: String, $isPublic: Boolean, $noteId: Int) {
  noteVersions(
    orderBy: UPDATED_AT_DESC
    condition: {orgId: null, type: $type, slug: $slug, isPublic: $isPublic, noteId: $noteId}
  ) {
    nodes {
      __typename
      id
      noteId
      editorId
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useNoteVersionsQuery__
 *
 * To run a query within a React component, call `useNoteVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteVersionsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      slug: // value for 'slug'
 *      isPublic: // value for 'isPublic'
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useNoteVersionsQuery(baseOptions?: Apollo.QueryHookOptions<NoteVersionsQuery, NoteVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteVersionsQuery, NoteVersionsQueryVariables>(NoteVersionsDocument, options);
      }
export function useNoteVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteVersionsQuery, NoteVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteVersionsQuery, NoteVersionsQueryVariables>(NoteVersionsDocument, options);
        }
export type NoteVersionsQueryHookResult = ReturnType<typeof useNoteVersionsQuery>;
export type NoteVersionsLazyQueryHookResult = ReturnType<typeof useNoteVersionsLazyQuery>;
export type NoteVersionsQueryResult = Apollo.QueryResult<NoteVersionsQuery, NoteVersionsQueryVariables>;
export function refetchNoteVersionsQuery(variables?: NoteVersionsQueryVariables) {
      return { query: NoteVersionsDocument, variables: variables }
    }
export const NoteAtVersionDocument = gql`
    query NoteAtVersion($id: Int!, $versionId: Int!) {
  note: note(id: $id) {
    __typename
    id
    userId
    orgId
    type
    name
    slug
    payload
    meta
    isPublic
    createdAt
    updatedAt
    atVersion(input: {versionId: $versionId}) {
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useNoteAtVersionQuery__
 *
 * To run a query within a React component, call `useNoteAtVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteAtVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteAtVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useNoteAtVersionQuery(baseOptions: Apollo.QueryHookOptions<NoteAtVersionQuery, NoteAtVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteAtVersionQuery, NoteAtVersionQueryVariables>(NoteAtVersionDocument, options);
      }
export function useNoteAtVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteAtVersionQuery, NoteAtVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteAtVersionQuery, NoteAtVersionQueryVariables>(NoteAtVersionDocument, options);
        }
export type NoteAtVersionQueryHookResult = ReturnType<typeof useNoteAtVersionQuery>;
export type NoteAtVersionLazyQueryHookResult = ReturnType<typeof useNoteAtVersionLazyQuery>;
export type NoteAtVersionQueryResult = Apollo.QueryResult<NoteAtVersionQuery, NoteAtVersionQueryVariables>;
export function refetchNoteAtVersionQuery(variables: NoteAtVersionQueryVariables) {
      return { query: NoteAtVersionDocument, variables: variables }
    }
export const GenerateTokenDocument = gql`
    mutation GenerateToken($type: String!, $details: JSON!, $orgId: Int) {
  generateToken(input: {type: $type, details: $details, orgId: $orgId}) {
    token {
      nodeId
      id
      type
      key
      state
      details
      org {
        nodeId
        name
        url
      }
      createdAt
      updatedAt
    }
  }
}
    `;
export type GenerateTokenMutationFn = Apollo.MutationFunction<GenerateTokenMutation, GenerateTokenMutationVariables>;

/**
 * __useGenerateTokenMutation__
 *
 * To run a mutation, you first call `useGenerateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTokenMutation, { data, loading, error }] = useGenerateTokenMutation({
 *   variables: {
 *      type: // value for 'type'
 *      details: // value for 'details'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGenerateTokenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateTokenMutation, GenerateTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateTokenMutation, GenerateTokenMutationVariables>(GenerateTokenDocument, options);
      }
export type GenerateTokenMutationHookResult = ReturnType<typeof useGenerateTokenMutation>;
export type GenerateTokenMutationResult = Apollo.MutationResult<GenerateTokenMutation>;
export type GenerateTokenMutationOptions = Apollo.BaseMutationOptions<GenerateTokenMutation, GenerateTokenMutationVariables>;
export const ValidateTokenDocument = gql`
    query ValidateToken($key: String!) {
  validateToken(key: $key) {
    nodeId
    id
    type
    key
    state
    details
    org {
      nodeId
      name
      url
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useValidateTokenQuery__
 *
 * To run a query within a React component, call `useValidateTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateTokenQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useValidateTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateTokenQuery, ValidateTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateTokenQuery, ValidateTokenQueryVariables>(ValidateTokenDocument, options);
      }
export function useValidateTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateTokenQuery, ValidateTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateTokenQuery, ValidateTokenQueryVariables>(ValidateTokenDocument, options);
        }
export type ValidateTokenQueryHookResult = ReturnType<typeof useValidateTokenQuery>;
export type ValidateTokenLazyQueryHookResult = ReturnType<typeof useValidateTokenLazyQuery>;
export type ValidateTokenQueryResult = Apollo.QueryResult<ValidateTokenQuery, ValidateTokenQueryVariables>;
export function refetchValidateTokenQuery(variables: ValidateTokenQueryVariables) {
      return { query: ValidateTokenDocument, variables: variables }
    }
export const DisableTokenDocument = gql`
    mutation DisableToken($key: String!) {
  disableToken(input: {key: $key}) {
    token {
      nodeId
      id
      type
      key
      state
      details
      org {
        nodeId
        name
        url
      }
      createdAt
      updatedAt
    }
  }
}
    `;
export type DisableTokenMutationFn = Apollo.MutationFunction<DisableTokenMutation, DisableTokenMutationVariables>;

/**
 * __useDisableTokenMutation__
 *
 * To run a mutation, you first call `useDisableTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableTokenMutation, { data, loading, error }] = useDisableTokenMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDisableTokenMutation(baseOptions?: Apollo.MutationHookOptions<DisableTokenMutation, DisableTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableTokenMutation, DisableTokenMutationVariables>(DisableTokenDocument, options);
      }
export type DisableTokenMutationHookResult = ReturnType<typeof useDisableTokenMutation>;
export type DisableTokenMutationResult = Apollo.MutationResult<DisableTokenMutation>;
export type DisableTokenMutationOptions = Apollo.BaseMutationOptions<DisableTokenMutation, DisableTokenMutationVariables>;
export const ApplyTokenDocument = gql`
    mutation ApplyToken($key: String!, $details: JSON!) {
  applyToken(input: {key: $key, details: $details}) {
    organization {
      nodeId
      id
      type
      name
      url
      createdAt
      updatedAt
    }
  }
}
    `;
export type ApplyTokenMutationFn = Apollo.MutationFunction<ApplyTokenMutation, ApplyTokenMutationVariables>;

/**
 * __useApplyTokenMutation__
 *
 * To run a mutation, you first call `useApplyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyTokenMutation, { data, loading, error }] = useApplyTokenMutation({
 *   variables: {
 *      key: // value for 'key'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useApplyTokenMutation(baseOptions?: Apollo.MutationHookOptions<ApplyTokenMutation, ApplyTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyTokenMutation, ApplyTokenMutationVariables>(ApplyTokenDocument, options);
      }
export type ApplyTokenMutationHookResult = ReturnType<typeof useApplyTokenMutation>;
export type ApplyTokenMutationResult = Apollo.MutationResult<ApplyTokenMutation>;
export type ApplyTokenMutationOptions = Apollo.BaseMutationOptions<ApplyTokenMutation, ApplyTokenMutationVariables>;
export const OrganizationsNotesDocument = gql`
    query OrganizationsNotes($url: String) {
  organizations(orderBy: UPDATED_AT_DESC, condition: {url: $url}) {
    nodes {
      id
      name
      url
      notes: notesByOrgId(orderBy: UPDATED_AT_DESC) {
        nodes {
          __typename
          id
          userId
          type
          name
          slug
          payload
          meta
          isPublic
          createdAt
          updatedAt
        }
      }
    }
  }
}
    `;

/**
 * __useOrganizationsNotesQuery__
 *
 * To run a query within a React component, call `useOrganizationsNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsNotesQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useOrganizationsNotesQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationsNotesQuery, OrganizationsNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsNotesQuery, OrganizationsNotesQueryVariables>(OrganizationsNotesDocument, options);
      }
export function useOrganizationsNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsNotesQuery, OrganizationsNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsNotesQuery, OrganizationsNotesQueryVariables>(OrganizationsNotesDocument, options);
        }
export type OrganizationsNotesQueryHookResult = ReturnType<typeof useOrganizationsNotesQuery>;
export type OrganizationsNotesLazyQueryHookResult = ReturnType<typeof useOrganizationsNotesLazyQuery>;
export type OrganizationsNotesQueryResult = Apollo.QueryResult<OrganizationsNotesQuery, OrganizationsNotesQueryVariables>;
export function refetchOrganizationsNotesQuery(variables?: OrganizationsNotesQueryVariables) {
      return { query: OrganizationsNotesDocument, variables: variables }
    }
export const OrgSettingsDocument = gql`
    query OrgSettings($url: String!) {
  organizationByUrl(url: $url) {
    id
    name
    url
    ownerId
    tokensByOrgId {
      nodes {
        id
        key
        type
        state
        details
        createdAt
        updatedAt
        grantedById
      }
    }
    membershipsByOrgId {
      nodes {
        __typename
        id
        userId
        type
        state
        payload
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useOrgSettingsQuery__
 *
 * To run a query within a React component, call `useOrgSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgSettingsQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useOrgSettingsQuery(baseOptions: Apollo.QueryHookOptions<OrgSettingsQuery, OrgSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgSettingsQuery, OrgSettingsQueryVariables>(OrgSettingsDocument, options);
      }
export function useOrgSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgSettingsQuery, OrgSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgSettingsQuery, OrgSettingsQueryVariables>(OrgSettingsDocument, options);
        }
export type OrgSettingsQueryHookResult = ReturnType<typeof useOrgSettingsQuery>;
export type OrgSettingsLazyQueryHookResult = ReturnType<typeof useOrgSettingsLazyQuery>;
export type OrgSettingsQueryResult = Apollo.QueryResult<OrgSettingsQuery, OrgSettingsQueryVariables>;
export function refetchOrgSettingsQuery(variables: OrgSettingsQueryVariables) {
      return { query: OrgSettingsDocument, variables: variables }
    }
export const UpdateMembershipDocument = gql`
    mutation UpdateMembership($id: Int!, $type: String, $state: String, $payload: JSON) {
  updateMembership(
    input: {id: $id, patch: {id: $id, type: $type, state: $state, payload: $payload}}
  ) {
    membership {
      __typename
      id
      type
      state
      payload
      createdAt
      updatedAt
    }
  }
}
    `;
export type UpdateMembershipMutationFn = Apollo.MutationFunction<UpdateMembershipMutation, UpdateMembershipMutationVariables>;

/**
 * __useUpdateMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipMutation, { data, loading, error }] = useUpdateMembershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      state: // value for 'state'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipMutation, UpdateMembershipMutationVariables>(UpdateMembershipDocument, options);
      }
export type UpdateMembershipMutationHookResult = ReturnType<typeof useUpdateMembershipMutation>;
export type UpdateMembershipMutationResult = Apollo.MutationResult<UpdateMembershipMutation>;
export type UpdateMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>;
export const ProgramAtVersionDocument = gql`
    query ProgramAtVersion($id: Int!, $versionId: Int!) {
  program(id: $id) {
    __typename
    id
    userId
    orgId
    type
    name
    slug
    payload
    meta
    isPublic
    createdAt
    updatedAt
    atVersion(input: {versionId: $versionId}) {
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useProgramAtVersionQuery__
 *
 * To run a query within a React component, call `useProgramAtVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramAtVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramAtVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useProgramAtVersionQuery(baseOptions: Apollo.QueryHookOptions<ProgramAtVersionQuery, ProgramAtVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProgramAtVersionQuery, ProgramAtVersionQueryVariables>(ProgramAtVersionDocument, options);
      }
export function useProgramAtVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramAtVersionQuery, ProgramAtVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProgramAtVersionQuery, ProgramAtVersionQueryVariables>(ProgramAtVersionDocument, options);
        }
export type ProgramAtVersionQueryHookResult = ReturnType<typeof useProgramAtVersionQuery>;
export type ProgramAtVersionLazyQueryHookResult = ReturnType<typeof useProgramAtVersionLazyQuery>;
export type ProgramAtVersionQueryResult = Apollo.QueryResult<ProgramAtVersionQuery, ProgramAtVersionQueryVariables>;
export function refetchProgramAtVersionQuery(variables: ProgramAtVersionQueryVariables) {
      return { query: ProgramAtVersionDocument, variables: variables }
    }
export const InteractionAtVersionDocument = gql`
    query InteractionAtVersion($id: Int!, $versionId: Int!) {
  interaction(id: $id) {
    __typename
    id
    userId
    orgId
    type
    name
    slug
    payload
    meta
    isPublic
    createdAt
    updatedAt
    atVersion(input: {versionId: $versionId}) {
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useInteractionAtVersionQuery__
 *
 * To run a query within a React component, call `useInteractionAtVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionAtVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionAtVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useInteractionAtVersionQuery(baseOptions: Apollo.QueryHookOptions<InteractionAtVersionQuery, InteractionAtVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractionAtVersionQuery, InteractionAtVersionQueryVariables>(InteractionAtVersionDocument, options);
      }
export function useInteractionAtVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractionAtVersionQuery, InteractionAtVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractionAtVersionQuery, InteractionAtVersionQueryVariables>(InteractionAtVersionDocument, options);
        }
export type InteractionAtVersionQueryHookResult = ReturnType<typeof useInteractionAtVersionQuery>;
export type InteractionAtVersionLazyQueryHookResult = ReturnType<typeof useInteractionAtVersionLazyQuery>;
export type InteractionAtVersionQueryResult = Apollo.QueryResult<InteractionAtVersionQuery, InteractionAtVersionQueryVariables>;
export function refetchInteractionAtVersionQuery(variables: InteractionAtVersionQueryVariables) {
      return { query: InteractionAtVersionDocument, variables: variables }
    }
export const UserRegistrationDocument = gql`
    mutation UserRegistration($username: String!, $password: String!) {
  registerUser(input: {username: $username, password: $password}) {
    user {
      id
      username
    }
  }
}
    `;
export type UserRegistrationMutationFn = Apollo.MutationFunction<UserRegistrationMutation, UserRegistrationMutationVariables>;

/**
 * __useUserRegistrationMutation__
 *
 * To run a mutation, you first call `useUserRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRegistrationMutation, { data, loading, error }] = useUserRegistrationMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<UserRegistrationMutation, UserRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRegistrationMutation, UserRegistrationMutationVariables>(UserRegistrationDocument, options);
      }
export type UserRegistrationMutationHookResult = ReturnType<typeof useUserRegistrationMutation>;
export type UserRegistrationMutationResult = Apollo.MutationResult<UserRegistrationMutation>;
export type UserRegistrationMutationOptions = Apollo.BaseMutationOptions<UserRegistrationMutation, UserRegistrationMutationVariables>;
export const CreateStateDocument = gql`
    mutation CreateState($userId: Int!, $programId: Int!, $interactionId: Int!, $state: StateStateEnum, $payload: JSON, $meta: JSON) {
  createState(
    input: {state: {userId: $userId, programId: $programId, interactionId: $interactionId, state: $state, payload: $payload, meta: $meta}}
  ) {
    state {
      __typename
      id
      userId
      programId
      interactionId
      state
      payload
      meta
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateStateMutationFn = Apollo.MutationFunction<CreateStateMutation, CreateStateMutationVariables>;

/**
 * __useCreateStateMutation__
 *
 * To run a mutation, you first call `useCreateStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStateMutation, { data, loading, error }] = useCreateStateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      programId: // value for 'programId'
 *      interactionId: // value for 'interactionId'
 *      state: // value for 'state'
 *      payload: // value for 'payload'
 *      meta: // value for 'meta'
 *   },
 * });
 */
export function useCreateStateMutation(baseOptions?: Apollo.MutationHookOptions<CreateStateMutation, CreateStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStateMutation, CreateStateMutationVariables>(CreateStateDocument, options);
      }
export type CreateStateMutationHookResult = ReturnType<typeof useCreateStateMutation>;
export type CreateStateMutationResult = Apollo.MutationResult<CreateStateMutation>;
export type CreateStateMutationOptions = Apollo.BaseMutationOptions<CreateStateMutation, CreateStateMutationVariables>;
export const StatesDocument = gql`
    query States($userId: Int, $programId: Int, $interactionId: Int) {
  states(
    orderBy: UPDATED_AT_DESC
    condition: {userId: $userId, programId: $programId, interactionId: $interactionId}
  ) {
    nodes {
      __typename
      id
      userId
      programId
      interactionId
      state
      payload
      meta
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useStatesQuery__
 *
 * To run a query within a React component, call `useStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      programId: // value for 'programId'
 *      interactionId: // value for 'interactionId'
 *   },
 * });
 */
export function useStatesQuery(baseOptions?: Apollo.QueryHookOptions<StatesQuery, StatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatesQuery, StatesQueryVariables>(StatesDocument, options);
      }
export function useStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatesQuery, StatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatesQuery, StatesQueryVariables>(StatesDocument, options);
        }
export type StatesQueryHookResult = ReturnType<typeof useStatesQuery>;
export type StatesLazyQueryHookResult = ReturnType<typeof useStatesLazyQuery>;
export type StatesQueryResult = Apollo.QueryResult<StatesQuery, StatesQueryVariables>;
export function refetchStatesQuery(variables?: StatesQueryVariables) {
      return { query: StatesDocument, variables: variables }
    }
export const UpdateStateDocument = gql`
    mutation UpdateState($id: Int!, $state: StateStateEnum, $payload: JSON, $meta: JSON) {
  updateState(
    input: {id: $id, patch: {state: $state, payload: $payload, meta: $meta}}
  ) {
    state {
      __typename
      id
      userId
      programId
      interactionId
      state
      payload
      meta
      createdAt
      updatedAt
    }
  }
}
    `;
export type UpdateStateMutationFn = Apollo.MutationFunction<UpdateStateMutation, UpdateStateMutationVariables>;

/**
 * __useUpdateStateMutation__
 *
 * To run a mutation, you first call `useUpdateStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStateMutation, { data, loading, error }] = useUpdateStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *      payload: // value for 'payload'
 *      meta: // value for 'meta'
 *   },
 * });
 */
export function useUpdateStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStateMutation, UpdateStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStateMutation, UpdateStateMutationVariables>(UpdateStateDocument, options);
      }
export type UpdateStateMutationHookResult = ReturnType<typeof useUpdateStateMutation>;
export type UpdateStateMutationResult = Apollo.MutationResult<UpdateStateMutation>;
export type UpdateStateMutationOptions = Apollo.BaseMutationOptions<UpdateStateMutation, UpdateStateMutationVariables>;