import * as React from "react";

import SimpleMDE from "react-simplemde-editor";

// tslint:disable-next-line:no-var-requires no-submodule-imports no-implicit-dependencies
require("easymde/dist/easymde.min.css");

const fullscreenStyle = require("./style.scss");

interface IOption {
    label?: string;
    value: string;
}

interface IProps {
    onChange?: (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    ) => void;
    onMarkdownChange?: (value: string) => void;
    label: string;
    name: string;
    required?: boolean;
    type?: "markdown" | "textarea" | "select" | "text" | string;
    value?: string;
    options?: IOption[];
    disabled?: boolean;
    readOnly?: boolean;
}

const style = {
    fieldContainer: "mt3",
    fieldInput: "input-reset ba b--black-20 pa2 mb2 db w-100",
    fieldLabel: "db fw4 lh-copy f6",
    fieldMarkdown: fullscreenStyle.markdown,
    fieldSelect: "pa2 ba bg-transparent",
    fieldTextarea: "input-reset ba b--black-20 pa2 mb2 db w-100 h5",
};

export const FormField = (props: IProps) => {
    return (
        <div className={style.fieldContainer}>
            <label className={style.fieldLabel} htmlFor={props.name}>
                {props.label}
            </label>
            {props.type !== "markdown" &&
                props.type !== "textarea" &&
                props.type !== "select" && (
                    <input
                        onChange={props.onChange}
                        className={style.fieldInput}
                        type={props.type || "text"}
                        name={props.name}
                        id={props.name}
                        value={props.value || ""}
                        required={props.required}
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                    />
                )}
            {props.type === "textarea" && (
                <textarea
                    onChange={props.onChange}
                    className={style.fieldTextarea}
                    name={props.name}
                    id={props.name}
                    value={props.value || ""}
                    required={props.required}
                    disabled={props.disabled}
                    readOnly={props.readOnly}
                />
            )}
            {props.type === "markdown" && (
                <SimpleMDE
                    onChange={props.onMarkdownChange}
                    className={style.fieldMarkdown}
                    id={props.name}
                    value={props.value || ""}
                />
            )}
            {props.type === "select" && (
                <select
                    onChange={props.onChange}
                    className={style.fieldSelect}
                    name={props.name}
                    id={props.name}
                    value={props.value || undefined}>
                    required={props.required}
                    disabled={props.disabled}
                    {props.options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label || option.value}
                        </option>
                    ))}
                </select>
            )}
        </div>
    );
};
