import * as React from "react";

import { useReactiveVar } from "@apollo/client";
import * as Modal from "react-modal";
import { Navigate, Route, Routes } from "react-router-dom";

import { Layout, routeWithHeader } from "../utils/routeWithNavigation";

import { RegisterComponent } from "../components/Register/index";
import { SigninAs, SigninAsModal } from "../components/Register/signin";
import { alertsVar, currentUserVar } from "./auth.data";

export const AuthCheck = (props: any, Component: any) => {
    const user = useReactiveVar(currentUserVar);
    return (
        <div>
            {() => {
                return (
                    (!user && (
                        <Navigate
                            to={{
                                pathname: "/signin",
                            }}
                            state={{ app: props.history.location.pathname }}
                        />
                    )) || <Component {...props} />
                );
            }}
        </div>
    );
};

export const AuthModal = () => {
    const alerts = useReactiveVar(alertsVar);
    return (
        <Modal isOpen={alerts.length > 0}>
            <SigninAsModal />
        </Modal>
    );
};

export const AuthRoutes = ({
    Homepage,
    openRoutes,
}: {
    Homepage?: React.FunctionComponent;
    openRoutes?: () => JSX.Element[];
}) => {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                {openRoutes && openRoutes().map((e) => e)}
                <Route path="register" element={<RegisterComponent />} />
                <Route path="signin" element={<SigninAs />} />
                {Homepage && <Route index={true} element={<Homepage />} />}
            </Route>
        </Routes>
    );
};
