import { get } from "lodash";

export interface IContent {
    id: number;
    name: string;
    contents?: IContent[];
}

export interface IProgramPayload {
    contents: IContent[];
    bespoke: {
        preroll: IContent[];
        about: IContent[];
        agreements: IContent[];
    };
}

type BespokeType = "contents" | "preroll" | "about" | "agreements";

export const getContent = (payload: IProgramPayload, contentType: BespokeType) => {
    return get(payload, "contents" === contentType ? "contents" : `bespoke.${contentType}`);
};

export const toInt = (sOrI: number | string) => {
    return parseInt(sOrI.toString(), 10);
};
