import cx from "classnames";
import * as React from "react";

const scssStyle = require("./layout.scss").default;

export const style = {
    sideBarColumn: cx(scssStyle.sideBarColumn),
    explorerColumn: "ml3",
    mainColumn: cx("ml3", scssStyle.grow),
};
