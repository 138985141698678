import * as React from "react";
const { useState } = React;

import { NavLink, useNavigate, useSearchParams } from "react-router-dom";

import { FormField } from "../../components/Form/Field";

import { useAuthenticate } from "./query";

import { Button } from "../../../shared/components/Button";
import { alertsVar, currentUserVar } from "../../services/auth.data";
import { useReactiveVar } from "@apollo/client";

interface IUserInput {
    username: string;
    password: string;
}

interface ISignInAsState {
    app: string;
}

export const SigninAs = () => {
    const navigateTo = useNavigate();
    const [searchParams] = useSearchParams();
    const app = searchParams.get("app");

    const [userInput, setUserInput] = useState<IUserInput>({
        username: "",
        password: "",
    });
    const handleUserInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const newInput = { [e.currentTarget.name]: e.currentTarget.value };
        setUserInput((prevUserInput: IUserInput) => ({
            ...userInput,
            ...newInput,
        }));
    };

    const [authenticate, authenticateResponse] = useAuthenticate();
    const authErrors = authenticateResponse?.error?.graphQLErrors || [];

    const handleUserSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await authenticate(userInput);
        if (!!!response.errors) {
            app ? navigateTo(app) : navigateTo("/");
        }
    };

    return (
        <div className={"w-30 fl ml5 ph2"}>
            <h1>Log in</h1>
            <form onSubmit={handleUserSubmit}>
                <div className={"mb3"}>
                    <FormField
                        label={"Username"}
                        name={"username"}
                        type={"text"}
                        value={userInput.username}
                        onChange={handleUserInputChange}
                    />
                </div>
                <div className={"mb3"}>
                    <FormField
                        label={"Password"}
                        name={"password"}
                        type={"password"}
                        value={userInput.password}
                        onChange={handleUserInputChange}
                    />
                </div>
                <Button type={"submit"} className={"mb3"}>
                    Sign In
                </Button>
                <div>
                    <NavLink to="/register" className={"underline"}>
                        Register here.
                    </NavLink>
                    <br />
                </div>
                {authErrors.map((e: any, i: number) => (
                    <div key={i} className={"dib pv2 red"}>
                        {e.message}
                    </div>
                ))}
            </form>
        </div>
    );
};

export const SigninAsModal = () => {
    const user = useReactiveVar(currentUserVar);
    const [userInput, setUserInput] = useState<IUserInput>({
        username: user.username,
        password: "",
    });
    const handleUserInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const newInput = { [e.currentTarget.name]: e.currentTarget.value };
        setUserInput((prevUserInput: IUserInput) => ({
            ...userInput,
            ...newInput,
        }));
    };

    const [authenticate, authenticateResponse] = useAuthenticate();
    const authErrors = authenticateResponse?.error?.graphQLErrors || [];

    const handleUserSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await authenticate(userInput);
        if (!!!response.errors) {
            alertsVar([]);
        }
    };

    return (
        <div className={"w-30 fl ml5 ph2"}>
            <h1>Log in</h1>
            <form onSubmit={handleUserSubmit}>
                <div className={"mb3"}>
                    <FormField
                        label={"Username"}
                        name={"username"}
                        type={"text"}
                        value={userInput.username}
                        onChange={handleUserInputChange}
                        disabled={true}
                    />
                </div>
                <div className={"mb3"}>
                    <FormField
                        label={"Password"}
                        name={"password"}
                        type={"password"}
                        value={userInput.password}
                        onChange={handleUserInputChange}
                    />
                </div>
                <Button type={"submit"} className={"mb3"}>
                    Sign In
                </Button>
                {authErrors.map((e: any, i: number) => (
                    <div key={i} className={"dib pv2 red"}>
                        {e.message}
                    </div>
                ))}
            </form>
        </div>
    );
};
