import * as React from "react";

import { useReactiveVar } from "@apollo/client";
import { useOutletContext, useParams } from "react-router-dom";
import * as Modal from "react-modal";

import { alertsVar } from "../../shared/services/auth.data";
import { ContentLocation, IInteractionLocationParams } from "../shared/routes/locations";
import { Sidebar, SidebarSectionEnum } from "../shared/components/Sidebar";
import {
    UpdateInteractionMutationVariables,
    useQueryInteractionQuery,
    useUpdateInteractionMutation,
} from "../../shared/models/types";
import { IInteractionArrow } from "../shared/models/program";
import { IOutletContext } from "../ProgramPage";
import { Explorer } from "../EditorPage/Explorer";
import { Editor } from "./Editor";
import { style } from "../shared/components/Layout/style";
import { AddInteraction } from "./AddInteraction";

export const InteractionPage = () => {
    const params = useParams<IInteractionLocationParams>();
    const { section } = params;
    const {
        organization,
        program,
        addInteraction,
        removeInteraction,
        moveUpInteraction,
        updateInteraction,
    } = useOutletContext() as IOutletContext;

    const [modal, setModal] = React.useState(false);
    const [targetLocation, setTargetLocation] = React.useState([]);
    const addInteractionModal = (loc: number[]) => {
        setTargetLocation(loc);
        setModal(true);
    };

    const targetContents: IInteractionArrow[] = program?.payload[section] || [];

    const { loc } = ContentLocation.parseContentLocation(params["*"]);

    const currentInteractionId = loc.length > 0 ? loc[loc.length - 1] : 0;
    const { data: targetInteractionData, refetch: refetchTargetInteraction } = useQueryInteractionQuery({
        variables: { id: currentInteractionId },
        skip: currentInteractionId === 0,
    });
    const targetInteraction = targetInteractionData?.interaction;

    const [editInteractionMutation] = useUpdateInteractionMutation();
    const editInteraction = async (patch: UpdateInteractionMutationVariables) => {
        await editInteractionMutation({ variables: patch });
        updateInteraction(targetContents, loc, section, {
            id: patch.id,
            name: patch.name,
            slug: patch.slug,
        });
        refetchTargetInteraction();
    };

    return (
        <div>
            <div className="flex">
                <Sidebar section={SidebarSectionEnum.Interactions} />
                {!!!section && (
                    <div className={style.mainColumn}>
                        This is the interaction page. Select a section.
                        <div>
                            <input
                                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                onClick={() => {
                                    addInteractionModal([]);
                                }}
                                type="button"
                                value="Add Interaction"
                            />
                        </div>
                    </div>
                )}
                {!!section && (
                    <React.Fragment>
                        <div className={style.explorerColumn}>
                            <div>
                                <input
                                    className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                                    onClick={() => {
                                        addInteractionModal([]);
                                    }}
                                    type="button"
                                    value="Add Interaction"
                                />
                            </div>
                            <Explorer
                                contents={targetContents}
                                ancestry={[]}
                                addContent={addInteractionModal}
                                removeContent={(indexes: number[]) => {
                                    removeInteraction(targetContents, indexes, section);
                                }}
                                moveContentUp={(indexes: number[]) => {
                                    moveUpInteraction(targetContents, indexes, section);
                                }}
                                section={section}
                                currentId={currentInteractionId}
                            />
                        </div>
                        <div className={style.mainColumn}>
                            {targetInteraction && (
                                <Editor interaction={targetInteraction} editInteraction={editInteraction} />
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>
            <Modal isOpen={modal} ariaHideApp={false}>
                <AddInteraction
                    addInteraction={addInteraction}
                    organization={organization}
                    program={program}
                    type={section}
                    location={targetLocation}
                    close={() => {
                        setModal(false);
                    }}
                />
            </Modal>
        </div>
    );
};
