import * as moment from "moment";

export const toDate = (ds: string) => {
    return moment(ds)
        .local()
        .format("L");
};

export const getLocalDate = (dt: string) => {
    return moment
        .utc(dt)
        .local()
        .format("L");
};

export const getLocalTime = (dt: string) => {
    return moment
        .utc(dt)
        .local()
        .format("h:mm a");
};

export const getCurrentLocalDate = () =>
    moment
        .utc()
        .local()
        .format("L");
