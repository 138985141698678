import { makeVar, useReactiveVar } from "@apollo/client";

interface IAlert {
    type: string;
    name: string;
    createdAt: any;
}
export const alertsVar = makeVar([] as IAlert[]);
(window as any)._alertsVar = alertsVar;

export interface ICurrentUser {
    id?: number;
    username?: string;
    token?: string;
    role?: string;
}
export const currentUserVar = makeVar<ICurrentUser>(null);

export const useCurrentUser = () => {
    return useReactiveVar(currentUserVar);
};
