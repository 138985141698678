import * as React from "react";

import cx from "classnames";

export const TextFormField = (props: {
    inputRef?: React.RefObject<HTMLInputElement>;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    name: string;
    placeholder: string;
    value: string;
    maxLength: number;
    disabled?: boolean;
    className?: string;
}) => {
    return (
        <input
            autoComplete="off"
            name={props.name}
            ref={props.inputRef}
            placeholder={props.placeholder}
            className={cx("input-reset ba b--black-20 pa2 mb2 db w-100", props.className)}
            type={"text"}
            onChange={props.onChange}
            value={props.value || ""}
            maxLength={props.maxLength}
            disabled={props.disabled === true}
        />
    );
};
// (e) => setTokenDetails({
//     ...tokenDetails,
//     [e.currentTarget.name]: e.currentTarget.value,
// })
export const passthrough = <T,>(currentValue: T, setter: (x: T) => void) => {
    return (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        setter({
            ...currentValue,
            [e.currentTarget.name]: e.currentTarget.value,
        });
};
// onChange={() => {
//     setTokenDetails({ ...tokenDetails, editable: !tokenDetails.editable });
// }}
export const passthroughBoolean = <T,>(currentValue: T, setter: (x: T) => void) => {
    return (e: React.FormEvent<HTMLInputElement>) => {
        setter({
            ...currentValue,
            [e.currentTarget.name]: !!!currentValue[e.currentTarget.name as keyof typeof currentValue],
        });
    };
};

export const TextareaFormField = (props: {
    onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
    name: string;
    placeholder: string;
    value: string;
    maxLength?: number;
    disabled?: boolean;
    className?: string;
}) => {
    return (
        <textarea
            autoComplete="off"
            name={props.name}
            placeholder={props.placeholder}
            className={cx("input-reset ba b--black-20 pa2 mb2 db w-100 vh-50", props.className)}
            onChange={props.onChange}
            value={props.value || ""}
            maxLength={props.maxLength}
            disabled={props.disabled === true}
        />
    );
};
