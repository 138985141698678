import Location from "app-location";
import * as yup from "yup";

const idType = yup
    .number()
    .integer()
    .positive()
    .required();

const idArrayType = yup
    .array()
    .of(idType)
    .required();

const uuidType = yup
    .string()
    .uuid()
    .required();

const codeType = yup.string().required();

export const NoteLocation = new Location("/n/:id", {
    id: idType,
});

export const NewNoteLocation = new Location("/new");
export const OrgNewNoteLocation = new Location("/o/:code/new", {
    code: codeType,
});
export const getNewNoteUrl = (code?: string) => {
    if (code) {
        return OrgNewNoteLocation.toUrl({ code });
    } else {
        return NewNoteLocation.toUrl();
    }
};

export const SettingsLocation = new Location("/s");

export const NewOrganizationLocation = new Location("/new_org/:key", {
    key: uuidType,
});

export const OrganizationLocation = new Location("/o/:code", {
    code: codeType,
});

export const OrgNoteLocation = new Location("/o/:code/n/:id", {
    id: idType,
});

export const getNoteUrl = (params: { id?: number; code?: string }) => {
    if (params.code) {
        return OrgNoteLocation.toUrl(params);
    } else {
        return NoteLocation.toUrl(params);
    }
};

export const OrgSettings = new Location("/o/:code/settings", {
    code: codeType,
});

export const InvitationPage = new Location("/o/:code/invite/:key", {
    code: codeType,
    key: uuidType,
});
