import { useReactiveVar } from "@apollo/client";
import cx from "classnames";
import * as React from "react";
import { NavLink } from "react-router-dom";

import { endSession } from "../../../../shared/services/auth";
import { currentUserVar } from "../../../../shared/services/auth.data";

const Longform = require("../../../../Longform/shared/images/longform.svg");

const style = {
    container: "flex justify-between items-center w-100 bg-white bb b--light-gray tc center mt2",
    image: "w2 pl2 self-center",
    link: "f6 f5-l link bg-animate black-80 hover-bg-lightest-blue pa3 ph4-l",
};

export const Header = () => {
    const user = useReactiveVar(currentUserVar);
    const handleLogout = (e: React.MouseEvent) => {
        endSession();
    };
    return (
        <nav className={cx("dt w-100")}>
            <div className="dtc w2 v-mid pa3">
                <NavLink to="/" className="dib w2 h2 pa1 ba b--white-90 grow-large border-box">
                    <img className={style.image} src={Longform} />
                </NavLink>
            </div>
            <div className="dtc v-mid tr pa3">
                {!!!user && (
                    <NavLink to="/signin" className="f6 fw4 hover-black no-underline black-70 dib pv2 ph3">
                        Log In
                    </NavLink>
                )}
                {!!user && (
                    <React.Fragment>
                        <span className="f6 fw4 hover-black no-underline black-70 dib pv2 ph3">{user.username}</span>
                        <a
                            href="#"
                            onClick={(e) => handleLogout(e)}
                            className="f6 fw4 hover-black no-underline black-70 dib pv2 ph3">
                            Log Out
                        </a>
                    </React.Fragment>
                )}
            </div>
        </nav>
    );
};
