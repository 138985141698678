import cx from "classnames";
import * as React from "react";
const { useState, useRef, useEffect, useMemo } = React;

import { Editor as CMEditor } from "codemirror";
import * as Markdown from "markdown-it";
import SimpleMDEEditor, { SimpleMdeToCodemirrorEvents } from "react-simplemde-editor";
const md = new Markdown();

import { passthrough, TextFormField } from "../../Longform/shared/components/form";
import { GenericInteraction } from "../shared/models/program";
import { UpdateInteractionMutationVariables } from "../../shared/models/types";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router";
import { EditorFooter } from "../shared/components/EditorFooter";

interface IProps {
    interaction: GenericInteraction;
    editInteraction: (patch: UpdateInteractionMutationVariables) => Promise<void>;
}

export const Editor = (props: IProps) => {
    const [patch, setPatch] = useState(props.interaction);
    const setMarkdown = (newMarkdownString: string) => {
        setPatch({
            ...patch,
            payload: {
                ...patch.payload,
                md: newMarkdownString,
            },
        });
    };

    const saveInteractionHandler = async (event?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        await props.editInteraction(patch);
    };
    const saveInteractionRef = useRef(null);
    saveInteractionRef.current = saveInteractionHandler;

    const nameRef = useRef(null);
    useEffect(() => {
        if (patch.id !== props.interaction.id) {
            nameRef.current?.focus();
            setPatch(props.interaction);
        }
    });

    const isUnsaved = () => {
        if (!!!props.interaction) {
            return false;
        }
        if (props.interaction.name !== patch.name) {
            return true;
        }
        if (props.interaction.payload.md !== patch.payload.md) {
            return true;
        }
        return false;
    };

    const footerRef = useRef(null);
    const mdeEvents = useMemo(() => {
        return {
            cursorActivity: (instance: CMEditor) => {
                if (!!!footerRef.current) {
                    return;
                }
                const cursorBounds = instance.cursorCoords(false, "window");
                const footerBounds = footerRef.current?.getBoundingClientRect();

                const cursorBottom = cursorBounds.bottom;
                const curserHeight = cursorBottom - cursorBounds.top;
                const footerTop = footerBounds.top;
                if (cursorBottom > footerTop - curserHeight) {
                    window.scrollBy(0, curserHeight * 6);
                }
            },
        } as SimpleMdeToCodemirrorEvents;
    }, []);

    useHotkeys(
        "ctrl+s, cmd+s",
        (e: KeyboardEvent) => {
            saveInteractionRef.current();
            e.preventDefault();
        },
        { enableOnTags: ["INPUT", "TEXTAREA", "SELECT"] },
    );

    if (!!!props.interaction) {
        return <div>Loading...</div>;
    }
    return (
        <div>
            <form onSubmit={saveInteractionRef.current} className={"dark-gray w-100 w-75-l mh2"}>
                <div>
                    <TextFormField
                        name="name"
                        inputRef={nameRef}
                        value={patch.name}
                        placeholder="Interaction Name"
                        onChange={passthrough(patch, setPatch)}
                        maxLength={128}
                    />
                </div>
                <div>
                    <SimpleMDEEditor value={patch.payload.md} onChange={setMarkdown} events={mdeEvents} />

                    <div ref={footerRef as any} className={cx("bg-white bottom-0")} style={{ position: "sticky" }}>
                        <EditorFooter
                            note={props.interaction}
                            isUnsaved={isUnsaved}
                            failure={{ count: 0, message: null }}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};
