import * as React from "react";

import { Link, useOutletContext, useParams } from "react-router-dom";
import cx from "classnames";

import { InteractionLocation, ProgramLocation, ProgramVersionLocation } from "../../routes/locations";
import { ProgramInteractionSections } from "../../models/program";
import { style } from "../Layout/style";

export enum SidebarSectionEnum {
    ProgramOverview,
    Interactions,
}

// const getSection = (params) => {

// }

export const Sidebar = (props: ISidebarProps) => {
    const params = useParams();
    let list;
    if (props.section === SidebarSectionEnum.ProgramOverview) {
        list = (
            <ul className="list">
                <li>
                    <Link to={InteractionLocation.toUrl()}>Interactions</Link>
                </li>
                <li>
                    <Link to={ProgramVersionLocation.toUrl()}>Versions</Link>
                </li>
                <li>Variables</li>
                <li>Settings</li>
            </ul>
        );
    }
    if (props.section === SidebarSectionEnum.Interactions) {
        list = (
            <ul className={"list"}>
                <li>
                    <Link
                        className="b"
                        to={ProgramLocation.toAbsoluteUrl({
                            ...params,
                        })}>
                        Interactions
                    </Link>
                </li>
                <li>
                    <Link
                        className={cx("pl3", { b: params.section === ProgramInteractionSections.Preroll })}
                        to={InteractionLocation.toAbsoluteUrl({
                            ...params,
                            section: ProgramInteractionSections.Preroll,
                        })}>
                        Preroll
                    </Link>
                </li>
                <li>
                    <Link
                        className={cx("pl3", { b: params.section === ProgramInteractionSections.Contents })}
                        to={InteractionLocation.toAbsoluteUrl({
                            ...params,
                            section: ProgramInteractionSections.Contents,
                        })}>
                        Contents
                    </Link>
                </li>
                <li>
                    <Link
                        className={cx("pl3", { b: params.section === ProgramInteractionSections.Agreements })}
                        to={InteractionLocation.toAbsoluteUrl({
                            ...params,
                            section: ProgramInteractionSections.Agreements,
                        })}>
                        Agreements
                    </Link>
                </li>
                <li>
                    <Link
                        className={cx("pl3", { b: params.section === ProgramInteractionSections.Tools })}
                        to={InteractionLocation.toAbsoluteUrl({
                            ...params,
                            section: ProgramInteractionSections.Tools,
                        })}>
                        Tools
                    </Link>
                </li>
            </ul>
        );
    }

    return <div className={style.sideBarColumn}>{list}</div>;
};

interface ISidebarProps {
    section: SidebarSectionEnum;
    details?: any;
}
