import { DataProxy, Operation, ServerError } from "@apollo/client";
// tslint:disable-next-line:no-submodule-imports
import { ErrorResponse } from "@apollo/client/link/error";
import * as moment from "moment";

import { AuthenticateUser } from "../components/Register/query.gql";

import { alertsVar, currentUserVar } from "./auth.data";

export const parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(window.atob(base64));
};

export const getUserId = () => {
    return localStorage.getItem("authenticated-token")
        ? parseJwt(localStorage.getItem("authenticated-token")).uid
        : null;
};

export enum EntryType {
    authenticated = "authenticated",
    anon = "anon",
}

export const tokenName = (entry: string) => {
    return addPrefix(entry, "token");
};

const addPrefix = (entry: string, key: string) => {
    return `${entry}-${key}`;
};

export const saveSession = (username: string, token: string, cache: DataProxy, entry?: string) => {
    if (!!!entry) {
        entry = (window as any).__ENTRY__;
    }
    const parsed = parseJwt(token);
    const role = parsed.role;
    const user = { __typename: "User", username, id: parsed.user_id };
    if (window.localStorage && username && token) {
        localStorage.setItem(tokenName(entry), token);
        localStorage.setItem(addPrefix(entry, "authenticated"), JSON.stringify(user));
        localStorage.setItem(addPrefix(entry, "role"), JSON.stringify(role));
    }
    currentUserVar(user);
};

export const getAuthState = (entry: string) => {
    const state = {
        currentUser: null as { id: number; username: string },
        token: null as string,
        role: null as string,
    };
    if (window.localStorage) {
        state.token = localStorage.getItem(tokenName(entry));
        state.currentUser = JSON.parse(localStorage.getItem(addPrefix(entry, "authenticated")));
        state.role = JSON.parse(localStorage.getItem(addPrefix(entry, "role")));
    }
    return state;
};

export const endSession = () => {
    currentUserVar(null);
    if (window.localStorage) {
        localStorage.clear();
    }
    window.location.assign(window.location.pathname);
};

export const setHeaders = async (operation: Operation, entry: EntryType) => {
    const token = localStorage.getItem(tokenName(entry));
    if (token && operation.query !== AuthenticateUser) {
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : "",
            },
        });
    }
};

export const handleExpiredAuth = (errObj: ErrorResponse) => {
    if (errObj.networkError && (errObj.networkError as ServerError).statusCode === 401) {
        const alerts = alertsVar();
        alertsVar([...alerts, { type: "auth", name: "expired", createdAt: moment.utc() }]);
    }
    // tslint:disable-next-line:no-console
    console.warn("observed graphQLerror", errObj);
    errObj.forward(errObj.operation);
};
