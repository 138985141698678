import { generatePath, Params } from "react-router";
import { ProgramInteractionSections } from "../models/program";

export interface ILabLocationParams extends Params {
    orgUrl: string;
}

export const LabLocation = {
    path: "/lab/:orgUrl",
    toUrl: (params: ILabLocationParams) => {
        return generatePath(LabLocation.path, params);
    },
};

export const CatalogueLocation = LabLocation;

export interface IProgramLocationParams extends Params {
    programSlug: string;
}

export const ProgramLocation = {
    path: "p/:programSlug",
    toUrl: (params: IProgramLocationParams) => {
        return generatePath(ProgramLocation.path, params);
    },
    toAbsoluteUrl: (params?: IInteractionLocationParams) => {
        const aUrl: string = [LabLocation, ProgramLocation]
            .map((L) => {
                return L.toUrl(params as any);
            })
            .join("/");
        return aUrl;
    },
};

export interface IInteractionLocationParams extends Params {
    section?: ProgramInteractionSections;
}

export const InteractionLocation = {
    path: "i/:section",
    toUrl: (params?: IInteractionLocationParams) => {
        if (!!!params?.section) {
            params = { section: "" as ProgramInteractionSections, ...params };
        }
        return generatePath(InteractionLocation.path, params);
    },
    toAbsoluteUrl: (params?: IInteractionLocationParams) => {
        const aUrl: string = [LabLocation, ProgramLocation, InteractionLocation]
            .map((L) => {
                return L.toUrl(params as any);
            })
            .join("/");
        return aUrl;
    },
};
export interface IVersionParams extends Params {
    version?: string;
}
export const ProgramVersionLocation = {
    path: "versions/:version",
    toUrl: (params?: IVersionParams) => {
        if (!!!params?.version) {
            params = { version: "", ...params };
        }
        return generatePath(ProgramVersionLocation.path, params);
    },
    toAbsoluteUrl: (params?: IVersionParams) => {
        const aUrl: string = [LabLocation, ProgramLocation, ProgramVersionLocation]
            .map((L) => {
                return L.toUrl(params as any);
            })
            .join("/");
        return aUrl;
    },
};

const isDigit = new RegExp("\\d+");
export const ContentLocation = {
    path: "/p/:id",
    contentPath: `${InteractionLocation.path}/*`,
    toUrl: (params: { id?: number; loc?: number[]; section?: string }) => {
        let { id, loc } = params;
        const { section } = params;
        if (!!!id && loc && loc.length > 1) {
            id = loc[0];
            loc = loc.slice(1);
        }
        const idPath = id ? `${id}/` : "";
        let contentPath = "";
        if (loc && loc.length > 0) {
            contentPath = loc.join("/");
        }
        if (section && section !== "contents") {
            contentPath = `${section}/${contentPath}`;
        }
        return `/p/${idPath}${contentPath}`;
    },
    parseLocationParams: () => {
        // not implemented
    },
    parseContentLocation: (wildcard = "") => {
        const loc: number[] = [];
        wildcard.split("/").map((n: string, i: number) => {
            if (isDigit.test(n)) {
                loc.push(parseInt(n, 10));
            }
        });
        return { loc };
    },
    toAbsoluteUrl: (params: any) => {
        const { section, loc } = params;
        const aUrl: string = [LabLocation, ProgramLocation, InteractionLocation]
            .map((L) => {
                return L.toUrl(params as any);
            })
            .join("/");
        let contentPath = "";
        if (loc && loc.length > 0) {
            contentPath = loc.join("/");
        }
        return [aUrl, contentPath].join("/");
    },
};
