import * as React from "react";
import { NavLink, useParams } from "react-router-dom";

import cx from "classnames";

import { getNewNoteUrl } from "../../../../Longform/shared/routes/locations";
import Logo from "../../../../shared/images/placeholder.png";
import { endSession } from "../../../services/auth";
import { currentUserVar } from "../../../services/auth.data";
import { useReactiveVar } from "@apollo/client";

const Longform = require("../../../../Longform/shared/images/longform.svg");

const style = {
    container: "flex justify-between items-center w-100 bg-white bb b--light-gray tc center mt2",
    image: "w2 pl2 self-center",
    link: "f6 f5-l link bg-animate black-80 hover-bg-lightest-blue pa3 ph4-l",
};

interface IProps {
    className?: string;
}

export const NavigationHeader = (props: IProps) => {
    const { code } = useParams();
    const { className } = props;
    const user = useReactiveVar(currentUserVar);
    const handleLogout = (e: React.MouseEvent) => {
        endSession();
    };
    return (
        <nav className={cx(className, "dt w-100 mw8 center")}>
            <div className="dtc w2 v-mid pa3">
                <NavLink to="/" className="dib w2 h2 pa1 ba b--white-90 grow-large border-box">
                    <img className={style.image} src={Longform} />
                </NavLink>
            </div>
            <div className="dtc v-mid tr pa3">
                {!!!user && (
                    <NavLink to="/signin" className="f6 fw4 hover-black no-underline black-70 dib pv2 ph3">
                        Log In
                    </NavLink>
                )}
                {!!user && (
                    <React.Fragment>
                        <NavLink
                            to={getNewNoteUrl(code)}
                            className="f6 fw4 hover-black no-underline black-70 dib pv2 ph3">
                            New
                        </NavLink>
                        <span className="f6 fw4 hover-black no-underline black-70 dib pv2 ph3">{user.username}</span>
                        <a
                            href="#"
                            onClick={(e) => handleLogout(e)}
                            className="f6 fw4 hover-black no-underline black-70 dib pv2 ph3">
                            Log Out
                        </a>
                    </React.Fragment>
                )}
            </div>
        </nav>
    );
};

export const NavigationSidebar = ({ className }: IProps) => {
    return (
        <div className={"w-8 fl pl2 h-100 flex flex-column pr3 bb-0 bt-0 br bl-0 b--solid b--black-10"}>
            <NavLink className={"db w-100 flex-auto pv3 center mt2 bg-white"} to="/">
                <img className={style.image} src={Logo} />
            </NavLink>
            <NavLink className={"db w-100 flex-auto pv3 center bg-white"} to="/notes">
                <img className={style.image} src={"https://image.flaticon.com/icons/svg/33/33450.svg"} />
            </NavLink>
            <NavLink className={"db w-100 flex-auto pv3 center bg-white"} to="/edu">
                <img className={style.image} src={"https://image.flaticon.com/icons/svg/33/33490.svg"} />
            </NavLink>
            <NavLink className={"db w-100 flex-auto pv3 center bg-white"} to="/app3">
                App 3
            </NavLink>
        </div>
    );
};
