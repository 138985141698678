import * as React from "react";
const { useState } = React;

import cx from "classnames";

import { VersionSidebar } from ".";
import { useInteractionVersionsQuery } from "../../../../shared/models/types";
import { InteractionAtVersion } from "./AtVersion";
import { filterUnchanged, getUnpatchedChanges, useRestoreInteraction } from "./model";

export const VersionModal = (props: { id: number; close: () => void }) => {
    const { data } = useInteractionVersionsQuery({
        variables: {
            interactionId: props.id,
        },
    });
    const versions = data?.interactionVersions?.nodes || [];

    const [selectedVersion, setSelectedVersion] = useState(null);

    const [tab, setTab] = useState(0);
    const showFullText = selectedVersion?.payload?.md || (!!selectedVersion && tab !== 0);

    const baseId = selectedVersion?.programId || selectedVersion?.interactionId || selectedVersion?.noteId;

    const unpatchedChanges = selectedVersion ? getUnpatchedChanges(versions, selectedVersion, showFullText) : {};

    const [restoreInteraction] = useRestoreInteraction(props.id);
    const handleRestoreInteraction = async () => {
        await restoreInteraction(selectedVersion.id);
        props.close();
    };

    return (
        <div className="flex">
            <div>
                {versions.map((nV, i) => (
                    <VersionSidebar
                        key={nV.id}
                        version={nV}
                        previousVersion={versions[i - 1]}
                        onClick={() => setSelectedVersion(nV)}
                        selected={nV === selectedVersion}
                    />
                ))}
            </div>
            <div style={{ flexGrow: 1 }}>
                <div>
                    {Object.entries(unpatchedChanges).map(([k, v]) => {
                        return (
                            <div key={k}>
                                <span className="pl2">{k}</span>
                                <span className="pl2">{v.value}</span>
                                {v.added && <span className="green pl2">+{v.added}</span>}
                                {v.added && <span className="red pl2">-{v.removed}</span>}
                            </div>
                        );
                    })}
                </div>
                <div className="flex justify-end">
                    <div
                        onClick={() => setTab(0)}
                        className={cx("pa2 pointer", { b: !!!showFullText && selectedVersion })}>
                        Patch
                    </div>
                    <div onClick={() => setTab(selectedVersion?.id)} className={cx("pa2 pointer", { b: showFullText })}>
                        Full Text
                    </div>
                    <div onClick={props.close} className="pointer pa2">
                        Close
                    </div>
                </div>
                {selectedVersion && !!!showFullText && (
                    <textarea
                        value={JSON.stringify(
                            filterUnchanged(selectedVersion?.payload.patches) || selectedVersion?.payload,
                            null,
                            2,
                        )}
                        className="vh-75 w-100 nowrap"
                        readOnly
                        style={{ whiteSpace: "pre-line" }}
                    />
                )}
                {showFullText && <InteractionAtVersion id={baseId} versionId={selectedVersion.id} />}
                <input
                    className={cx(
                        "b ph3 pv2 input-reset ba b--red bg-light-red grow pointer f6 mr1",
                        !!selectedVersion ? "dib" : "dn",
                    )}
                    type="button"
                    value="Restore this Version"
                    onClick={handleRestoreInteraction}
                />
            </div>
        </div>
    );
};
