import { findIndex, set } from "lodash";
import { useCurrentProgram } from "../../../shared/graphql/programs";

import { Interaction, Program, QueryInteractionQuery, useUpdateProgramMutation } from "../../../shared/models/types";

export type GenericInteraction = Interaction | QueryInteractionQuery["interaction"];

export enum ProgramInteractionSections {
    Preroll = "preroll",
    Contents = "contents",
    Agreements = "agreements",
    Tools = "tools",
}

export interface IInteractionArrow {
    id: number;
    name: string;
    slug: string;
    contents?: IInteractionArrow[];
}

export interface IProgramPayload {
    contents: IInteractionArrow[];
    bespoke: {
        preroll: IInteractionArrow[];
        about: IInteractionArrow[];
        agreements: IInteractionArrow[];
    };
}

/**
 *
 * @param contents
 * @param loc index determined location of the content that will contain the new content
 * @param newContent
 */
const addContentToLocation = (
    contents: IInteractionArrow[] = [],
    loc: number[] = [],
    newContent: IInteractionArrow,
) => {
    // Simplest case, loc is null or empty
    if (!!!loc || loc.length === 0) {
        const newContents = [
            ...contents,
            {
                id: newContent.id,
                name: newContent.name,
                slug: newContent.slug,
            },
        ] as IInteractionArrow[];
        return newContents;
    } else {
        // Top level case, there is only one location (recurses if deeper)
        const newContents0 = [...contents];
        newContents0[loc[0]] = { ...newContents0[loc[0]] };
        newContents0[loc[0]].contents = addContentToLocation(newContents0[loc[0]].contents, loc.slice(1), newContent);
        return newContents0;
    }
};

// type BespokeType = "contents" | "preroll" | "about" | "agreements";

// const updateContent = (payload: IProgramPayload, newContents: IInteractionArrow[], contentType: BespokeType) => {
//     set(payload, "contents" === contentType ? "contents" : `bespoke.${contentType}`, newContents);
// };

export const addInteractionToProgram = (
    program: Partial<Program>,
    interaction: Partial<Interaction>,
    section: ProgramInteractionSections,
    location: number[],
) => {
    // Create ProgramPatch required to add Interaction to Program
    //
    const newInteractionArrow: IInteractionArrow = {
        id: interaction.id,
        name: interaction.name,
        slug: interaction.slug,
    };

    // Get target content
    const targetContent = program.payload[section];

    // Update to include interaction
    const newTargetContents = addContentToLocation(targetContent, location, newInteractionArrow);

    // Create updated payload
    const newPayload = { ...program.payload };
    newPayload[section] = newTargetContents;

    // Return
    return { payload: newPayload };
};

export const removeContent = (contents: IInteractionArrow[] = [], indexes: number[]) => {
    // Simplest case, index is at target level
    if (indexes.length === 1) {
        const newContents = [...contents];
        newContents.splice(indexes[0], 1);
        return newContents;
    } else {
        // Recurse
        const newContents0 = [...contents];
        newContents0[indexes[0]] = { ...newContents0[indexes[0]] };
        newContents0[indexes[0]].contents = removeContent(newContents0[indexes[0]].contents, indexes.slice(1));
        return newContents0;
    }
};
export const moveContentUp = (contents: IInteractionArrow[] = [], indexes: number[]) => {
    // Simplest case, index is at target level
    if (indexes.length === 1) {
        const sourceIndex = indexes[0];
        const newContents = [...contents];
        if (sourceIndex >= 1 && contents.length >= sourceIndex) {
            // Set source at target location
            newContents[sourceIndex - 1] = contents[sourceIndex];
            // Place original content at source location
            newContents[sourceIndex] = contents[sourceIndex - 1];
        }
        return newContents;
    } else {
        // Recurse
        const newContents0 = [...contents];
        newContents0[indexes[0]] = { ...newContents0[indexes[0]] };
        newContents0[indexes[0]].contents = moveContentUp(newContents0[indexes[0]].contents, indexes.slice(1));
        return newContents0;
    }
};
export const replaceContent = (
    contents: IInteractionArrow[] = [],
    path: number[], // ids
    updatedContent: IInteractionArrow,
) => {
    const index0 = findIndex(contents, { id: path[0] });
    // Simplest case, index is at target level
    if (path.length === 1) {
        const newContents = [...contents];
        const staleContent = newContents[index0];
        if (staleContent.id === updatedContent.id) {
            newContents[index0] = {
                ...updatedContent,
                contents: newContents[index0].contents,
            };
        } else {
            // failure case; unhandled
            console.error(
                "program payload appears to be out of sync; destination InteractionArrow does not match updated",
            );
        }
        return newContents;
    } else {
        // Recurse
        const newContents0 = [...contents];
        newContents0[index0] = { ...newContents0[index0] };
        newContents0[index0].contents = replaceContent(newContents0[index0].contents, path.slice(1), updatedContent);
        return newContents0;
    }
};

export const useUpdateInteractionInProgram = () => {
    const { program, refetchPrograms } = useCurrentProgram();
    const [updateProgram] = useUpdateProgramMutation();
    const updateInteraction = async (
        targetContents: IInteractionArrow[],
        location: number[],
        section: ProgramInteractionSections,
        updatedInteraction: IInteractionArrow,
    ) => {
        //
        const newContents = replaceContent(targetContents, location, updatedInteraction);
        const programPatch = { id: program.id, payload: { ...program.payload } };
        programPatch.payload[section] = newContents;

        const { data } = await updateProgram({
            variables: programPatch,
        });
        refetchPrograms && refetchPrograms();
    };
    return [updateInteraction];
};
