import * as React from "react";

import cx from "classnames";

interface IProps {
    children: any;
    type?: "button" | "reset" | "submit";
    className?: string;
    onClick?: () => void;
}

export const Button = ({ type, children, className, onClick }: IProps) => (
    <button
        type={type}
        onClick={onClick}
        className={cx(
            "w-100 pa2 f6 fw6 pointer bg-green hover-bg-navy bg-animate white br3 ",
            className,
        )}>
        {children}
    </button>
);
