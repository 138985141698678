export { LabLocation, ProgramLocation } from "../../../ETxLab/shared/routes/locations";
import { without } from "lodash";
import { LabLocation, ProgramLocation } from "../../../ETxLab/shared/routes/locations";
import { toInt } from "../../../shared/models/content";

export enum Sections {
    contents = "contents",
}

const isDigit = new RegExp("\\d+");
export const ContentLocation = {
    path: [LabLocation.path, ProgramLocation.path, "*"].join("/"),

    parseLocationParams: () => {
        // not implemented
    },
    parseContentLocation: (wildcard = "") => {
        const loc: number[] = [];
        let section = "contents";
        wildcard.split("/").map((n: string, i: number) => {
            if (isDigit.test(n)) {
                loc.push(parseInt(n, 10));
            } else if (i === 0 && n !== "") {
                section = n;
            }
        });
        return { loc, section };
    },
    toUrl: (params: any) => {
        const { section, loc } = params;
        const aUrl: string = [LabLocation, ProgramLocation]
            .map((L) => {
                return L.toUrl(params as any);
            })
            .join("/");
        let contentPath = "";
        if (loc && loc.length > 0) {
            contentPath = loc.join("/");
        }
        return [aUrl, contentPath].join("/");
    },
};

export interface ISessionParams {
    [k: string]: string | number | (number | string)[];
    programId: number | string;
    chapterId: number | string;
    sessionId: number | string;
    loc: number[];
}

export const SessionLocation = {
    path: [LabLocation.path, ProgramLocation.path, ":chapterId", "session", ":sessionId", "*"].join("/"),

    parseLocationParams: () => {
        // not implemented
    },
    parseContentLocation: (wildcard = "") => {
        const loc: number[] = [];
        let section = Sections.contents;
        wildcard.split("/").map((n: string, i: number) => {
            if (isDigit.test(n)) {
                loc.push(parseInt(n, 10));
            } else if (i === 0 && n !== "") {
                section = n as Sections;
            }
        });
        return { loc, section };
    },
    toUrl: (params: ISessionParams) => {
        const { section, chapterId, sessionId, loc } = params;
        const aUrl: string = [LabLocation, ProgramLocation]
            .map((L) => {
                return L.toUrl(params as any);
            })
            .concat(chapterId.toString())
            .concat("session")
            .concat(sessionId.toString())
            .join("/");
        let contentPath = "";
        if (loc && loc.length > 0) {
            contentPath = without(loc, toInt(chapterId), toInt(sessionId)).join("/");
        }
        if (section && section !== Sections.contents) {
            contentPath = [section, contentPath].join("/");
        }
        return [aUrl, contentPath].join("/");
    },
};

export const ProgramSettingsLocation = {
    path: [LabLocation.path, ProgramLocation.path, "settings"].join("/"),

    parseLocationParams: () => {
        // not implemented
    },
    toUrl: (params: any) => {
        const { section } = params;
        const aUrl: string = [LabLocation, ProgramLocation]
            .map((L) => {
                return L.toUrl(params as any);
            })
            .concat("settings")
            .join("/");
        return aUrl;
    },
};
