import { useReactiveVar } from "@apollo/client";
import * as React from "react";
import { Route, Routes } from "react-router-dom";

import { currentUserVar } from "../../../shared/services/auth.data";
import { AuthModal, AuthRoutes } from "../../../shared/services/routeAuth";
import { CataloguePage } from "../../CataloguePage";
import { InteractionPage } from "../../InteractionPage";
import { LabHomepage } from "../../LabHomepage";
import { ProgramOverview, ProgramPage } from "../../ProgramPage";
import { ProgramVersionPage } from "../../ProgramVersionPage";
import { LabLayout } from "../components/Layout";
import {
    ContentLocation,
    InteractionLocation,
    LabLocation,
    ProgramLocation,
    ProgramVersionLocation,
} from "./locations";

export const AppRoutes = () => {
    return (
        <React.Fragment>
            <AuthModal />
            <Routes>
                <Route path="/" element={<LabLayout />}>
                    <Route path={LabLocation.path}>
                        <Route path={ProgramLocation.path} element={<ProgramPage />}>
                            <Route path={InteractionLocation.path} element={<InteractionPage />} />
                            <Route path={InteractionLocation.toUrl()} element={<InteractionPage />} />
                            <Route path={ContentLocation.contentPath} element={<InteractionPage />} />
                            <Route path={ProgramVersionLocation.path} element={<ProgramVersionPage />} />
                            <Route path={ProgramVersionLocation.toUrl()} element={<ProgramVersionPage />} />
                            <Route index element={<ProgramOverview />} />
                        </Route>
                        <Route index element={<CataloguePage />} />
                    </Route>
                    <Route index element={<LabHomepage />} />
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export const App = () => {
    const user = useReactiveVar(currentUserVar);
    return <div>{!!user ? <AppRoutes /> : <AuthRoutes Homepage={LabHomepage} />}</div>;
};
