import cx from "classnames";
import * as React from "react";

import { useReactiveVar } from "@apollo/client";
import { Link, Outlet, useParams } from "react-router-dom";

import { alertsVar } from "../../shared/services/auth.data";
import { ILabLocationParams, IProgramLocationParams, LabLocation, ProgramLocation } from "../shared/routes/locations";
import { Interaction, Organization, Program, useUpdateProgramMutation } from "../../shared/models/types";
import { Sidebar, SidebarSectionEnum } from "../shared/components/Sidebar";
import {
    addInteractionToProgram,
    IInteractionArrow,
    moveContentUp,
    ProgramInteractionSections,
    removeContent,
    useUpdateInteractionInProgram,
} from "../shared/models/program";
import { style } from "../shared/components/Layout/style";
import { useCurrentProgram } from "../../shared/graphql/programs";

export interface IOutletContext {
    organization: Partial<Organization>;
    program: Partial<Program>;
    addInteraction: (
        interaction: Partial<Interaction>,
        section: ProgramInteractionSections,
        location: number[],
    ) => Promise<void>;
    removeInteraction: (
        targetContents: IInteractionArrow[],
        location: number[],
        section: ProgramInteractionSections,
    ) => Promise<void>;
    moveUpInteraction: (
        targetContents: IInteractionArrow[],
        location: number[],
        section: ProgramInteractionSections,
    ) => Promise<void>;
    updateInteraction: (
        targetContents: IInteractionArrow[],
        location: number[],
        section: ProgramInteractionSections,
        updatedInteraction: IInteractionArrow,
    ) => Promise<void>;
}

export const ProgramPage = () => {
    const params = useParams<IProgramLocationParams>();

    const alerts = useReactiveVar(alertsVar);
    // if (error && alerts.length === 0) {
    //     refetchNotes();
    // }

    const { organization, program, refetchPrograms } = useCurrentProgram();
    const [updateProgram] = useUpdateProgramMutation();

    const addInteraction = async (
        interaction: Partial<Interaction>,
        section: ProgramInteractionSections,
        location: number[],
    ) => {
        const programPatch = addInteractionToProgram(program, interaction, section, location);
        const { data } = await updateProgram({
            variables: { ...programPatch, id: program.id },
        });
        refetchPrograms();
    };

    const removeInteraction = async (
        targetContents: IInteractionArrow[],
        location: number[],
        section: ProgramInteractionSections,
    ) => {
        const newContents = removeContent(targetContents, location);
        const programPatch = { id: program.id, payload: { ...program.payload } };
        programPatch.payload[section] = newContents;

        const { data } = await updateProgram({
            variables: programPatch,
        });
        refetchPrograms();
    };
    const moveUpInteraction = async (
        targetContents: IInteractionArrow[],
        location: number[],
        section: ProgramInteractionSections,
    ) => {
        //
        const newContents = moveContentUp(targetContents, location);
        const programPatch = { id: program.id, payload: { ...program.payload } };
        programPatch.payload[section] = newContents;

        const { data } = await updateProgram({
            variables: programPatch,
        });
        refetchPrograms();
    };
    const [updateInteraction] = useUpdateInteractionInProgram();

    const outletContext: IOutletContext = {
        organization: organization as Partial<Organization>,
        program,
        addInteraction,
        removeInteraction,
        moveUpInteraction,
        updateInteraction,
    };

    if (!!!(organization && program)) {
        return <p>no program or loading</p>;
    }
    return (
        <div className="">
            <h3 className="ma0 pa0 f6 normal ml3">
                <Link
                    className=""
                    to={LabLocation.toUrl({
                        ...params,
                    } as ILabLocationParams)}>
                    {organization.name}
                </Link>
            </h3>
            <h2 className="ma0 pa0 ml3">
                <Link
                    className="b"
                    to={ProgramLocation.toAbsoluteUrl({
                        ...params,
                    })}>
                    {program.name}
                </Link>
            </h2>

            <Outlet context={outletContext} />
        </div>
    );
};

export const ProgramOverview = () => {
    return (
        <div className="flex">
            <Sidebar section={SidebarSectionEnum.ProgramOverview} />
            <div className={cx("test", style.mainColumn)}>This is the program page.</div>
        </div>
    );
};
