import * as React from "react";
import { TextFormField, passthrough } from "../../Longform/shared/components/form";
import {
    useCreateInteractionMutation,
    InteractionAccessEnum,
    InteractionArrowsQuery,
    useInteractionArrowsLazyQuery,
} from "../../shared/models/types";
import { useCurrentUser } from "../../shared/services/auth.data";
import { IOutletContext } from "../ProgramPage";
import { ProgramInteractionSections } from "../shared/models/program";

interface IInteractionDetails {
    name?: string;
    slug?: string;
}

export const AddInteraction = (props: {
    addInteraction: IOutletContext["addInteraction"];
    organization: IOutletContext["organization"];
    program: IOutletContext["program"];
    close: any;
    type: ProgramInteractionSections;
    location: number[];
}) => {
    const [interactionDetails, setInteractionDetails] = React.useState<IInteractionDetails>({});
    const [createInteraction] = useCreateInteractionMutation();

    const currentUser = useCurrentUser();

    const createProgramHandler = async (event?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        const {
            data: {
                createInteraction: { interaction },
            },
        } = await createInteraction({
            variables: {
                slug: interactionDetails.slug,
                name: interactionDetails.name,
                orgId: props.organization.id,
                access: InteractionAccessEnum.Semipublic,
                payload: {},
                isPublic: false,
                version: "0.1.0",
                type: props.type,
                meta: {},
                userId: currentUser.id,
            },
        });

        await props.addInteraction(interaction, props.type, props.location);

        // Close Modal
        setInteractionDetails({});
        props.close();
    };

    return (
        <div>
            <form onSubmit={createProgramHandler} className={"dark-gray w-100 w-75-l center"}>
                <TextFormField
                    name="name"
                    value={interactionDetails.name}
                    placeholder="Title"
                    onChange={passthrough(interactionDetails, setInteractionDetails)}
                    maxLength={50}
                />
                <TextFormField
                    name="slug"
                    value={interactionDetails.slug}
                    placeholder="Slug"
                    onChange={passthrough(interactionDetails, setInteractionDetails)}
                    maxLength={50}
                />
                <input
                    className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                    type="submit"
                    value="Create Interaction"
                />
                <input
                    className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                    type="button"
                    value="Cancel"
                    onClick={props.close}
                />
            </form>
            <div>
                <AddExistingInteraction {...props} />
            </div>
        </div>
    );
};

const findMatchingArrows = (
    searchContentPattern: string,
    allArrows: InteractionArrowsQuery["interactions"]["nodes"][0][],
) => {
    const pattern = new RegExp(searchContentPattern, "i");
    const matches: typeof allArrows = [];
    (allArrows || []).map((arrow) => {
        if (!!!arrow.name.match(pattern) && !!!arrow.id.toString().match(pattern)) {
            // Not it
        } else {
            matches.push(arrow);
        }
    });
    return matches;
};

const AddExistingInteraction = (props: {
    addInteraction: IOutletContext["addInteraction"];
    organization: IOutletContext["organization"];
    program: IOutletContext["program"];
    close: any;
    type: ProgramInteractionSections;
    location: number[];
}) => {
    const [interactionDetails, setInteractionDetails] = React.useState<IInteractionDetails>({});
    const [createInteraction] = useCreateInteractionMutation();

    const currentUser = useCurrentUser();

    const [getArrows, { data: arrowData }] = useInteractionArrowsLazyQuery({
        variables: { orgId: props.organization.id },
    });
    const arrows = arrowData?.interactions.nodes;
    const searchInteractionsHandler = async (event?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        if (!!!arrows) {
            const { data: newArrowData } = await getArrows();
            setArrowMatches(findMatchingArrows(interactionDetails.name, newArrowData.interactions.nodes));
            setSelectedInteraction(null);
        } else {
            // already queried
        }
    };

    const [arrowMatches, setArrowMatches] = React.useState([]);
    const setSearch = (prop: { name: string }) => {
        setInteractionDetails(prop);
        setArrowMatches(findMatchingArrows(prop.name, arrows));
        setSelectedInteraction(null);
    };

    const [selectedInteraction, setSelectedInteraction] = React.useState(null);

    const addSelectedHandler = async () => {
        await props.addInteraction(selectedInteraction, props.type, props.location);

        // Close Modal
        setInteractionDetails({});
        props.close();
    };

    return (
        <form onSubmit={searchInteractionsHandler} className={"dark-gray w-100 w-75-l center"}>
            <TextFormField
                name="name"
                value={interactionDetails.name}
                placeholder="Title"
                onChange={passthrough(interactionDetails, setSearch)}
                maxLength={50}
            />
            <input
                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                type="submit"
                value="Search"
            />
            <input
                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                type="button"
                disabled={selectedInteraction === null}
                value="Add Selected"
                onClick={addSelectedHandler}
            />
            <div>
                {arrowMatches?.map((arrow, index) => {
                    return (
                        <div key={arrow.id}>
                            <label htmlFor="">
                                <input
                                    type="radio"
                                    name="content-search"
                                    value={arrow.id}
                                    id={`s-${arrow.id}`}
                                    checked={arrow.id === selectedInteraction?.id}
                                    onClick={() => {
                                        setSelectedInteraction(arrow);
                                    }}
                                />
                                {arrow.name} #{arrow.id}
                            </label>
                        </div>
                    );
                })}
            </div>
        </form>
    );
};
