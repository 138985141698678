import { GraphQLError } from "graphql";
import * as React from "react";
const { useState } = React;

import { NavLink, useNavigate } from "react-router-dom";

import { FormField } from "../../components/Form/Field";

import { Button } from "../Button";
import { Container } from "../Container";

import { useSignup } from "./query";
import { style } from "./style";

const profileInfo = [
    { name: "firstname", label: "First Name", type: "text" },
    { name: "lastname", label: "Last Name", type: "text" },
    { name: "email", label: "Email", type: "email" },
];

export const RegisterComponent = () => {
    const navigateTo = useNavigate();
    // token in the form
    const [token, setToken] = useState("");
    // token submitted to check if valid
    const [tokenSubmitted, setTokenSubmitted] = useState("");
    // user view model
    const [userForm, setUserForm] = useState({
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        username: "",
    });
    const userSignup = useSignup();

    const [userSignupFeedback, setUserSignupFeedback] = useState<{ errors?: readonly GraphQLError[] }>({});
    const handleUserSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        setUserSignupFeedback({});
        e.preventDefault();

        const { data, errors } = await userSignup({
            ...userForm,
        });

        if (errors?.length) {
            setUserSignupFeedback({ errors });
        } else {
            navigateTo("/");
        }
    };

    const handleUserChange = (e: React.FormEvent<HTMLInputElement>) =>
        setUserForm({ ...userForm, [e.currentTarget.name]: e.currentTarget.value });
    return (
        <div className={"w-30 fl ml5 ph2"}>
            <h1>Register</h1>
            {
                <form onSubmit={handleUserSubmit}>
                    <div className={style.fieldContainer}>
                        <FormField
                            label={"Choose a Username"}
                            name={"username"}
                            type={"text"}
                            value={userForm.username}
                            onChange={handleUserChange}
                        />
                    </div>
                    <div className={style.fieldContainer}>
                        <FormField
                            label={"Choose a Password"}
                            name={"password"}
                            type={"password"}
                            value={userForm.password}
                            onChange={handleUserChange}
                        />
                    </div>
                    <div className={style.fieldContainer}>
                        <Button type={"submit"} className={"mb3"}>
                            Register
                        </Button>
                        <div>
                            Already registered?
                            <NavLink to="/signin" className={"ph1 underline"}>
                                Sign in here.
                            </NavLink>
                            <br />
                        </div>
                        <div>
                            {userSignupFeedback.errors &&
                                userSignupFeedback.errors.map((e, i) => (
                                    <span className="dib pv2 red" key={i}>
                                        {e.message}
                                    </span>
                                ))}
                        </div>
                    </div>
                </form>
            }
        </div>
    );
};
