import * as React from "react";
const { useState } = React;
import cx from "classnames";

import { getLocalDate, getLocalTime } from "../../../../shared/utils/formatting";
import { getPayloadPatchSummary, Version } from "./model";

export const VersionSidebar = (props: {
    version: Partial<Version>;
    previousVersion: Partial<Version>;
    onClick: () => void;
    selected?: boolean;
}) => {
    let dateHeader;
    if (
        !!!props.previousVersion ||
        getLocalDate(props.version.createdAt) !== getLocalDate(props.previousVersion.createdAt)
    ) {
        dateHeader = <div className="f6 bold pt2">{getLocalDate(props.version.createdAt)}</div>;
    }

    const summary = getPayloadPatchSummary(props.version);
    const isFullText = !!!summary;

    return (
        <React.Fragment>
            {dateHeader}
            <div
                key={props.version.id}
                className={cx("ph3 pv2 pointer", { b: props.selected })}
                onClick={props.onClick}>
                <span>{getLocalTime(props.version.createdAt)}</span>
                {isFullText && <span className="pl2">Full text</span>}
                {!!!isFullText && <span className="green pl2">+{summary.added}</span>}
                {!!!isFullText && <span className="red pl2">-{summary.removed}</span>}
            </div>
        </React.Fragment>
    );
};
