import * as React from "react";
import { Outlet } from "react-router-dom";
import {
    NavigationHeader,
    NavigationSidebar,
} from "../components/Navigation/NavigationHeader";
import { TabBar } from "../components/Navigation/TabBar";

export const routeWithHeader = (Component: any, props?: any) => {
    return (
        <div>
            <NavigationHeader {...props} />
            <Component {...props} />
        </div>
    );
};
export const Layout = (props: any) => {
    return (
        <div>
            <NavigationHeader {...props} />
            <Outlet />
        </div>
    );
};

// export const routeWithTabBar = (Component: any) => {
//     const TabBarLayout = (props: any) => (
//         <div>
//             <Component {...props} />
//             <TabBar className={"fixed bottom-0 dn-l"} />
//         </div>
//     );
//     return TabBarLayout;
// };

// export const routeWithHeaderAndTabBar = (Component: any) => {
//     const TabBarLayout = (props: any) => (
//         <div>
//             <NavigationHeader />
//             <DesktopWrapper>
//                 <Component {...props} />
//                 <TabBar className={"fixed bottom-0 dn-l"} />
//             </DesktopWrapper>
//         </div>
//     );
//     return TabBarLayout;
// };
