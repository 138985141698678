import * as React from "react";
const { useState } = React;

import { useProgramAtVersionQuery, useInteractionAtVersionQuery } from "../../../../shared/models/types";

export const AtVersion = (props: { id: number; versionId: number }) => {
    const { data } = useProgramAtVersionQuery({ variables: { id: props.id, versionId: props.versionId } });
    const selectedVersion = data?.program?.atVersion;
    return (
        <textarea
            value={JSON.stringify(selectedVersion?.payload.patches || selectedVersion?.payload, null, 2)}
            className="vh-75 w-100 nowrap"
            readOnly
            style={{ whiteSpace: "pre-line" }}
        />
    );
};

export const InteractionAtVersion = (props: { id: number; versionId: number }) => {
    const { data } = useInteractionAtVersionQuery({ variables: { id: props.id, versionId: props.versionId } });
    const selectedVersion = data?.interaction?.atVersion;
    return (
        <textarea
            value={JSON.stringify(selectedVersion?.payload.patches || selectedVersion?.payload, null, 2)}
            className="vh-75 w-100 nowrap"
            readOnly
            style={{ whiteSpace: "pre-line" }}
        />
    );
};
