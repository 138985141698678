import cx from "classnames";
import * as React from "react";
import { Link, useParams } from "react-router-dom";

import { IContent } from "../shared/models/navigation";
import { ContentLocation } from "../shared/routes/locations";

interface IPillProps {
    contents?: IContent[];
    indexes?: number[];
    ancestry: number[];

    content: IContent;
    indexedLocation: number[];
    addContent: (indexes: number[]) => void;
    removeContent: (indexes: number[]) => void;
    moveContentUp: (indexes: number[]) => void;
    section?: string;
    currentId?: number;
}
const Pill = (props: IPillProps) => {
    const { ancestry, addContent, removeContent, moveContentUp, indexedLocation, section, currentId } = props;
    const { id, name } = props.content;
    const isFirst = indexedLocation[indexedLocation.length - 1] === 0;
    const params = useParams();
    return (
        <div className={"pa1"}>
            <div className="fr w4 tr">
                <button className="bg-white ba pointer" onClick={() => addContent(indexedLocation)}>
                    +
                </button>
                <button className="bg-white ba pointer" onClick={() => removeContent(indexedLocation)}>
                    X
                </button>
                <button
                    className="bg-white ba pointer"
                    style={{ visibility: isFirst ? "hidden" : "visible" }}
                    onClick={() => moveContentUp(indexedLocation)}>
                    ^
                </button>
            </div>
            <Link
                className={cx({ b: id === currentId })}
                to={ContentLocation.toAbsoluteUrl({
                    ...params,
                    loc: ancestry.concat(id),
                    section,
                })}>
                {name}
            </Link>
        </div>
    );
};

interface IExplorerProps {
    contents?: IContent[];
    indexes?: number[];
    ancestry: number[];
    addContent: (indexes: number[]) => void;
    removeContent: (indexes: number[]) => void;
    moveContentUp: (indexes: number[]) => void;
    section?: string;
    currentId?: number;
}
export const Explorer = (props: IExplorerProps) => {
    const { ancestry, addContent, removeContent, moveContentUp, section } = props;
    let { contents, indexes: loc } = props;
    loc = loc || [];
    contents = contents || [];

    return (
        <ul className="list pl3" style={{ maxWidth: "20rem" }}>
            {contents.map((content: IContent, i: number) => {
                const deeperIndexes = loc.concat(i);
                const contentAncestry = ancestry.concat(content.id);
                return (
                    <li key={content.id} className=" ">
                        <Pill {...props} content={content} indexedLocation={deeperIndexes} section={section} />
                        {content.contents && (
                            <Explorer
                                contents={content.contents}
                                indexes={deeperIndexes}
                                ancestry={contentAncestry}
                                addContent={addContent}
                                removeContent={removeContent}
                                moveContentUp={moveContentUp}
                                section={section}
                                currentId={props.currentId}
                            />
                        )}
                    </li>
                );
            })}
        </ul>
    );
};
