import * as React from "react";
import { Link } from "react-router-dom";

const heroImage = require("../shared/images/desk.jpg");

export const LabHomepage = () => {
    return (
        <div>
            <article className={"dark-gray w-100 w-75-l center"}>
                <div className={"w-100 pa2 ph3-ns pb3-ns"}>
                    <div className={"dt fl w-50"}>
                        <h1 className={"f2 mv0 pv2 ph3"}>Experiential Treatment Lab</h1>
                        <p className={"f6 lh-copy measure mt2 mid-gray pv2 ph3"}>
                            Treatment Development Platform.
                        </p>
                        <p className={"f6 lh-copy measure mt2 mid-gray pv2 ph3"}>
                            We drastically reduce the cost of developing digital versions of
                            evidence based treatments.
                        </p>
                    </div>
                    <div className={"dt fl w-50 ph3"}>
                        <img
                            src={heroImage}
                            className={"db w-100 br2"}
                            alt="A place for writing."
                        />
                    </div>
                </div>
            </article>
        </div>
    );
};
