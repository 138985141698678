import { gql } from "@apollo/client";
import { find } from "lodash";
import { useParams } from "react-router-dom";

import { ContentLocation } from "../../WellerLabs/shared/routes/locations";
import { IProgramPayload, toInt } from "../models/content";
import { useOrganizationProgramsQuery, useQueryInteractionQuery, Program as BaseProgram } from "../models/types";

export type Program = Omit<BaseProgram, "nodeId" | "access" | "states" | "state" | "atVersion" | "programVersions"> & {
    payload: IProgramPayload;
};

export const useCurrentPrograms = () => {
    const params = useParams();
    const { data: orgPrograms, loading: orgLoading, refetch: refetchPrograms } = useOrganizationProgramsQuery({
        variables: {
            url: params.orgUrl,
        },
    });
    const organization = orgPrograms?.organizationByUrl;
    const programs = organization?.programsByOrgId.nodes || [];

    const program: Program = find(programs, { slug: params.programSlug });
    const chapters = program?.payload.contents || [];

    const { loc } = ContentLocation.parseContentLocation(params["*"]);
    const currentInteractionId = loc.length > 0 ? loc[loc.length - 1] : 0;
    const chapter = find(chapters, { id: parseInt(params.chapterId, 10) });

    const { data: sessionData, loading: sessionLoading } = useQueryInteractionQuery({
        variables: { id: params.sessionId && Number(params.sessionId) },
        skip: !!!params.sessionId,
    });
    const session = sessionData?.interaction;

    const { data: pageData, loading: pageLoading } = useQueryInteractionQuery({
        variables: { id: toInt(currentInteractionId) },
        skip: !!!currentInteractionId,
    });
    const page = pageData?.interaction || session;

    const out = {
        organization,
        programs,
        program,
        chapters,
        chapter,
        refetchPrograms,
        session,
        page,
        loading: orgLoading || sessionLoading || pageLoading,
        loc,
    };
    return out;
};

export const useCurrentProgram = useCurrentPrograms;

export const ProgramAtVersion = gql`
    query ProgramAtVersion($id: Int!, $versionId: Int!) {
        program(id: $id) {
            __typename
            id
            userId
            orgId
            type
            name
            slug
            payload
            meta
            isPublic
            createdAt
            updatedAt
            atVersion(input: { versionId: $versionId }) {
                id
                userId
                orgId
                type
                name
                slug
                payload
                meta
                isPublic
                createdAt
                updatedAt
            }
        }
    }
`;

export const InteractionAtVersion = gql`
    query InteractionAtVersion($id: Int!, $versionId: Int!) {
        interaction(id: $id) {
            __typename
            id
            userId
            orgId
            type
            name
            slug
            payload
            meta
            isPublic
            createdAt
            updatedAt
            atVersion(input: { versionId: $versionId }) {
                id
                userId
                orgId
                type
                name
                slug
                payload
                meta
                isPublic
                createdAt
                updatedAt
            }
        }
    }
`;
