import * as React from "react";
const { useState } = React;

import { useReactiveVar } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import * as Modal from "react-modal";

import { alertsVar, useCurrentUser } from "../../shared/services/auth.data";
import { ILabLocationParams, ProgramLocation } from "../shared/routes/locations";
import {
    Organization,
    OrganizationProgramsQuery,
    ProgramAccessEnum,
    useCreateProgramMutation,
    useOrganizationProgramsQuery,
} from "../../shared/models/types";
import { passthrough, TextFormField } from "../../Longform/shared/components/form";
import { PartialOrganization } from "../../shared/graphql/organizations";

interface IProgramDetails {
    name?: string;
    slug?: string;
}

const CreateProgram = (props: { organization: PartialOrganization; close: any }) => {
    const [programDetails, setProgramDetails] = useState<IProgramDetails>({});
    const [createProgram] = useCreateProgramMutation();

    const currentUser = useCurrentUser();

    const createProgramHandler = async (event?: React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        const {
            data: {
                createProgram: { program },
            },
        } = await createProgram({
            variables: {
                slug: programDetails.slug,
                name: programDetails.name,
                orgId: props.organization.id,
                access: ProgramAccessEnum.Semipublic,
                payload: {
                    preroll: [],
                    contents: [],
                },
                isPublic: false,
                type: "program",
                meta: {},
                userId: currentUser.id,
            },
        });
        setProgramDetails({});
        props.close();
    };

    return (
        <form onSubmit={createProgramHandler} className={"dark-gray w-100 w-75-l center"}>
            <TextFormField
                name="name"
                value={programDetails.name}
                placeholder="Display Name"
                onChange={passthrough(programDetails, setProgramDetails)}
                maxLength={50}
            />
            <TextFormField
                name="slug"
                value={programDetails.slug}
                placeholder="Slug"
                onChange={passthrough(programDetails, setProgramDetails)}
                maxLength={50}
            />
            <input
                className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib mr1"
                type="submit"
                value="Create Program"
            />
        </form>
    );
};

export const CataloguePage = () => {
    const { orgUrl: code } = useParams<ILabLocationParams>();
    const alerts = useReactiveVar(alertsVar);
    // if (error && alerts.length === 0) {
    //     refetchNotes();
    // }

    // const programs: any[] = [];

    const { data: orgPrograms, refetch: refetchPrograms } = useOrganizationProgramsQuery({
        variables: {
            url: code,
        },
    });
    const organization = orgPrograms?.organizationByUrl;
    const programs = organization?.programsByOrgId.nodes || [];

    const [modal, setModal] = useState(false);
    const createNewProgram = () => {
        setModal(true);
    };

    return (
        <div className="flex">
            <div className="w-100">
                {programs?.map((n: any) => {
                    if (n.type !== "program") {
                        return;
                    }
                    return (
                        <article className="fl w-50 w-25-m w-20-l ba mv4 mh2" key={n.id}>
                            <Link className="db" to={ProgramLocation.toUrl({ programSlug: n.slug })}>
                                <h1 className="f4 bg-near-black white mv0 pv2 ph3">{n.name}</h1>
                                <div className="pa3 bt">
                                    <p className="f6 f5-ns lh-copy measure mv0">{n.payload.description}</p>
                                </div>
                            </Link>
                        </article>
                    );
                })}
                <article className="fl w-50 w-25-m w-20-l ba mv4 mh2">
                    <a className="db" onClick={createNewProgram}>
                        <h1 className="f4 bg-near-black white mv0 pv2 ph3">New Therapy</h1>
                        <div className="pa3 bt">
                            <p className="f6 f5-ns lh-copy measure mv0">Create a new treatment program.</p>
                        </div>
                    </a>
                </article>
            </div>
            <Modal isOpen={modal} ariaHideApp={false}>
                <CreateProgram
                    organization={organization}
                    close={() => {
                        setModal(false);
                        refetchPrograms;
                    }}
                />
            </Modal>
        </div>
    );
};
