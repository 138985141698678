import * as React from "react";
const { useState } = React;

import { useOutletContext } from "react-router-dom";
import cx from "classnames";

import { Sidebar, SidebarSectionEnum } from "../shared/components/Sidebar";
import { useProgramVersionsQuery } from "../../shared/models/types";
import { IOutletContext } from "../ProgramPage";
import { VersionSidebar } from "../shared/components/Version";
import { filterUnchanged } from "../shared/components/Version/model";
import { AtVersion } from "../shared/components/Version/AtVersion";

export const ProgramVersionPage = () => {
    const { program } = useOutletContext() as IOutletContext;

    const [selectedVersion, setSelectedVersion] = useState(null);
    const { data: programVersionsData } = useProgramVersionsQuery({ variables: { programId: program.id } });
    const versions = programVersionsData?.programVersions?.nodes || [];

    const [tab, setTab] = useState(0);
    const showFullText = !!!selectedVersion?.payload?.patches || (!!selectedVersion && tab !== 0);

    return (
        <div className="flex">
            <Sidebar section={SidebarSectionEnum.Interactions} />
            <div className={"ml3"}>
                {versions.map((v, i) => {
                    return (
                        <VersionSidebar
                            key={v.id}
                            version={v}
                            previousVersion={versions[i - 1]}
                            onClick={() => setSelectedVersion(v)}
                            selected={v === selectedVersion}
                        />
                    );
                })}
            </div>
            <div style={{ flexGrow: 1 }}>
                <div className="flex justify-end">
                    <div
                        onClick={() => setTab(0)}
                        className={cx("pa2 pointer", { b: !!!showFullText && selectedVersion })}>
                        Patch
                    </div>
                    <div onClick={() => setTab(selectedVersion?.id)} className={cx("pa2 pointer", { b: showFullText })}>
                        Full Text
                    </div>
                </div>
                {selectedVersion && !!!showFullText && (
                    <textarea
                        value={JSON.stringify(
                            filterUnchanged(selectedVersion.payload.patches) || selectedVersion.payload,
                            null,
                            2,
                        )}
                        className="vh-75 w-100 nowrap"
                        readOnly
                        style={{ whiteSpace: "pre-line" }}
                    />
                )}
                {showFullText && selectedVersion && <AtVersion id={program.id} versionId={selectedVersion.id} />}
            </div>
        </div>
    );
};
