import * as React from "react";
import { Outlet } from "react-router-dom";

import { Header } from "./Header";

export const LabLayout = (props: any) => {
    return (
        <div>
            <Header />
            <Outlet />
        </div>
    );
};
